import React, { useState } from "react";
import "../Cards/ChecklistCard.css";
import ChecklistCard from "../Cards/ChecklistCard.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReferralNotes from "../../notes/ReferralNotes.js";

export default function Referral() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const PreConReferralData = [
    "Builder's Sale Agreement w/ Tarion Addendums",
    "Builder Referral/Commission Agreement or FORM 641 if Builder does not have a Referral Form",
    "Additional Required Documents (as per your Brokerage)",
  ];

  const ReferralData = [
    "Referral Agreement (641)",
    "Additional Required Documents (as per your Brokerage)",
  ];

  const handleReferralType = (e) => {
    setReferralType(e.target.value);
  };

  const [ReferralType, setReferralType] = useState("toAgent");

  return (
    <div className="checklists w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-2">
          <div className="pr-5 ">Referral Type?</div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="toAgent"
              >
                <FormControlLabel
                  value="toAgent"
                  control={<Radio size="small" onClick={handleReferralType} />}
                  label="Referral of Client to another Agent"
                />
                <FormControlLabel
                  value="toBuilder"
                  control={<Radio size="small" onClick={handleReferralType} />}
                  label="Referral to Builder"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      {ReferralType === "toAgent" ? (
        <ChecklistCard
          Title1={""}
          CheckListData={ReferralData}
          Header={"Referral of Client to Another Agent"}
          Header2={""}
        />
      ) : (
        <ChecklistCard
          Title1={""}
          CheckListData={PreConReferralData}
          Header={"Referral of Client to Builder"}
          Header2={""}
        />
      )}
      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>

        <ReferralNotes />
      </div>
    </div>
  );
}
