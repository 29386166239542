import React from "react";
import "./ChecklistCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function MultiRepCardLease() {
  return (
    <div className="checklist-card pb-5">
      <div className="checklist-card-header mb-5">
        This is Multiple Representation
      </div>
      <p>
        You cannot proceed with the trade until ALL parties have given written
        consent to allow multiple representation. This consent is obtained with
        2 documents (One for the Buyers/Tenants, One for the Sellers/Landlords):
      </p>
      <br />
      <ul>
        <div key={Math.random()} className="pl-5 flex items-top">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className="pr-1 pb-1"
          />
          <li>
            <strong>FORM 327:</strong> Multiple Representation, Landlord –
            Acknowledgement & Consent Disclosure
          </li>
        </div>
        <div key={Math.random()} className="pl-5 flex items-top">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className="pr-1 pb-1"
          />
          <li>
            <strong>FORM 328:</strong> Multiple Representation, Tenant –
            Acknowledgement & Consent Disclosure
          </li>
        </div>
      </ul>
      <br />
      <p>
        If parties do not agree to multiple representation, the designated
        representative must either:
      </p>
      <br />
      <ol>
        <li>Release one client/or not sign a representation agreement</li>
        <li>Refer one of the parties to another agent</li>
      </ol>
      <br />
      <p>
        If ALL parties have given consent and you have signed the above forms,
        you may proceed to the Checklist Documents. TRESA requires multiple
        representation to be a separate conversation and as such, these
        documents should be signed separately and not bundled with other deal
        documents.
      </p>
    </div>
  );
}
