import React from "react";

export default function MLSNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        If the listing is on multiple boards, be sure to complete MLS Data Forms
        for each board
      </li>
      <li className="pb-2">
        Ensure that the commencement date on your Listing Agreement matches the
        contract start date on MLS
      </li>
    </ul>
  );
}
