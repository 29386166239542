import React, { useState } from "react";
import ChecklistCard from "../Cards/ChecklistCard.js";
import "./ResidentialListing.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListingNotes from "../../notes/ListingNotes.js";
import LeaseListingNotes from "../../notes/LeaseListingNotes.js";
import MLSNotes from "../../notes/MLSNotes.js";
import DisclosureNotes from "../../notes/DisclosureNotes.js";
import DirectionNotes from "../../notes/DirectionNotes.js";

export default function LeaseListing() {
  const [ListingData, setListingData] = useState([
    "RECO Info Guide Acknowledgement",
    "Listing Agreement with Schedule A (272)",
    "MLS Data Form(s)",
    "Additional Required Documents (as per your Brokerage)",
  ]);

  const [ListingType, setListingType] = useState("MLS");
  const [IsDisclosure, setIsDisclosure] = useState("No");
  const [Is244, setIs244] = useState("No");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const addDisclosure = (e) => {
    setIsDisclosure("Yes");
    if (
      ListingData.includes(
        "Registrant's Disclosure of Interest - Disposition (161)"
      ) === true
    ) {
      return;
    } else {
      setListingData([
        ...ListingData,
        "Registrant's Disclosure of Interest - Disposition (161)",
      ]);
    }
  };

  const removeDisclosure = (e) => {
    setIsDisclosure("No");
    setListingData(
      ListingData.filter(
        (item) =>
          item !== "Registrant's Disclosure of Interest - Disposition (161)"
      )
    );
  };

  const add244 = (e) => {
    setIs244("Yes");
    if (
      ListingData.includes("Seller's Direction re: Property/Offers (244)") ===
      true
    ) {
      return;
    } else {
      setListingData([
        ...ListingData,
        "Seller's Direction re: Property/Offers (244)",
      ]);
    }
  };

  const remove244 = (e) => {
    setIs244("No");
    setListingData(
      ListingData.filter(
        (item) => item !== "Seller's Direction re: Property/Offers (244)"
      )
    );
  };

  const setExclusive = (e) => {
    setListingType("Exclusive");
    let newListingData = ListingData;

    setListingData(
      newListingData.filter((item) => item !== "MLS Data Form(s)")
    );
  };

  const setMLS = (e) => {
    setListingType("MLS");
    let newListingData = ListingData;
    if (newListingData.includes("MLS Data Form(s)") === true) {
      return;
    } else {
      newListingData.push("MLS Data Form(s)");
    }
    setListingData(newListingData);
  };

  return (
    <div className="checklists w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-4">
          <div className="pr-5 ">MLS or Exclusive Listing?</div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="MLS"
              >
                <FormControlLabel
                  value="MLS"
                  control={<Radio size="small" onClick={setMLS} />}
                  label="MLS"
                />
                <FormControlLabel
                  value="Exclusive"
                  control={<Radio size="small" onClick={setExclusive} />}
                  label="Exclusive"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>

        <div className="flex flex-col items-left pb-4">
          <div className="pr-5 ">
            Do you or close relatives own the property?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={addDisclosure} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={removeDisclosure} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>

        <div className="flex flex-col items-left ">
          <div className="pr-5 ">
            Do you have a delayed offer presentation date?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={add244} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={remove244} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      <ChecklistCard
        Title1={""}
        CheckListData={ListingData}
        Header={ListingType + " Listing:"}
        Header2={"Landlord Client"}
      />
      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>
        <div className="pb-3"></div>
        {Is244 === "Yes" ? <DirectionNotes /> : ""}
        {IsDisclosure === "Yes" ? <DisclosureNotes /> : ""}

        <LeaseListingNotes />
        <ListingNotes />
        {ListingType === "MLS" ? <MLSNotes /> : ""}
      </div>
    </div>
  );
}
