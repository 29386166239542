import React from "react";

export default function ListingNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        As per TRESA, all agreements should be accompanied by a Schedule A which
        outlines the services to be provided to the client.
      </li>
      <li className="pb-2">
        <strong>Estate or Power of Attorney Listings:</strong> Please ensure the
        “Seller” name on the agreement reflects the individual(s) listed on
        title. If it is an estate, then put the Seller as: “The Estate of
        (deceased name).” The supporting documents are required to prove those
        signing the documents have the authority to do so. Depending on the
        situation, documents such as the Will/Certificate of Appointment or
        Power of Attorney documents are required to prove signing authority.
      </li>
      <li className="pb-2">
        <strong>Corporation Listings: </strong>If a corporation is listing a
        property, it is important to obtain supporting documents, such as the
        Articles of Incorporation, to ensure the parties signing the agreement
        have the authority to do so on behalf of the Corp.
      </li>
      <li className="pb-2">
        It is important to identify your Seller clients before proceeding with
        signing an agreement to ensure the names on the agreement are correct
        and all parties signing have the authority to do so.
      </li>
      <li className="pb-2">
        It is the responsibility of the listing agent to ensure all information
        on the listing is accurate. Please verify all listing details with your
        client, by carrying out the activities yourself (example: measurements)
        and other professional tools (example: GEOWAREHOUSE).
      </li>
      <li className="pb-2">
        As per RECO, if your listing sells in a multiple offer scenario, you
        should submit all registered offers or Form 801s to your brokerage.
      </li>
      <li className="pb-2">
        “Time is of the essence” when it comes to agreements. Please ensure
        agreements and associated deal documents are signed within their
        respective time frames in order to best protect your clients.
      </li>
    </ul>
  );
}
