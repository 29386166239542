import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import "../Cards/ChecklistCard.css";
import "./CoCBoxes.css";
import CustomAppAd from "../Globals/CustomAppAd";

export default function CoCBoxes() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#506CAA",
        dark: "#506CAA",
      },
      text: {
        primary: "#31303F",
        secondary: "#506CAA",
      },
    },
  });

  const [SellerRep, setSellerRep] = useState("");
  const [BuyerRep, setBuyerRep] = useState("");

  const handleSellerRep = (e) => {
    if (e.target.value !== "IRepSeller") {
      setBuyerRep("IRepBuyer");
      setSellerRep(e.target.value);
    }
    setSellerRep(e.target.value);
  };

  const handleBuyerRep = (e) => {
    if (e.target.value !== "IRepBuyer") {
      setSellerRep("IRepSeller");
      setBuyerRep(e.target.value);
    }
    setBuyerRep(e.target.value);
  };

  let CoCData = ["Select Representation"];
  if (SellerRep === "IRepSeller" && BuyerRep === "IRepBuyer") {
    CoCData = ["2b"];
  } else if (SellerRep === "IRepSeller" && BuyerRep === "SameBroBuyer") {
    CoCData = ["1a", "1a-3"];
  } else if (SellerRep === "IRepSeller" && BuyerRep === "OBBuyer") {
    CoCData = [
      "1a",
      "1a-1",
      "4a",
      "4a-1",
      "4b",
      `4b-1 or 4b-2 (4b-2 is typically
      used when the negotiated commission varies from what is posted on the MLS Listing)`,
    ];
  } else if (SellerRep === "IRepSeller" && BuyerRep === "SRPBuyer") {
    CoCData = ["1a", "1a-2"];
  } else if (SellerRep === "SameBroSeller" && BuyerRep === "IRepBuyer") {
    CoCData = ["1a", "1a-3"];
  } else if (SellerRep === "OBSeller" && BuyerRep === "IRepBuyer") {
    CoCData = [
      "1a",
      "1a-1",
      "4a",
      "4a-1",
      "4b",
      `4b-1 or 4b-2 (4b-2 is typically
      used when the negotiated commission varies from what is posted on the MLS Listing)`,
    ];
  } else if (SellerRep === "SRPSeller" && BuyerRep === "IRepBuyer") {
    CoCData = ["3a"];
  } else {
    CoCData = ["Select Representation"];
  }

  return (
    <div className="checklists flex flex-col w-full pt-5 pl-4 ">
      <CustomAppAd />
      <div className="flex flex-col w-full pb-3 pt-3 items-center pr-4">
        <span>
          <strong>
            Form 320/324: Confirmation of Co-operation and Representation
            Checkbox Tool
          </strong>
          <br />
          <br />
          Select who is representing the Buyers & Sellers in the dropdowns and
          the appropriate checkboxes for that scenario will display in the
          section below.
          <br />
          <br />
          <strong>
            This has been updated for the new CoC: Released February, 2024
          </strong>
          <br />
        </span>
      </div>
      <div className="flex  flex-col items-left w-full pb-5">
        <ThemeProvider theme={theme}>
          <FormControl sx={{ mt: 2, minWidth: 300, maxWidth: 110 }}>
            <InputLabel size="small">Seller/Landlord Representation</InputLabel>
            <Select
              value={SellerRep}
              name="Seller Representation"
              label="Seller/Landlord Representation"
              onChange={handleSellerRep}
              size="small"
            >
              <MenuItem key={Math.random()} value="IRepSeller">
                I Represent the Seller/Landlord
              </MenuItem>
              <MenuItem key={Math.random()} value="SameBroSeller">
                Different Agent - My Brokerage
              </MenuItem>
              <MenuItem key={Math.random()} value="OBSeller">
                Other Brokerage
              </MenuItem>
              <MenuItem key={Math.random()} value="SRPSeller">
                Seller/Tenant is an SRP
              </MenuItem>
            </Select>
          </FormControl>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <FormControl sx={{ mt: 2, minWidth: 300, maxWidth: 110 }}>
            <InputLabel size="small">Buyer/Tenant Representation</InputLabel>
            <Select
              value={BuyerRep}
              name="Buyer Representation"
              label="Buyer/Tenant Representation"
              onChange={handleBuyerRep}
              size="small"
            >
              <MenuItem key={Math.random()} value="IRepBuyer">
                I Represent the Buyer/Tenant
              </MenuItem>
              <MenuItem key={Math.random()} value="SameBroBuyer">
                Different Agent - My Brokerage
              </MenuItem>
              <MenuItem key={Math.random()} value="OBBuyer">
                Other Brokerage
              </MenuItem>
              <MenuItem key={Math.random()} value="SRPBuyer">
                Buyer/Tenant is an SRP
              </MenuItem>
            </Select>
          </FormControl>
        </ThemeProvider>
      </div>

      <div className="checklist-card ">
        <div className="flex flex-wrap checklist-card-header mb-5 ">
          <div>FORM 320/324 Checkboxes</div>
        </div>
        <ul>
          {SellerRep === "IRepSeller" && BuyerRep === "IRepBuyer" ? (
            <div className="pl-3 pr-3 pb-3">
              This is Multiple Representation. Please ensure Client(s) initial
              consent for multiple representation bubbles on page 2.
            </div>
          ) : (
            ""
          )}
          {SellerRep === "SameBroSeller" || BuyerRep === "SameBroBuyer" ? (
            <div className="pl-3 pr-3 pb-3">
              This is NOT Multiple Representation.
            </div>
          ) : (
            ""
          )}
          {SellerRep === "SRPSeller" && BuyerRep === "IRepBuyer" ? (
            <div className="pl-3 pr-3 pb-3">
              If the SRP Seller/Landlord chose to sign a Listing Agreement in
              order to pay commission, then Select 2b. This IS Multiple
              Represenation. <br />
              <br />
              If not, and the Buyer/Tenant is paying commission, use the
              checkboxes below:
            </div>
          ) : (
            ""
          )}
          {CoCData.map((item, index) => (
            <div key={index} className="pl-5 flex items-center">
              <FontAwesomeIcon
                icon={faSquareCheck}
                size="lg"
                color="#00ad43"
                className="pr-1 pb-1"
              />
              <li>{item}</li>
            </div>
          ))}
          <div className="pb-4"></div>
        </ul>
      </div>
      <div className="CoC-bottom"></div>
    </div>
  );
}
