import React, { useState } from "react";
import ChecklistCard from "../Cards/ChecklistCard.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MultiRepCard from "../Cards/MultiRepCard.js";
import MustRefer from "../Cards/MustRefer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import PurchaseNotes from "../../notes/PurchaseNotes.js";
import DisclosureNotes from "../../notes/DisclosureNotes.js";
import FSBONotes from "../../notes/FSBONotes.js";

export default function FSBOPurchase() {
  const RepresentationData = [
    "RECO Info Guide Acknowledgement",
    "Buyer Representation Agreement with Schedule A (371)",
    "Buyer Related FINTRAC Documents",
  ];

  const [SellerData, setSellerData] = useState(["Select Seller's Choice"]);

  const [DealDocs, setDealDocs] = useState([
    "Agreement of Purchase & Sale (100 series)",
    "Confirmation of Co-operation (320)",
    "Additional Required Documents (as per your Brokerage)",
  ]);

  const [IsSRP, setIsSRP] = useState(" ");
  const [IsDisclosure, setIsDisclosure] = useState("No");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const addDisclosure = (e) => {
    setIsDisclosure("Yes");
    if (
      DealDocs.includes(
        "Registrant's Disclosure of Interest - Acquisition (160)"
      ) === true
    ) {
      return;
    } else {
      setDealDocs([
        ...DealDocs,
        "Registrant's Disclosure of Interest - Acquisition (160)",
      ]);
    }
  };

  const removeDisclosure = (e) => {
    setIsDisclosure("No");
    setDealDocs(
      DealDocs.filter(
        (item) =>
          item !== "Registrant's Disclosure of Interest - Acquisition (160)"
      )
    );
  };

  const handleSeller = (e) => {
    setIsSRP(e.target.value);
    if (e.target.value === "SRP") {
      setSellerData([
        "RECO Info Guide Acknowledgement",
        "RECO Self-Represented Disclosure Form",
        "Seller Self-Represented Acknowledgement & Consent (216)",
        "Seller Related FINTRAC Documents",
      ]);
    } else {
      setSellerData([
        "RECO Info Guide Acknowledgement",
        "Listing Agreement with Schedule A (271)",
        "Seller Related FINTRAC Documents",
      ]);
    }
  };
  return (
    <div className="checklists w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className=" flex  flex-col items-left pt-3 pb-3">
          <div className="flex items-center justify-center pt-4 pb-3">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size="2xl"
              color="#cc0000"
            />
            <p>
              <strong>IMPORTANT:</strong> Commission cannot be paid by the
              Seller unless they agree to a Listing Agreement (271) or the APS
              contains a Remuneration Clause. If the Seller signs an agreement,
              this also creates Multiple Representation. If the Seller chooses
              to stay Self-Represented, commission can only be paid by the
              Buyer, unless you insert the Remuneration Clause into the
              agreement. Check with your Brokerage policies regarding the use of
              a Remuneration Clause.
            </p>
          </div>

          <div className="pr-5 pt-3">Seller's Choice?</div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Representation"
                  control={<Radio size="small" onClick={handleSeller} />}
                  label="Seller signed Listing Agreement"
                />
                <FormControlLabel
                  value="SRP"
                  control={<Radio size="small" onClick={handleSeller} />}
                  label="Seller chose to remain an SRP"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>

          <div className="pr-5 pt-5">
            Are you or close relatives purchasing this property?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={addDisclosure} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={removeDisclosure} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      {IsSRP === "Representation" ? (
        <>
          {IsDisclosure === "Yes" ? <MustRefer /> : ""}
          {IsDisclosure === "No" ? (
            <>
              <MultiRepCard />
              <ChecklistCard
                CheckListData={RepresentationData}
                CheckListData2={SellerData}
                CheckListData3={DealDocs}
                Header={"Purchase: Buyer Client"}
                Header2={"(FSBO - Seller chooses Representation)"}
                Title1="Buyer Representation Documents"
                Title2={"Seller " + IsSRP + " Documents"}
                Title3="Deal Documents"
              />{" "}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {IsSRP === "SRP" ? (
        <ChecklistCard
          CheckListData={RepresentationData}
          CheckListData2={SellerData}
          CheckListData3={DealDocs}
          Header="Purchase: Buyer Client"
          Header2="(FSBO - Seller is an SRP)"
          Title1="Buyer Representation Documents"
          Title2={"Seller " + IsSRP + " Documents"}
          Title3="Deal Documents"
        />
      ) : (
        ""
      )}

      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>
        <div className="pb-3"></div>
        <FSBONotes />
        {IsDisclosure === "Yes" ? <DisclosureNotes /> : ""}
        <PurchaseNotes />
      </div>
    </div>
  );
}
