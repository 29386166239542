import React, { useState } from "react";
import Listing from "./Listing";
import Purchase from "./Purchase";
import Lease from "./Lease";
import Referral from "./Referral";
import LetterOfOpinion from "./LetterOfOpinion";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CustomAppAd from "../Globals/CustomAppAd";

export default function Checklists() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const [TransactionType, setTransactionType] = useState("");

  const handleTransactionType = (e) => {
    setTransactionType(e.target.value);
  };

  return (
    <div className="flex flex-col w-full pt-5">
      <CustomAppAd />
      <div className="flex items-center w-full pb-5">
        <ThemeProvider theme={theme}>
          <FormControl sx={{ mt: 2, ml: 2, minWidth: 200, maxWidth: 110 }}>
            <InputLabel size="small">Transaction Type</InputLabel>
            <Select
              value={TransactionType}
              name="Transaction Type"
              label="Transaction Type"
              onChange={handleTransactionType}
              size="small"
            >
              <MenuItem key={Math.random()} value="Listing">
                Listing
              </MenuItem>
              <MenuItem key={Math.random()} value="Purchase">
                Purchase
              </MenuItem>
              <MenuItem key={Math.random()} value="Lease">
                Lease
              </MenuItem>
              <MenuItem key={Math.random()} value="Referral">
                Referral
              </MenuItem>
              <MenuItem key={Math.random()} value="LOI">
                Letter Of Opinion
              </MenuItem>
            </Select>
          </FormControl>
        </ThemeProvider>
      </div>
      {TransactionType === "Listing" ? <Listing /> : ""}
      {TransactionType === "Purchase" ? <Purchase /> : ""}
      {TransactionType === "Lease" ? <Lease /> : ""}
      {TransactionType === "Referral" ? <Referral /> : ""}
      {TransactionType === "LOI" ? <LetterOfOpinion /> : ""}
      {TransactionType === "" ? (
        <>
          <p>
            Please select the side of the transaction you are currently dealing
            with.
            <br />
            <br />
            <strong>Example: </strong>If you already have a listing and are
            dealing with a Buyer for that listing, choose "Purchase".
          </p>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
