import React, { useState } from "react";
import "./Listing.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ResidentialPurchase from "../Checklists/ResidentialPurchase.js";
import PreConPurchase from "../Checklists/PreConPurchase.js";
import AssignmentPurchase from "../Checklists/AssignmentPurchase.js";
import FSBOPurchase from "../Checklists/FSBOPurchase.js";
import MerePosting from "../Checklists/MerePosting.js";
import SRPBuyer from "../Checklists/SRPBuyer.js";

export default function Purchase() {
  const [PurchaseType, setPurchaseType] = useState("");

  let PurchaseTemplate = "";
  if (PurchaseType === "Residential") {
    PurchaseTemplate = <ResidentialPurchase />;
  } else if (PurchaseType === "PreCon") {
    PurchaseTemplate = <PreConPurchase />;
  } else if (PurchaseType === "Assignment") {
    PurchaseTemplate = <AssignmentPurchase />;
  } else if (PurchaseType === "FSBO") {
    PurchaseTemplate = <FSBOPurchase />;
  } else if (PurchaseType === "MerePost") {
    PurchaseTemplate = <MerePosting />;
  } else if (PurchaseType === "SRPBuyer") {
    PurchaseTemplate = <SRPBuyer />;
  } else {
    PurchaseTemplate = "";
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const setCheckList = (e) => {
    setPurchaseType(e.target.value);
  };

  return (
    <div className="checklists flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-2">
          <ThemeProvider theme={theme}>
            <FormControl sx={{ mt: 1, minWidth: 325, maxWidth: 110 }}>
              <InputLabel size="small">Purchase Type</InputLabel>
              <Select
                value={PurchaseType}
                name="Transaction Type"
                label="Transaction Type"
                onChange={setCheckList}
                size="small"
              >
                <MenuItem key={Math.random()} value="Residential">
                  Residential
                </MenuItem>
                <MenuItem key={Math.random()} value="PreCon">
                  Pre-Construction
                </MenuItem>
                <MenuItem key={Math.random()} value="Assignment">
                  Assignment
                </MenuItem>
                <MenuItem key={Math.random()} value="FSBO">
                  For Sale By Owner
                </MenuItem>
                <MenuItem key={Math.random()} value="MerePost">
                  Mere Posting
                </MenuItem>
                <MenuItem key={Math.random()} value="SRPBuyer">
                  Client Listing - Self-Represented Buyer
                </MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      {PurchaseTemplate}
    </div>
  );
}
