import React, { useState } from "react";
import Header from "../src/components/Globals/Header";
import Home from "../src/components/body/Home";
import MBSig from "../src/components/body/MBSig";
import RECOGuide from "./components/body/RECOGuide";
import MultipleRep from "./components/body/MultipleRep";
import CommonForms from "./components/body/CommonForms";
import Checklists from "./components/body/Checklists";
import CustomApp from "./components/body/CustomApp";
import PrivacyPolicy from "./components/body/PrivacyPolicy";
import "./App.css";
import Footer from "./components/Globals/Footer";
import CoCBoxes from "./components/body/CoCBoxes";
import { Routes, Route } from "react-router-dom";

function App() {
  const [MenuSelect, setMenuSelect] = useState("");

  return (
    <>
      <div className="app  w-full flex flex-col items-center ">
        <Header MenuSelect={MenuSelect} setMenuSelect={setMenuSelect} />
        <div className="relative w-full h-full body max-w-5xl ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/office-documents" element={<MBSig />} />
            <Route path="/reco-info-guide" element={<RECOGuide />} />
            <Route path="/multiple-representation" element={<MultipleRep />} />
            <Route path="/common-forms" element={<CommonForms />} />
            <Route path="/transaction-checklists" element={<Checklists />} />
            <Route path="/form-320" element={<CoCBoxes />} />
            <Route path="/custom-app" element={<CustomApp />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>

        <Footer MenuSelect={MenuSelect} setMenuSelect={setMenuSelect} />
      </div>
    </>
  );
}

export default App;
