import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ChecklistCard from "./ChecklistCard";

export default function MustRefer() {
  const ReferralDocs = [
    "Referral Agreement (641)",
    "Additional Required Documents (as per your Brokerage)",
  ];

  return (
    <>
      <div className="flex w-full pl-4 pt-3 items-center ">
        <div className="flex items-center justify-center">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            size="2xl"
            color="#cc0000"
          />
          <p>
            As per RECO, you cannot represent both clients in a trade when you
            have interest in the listed property. As a result, you must release
            or refer one of the client(s) to another agent. However, you can
            collect a referral fee on the client referral.
          </p>
        </div>
      </div>

      <ChecklistCard
        CheckListData={ReferralDocs}
        Header={"Client Referral"}
        Title1="Referral Documents"
      />
    </>
  );
}
