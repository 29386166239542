import React, { useState } from "react";
import ChecklistCard from "../Cards/ChecklistCard.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DisclosureNotes from "../../notes/DisclosureNotes.js";
import LeaseNotes from "../../notes/LeaseNotes.js";

export default function TenantSRP() {
  const TenantData = [
    "RECO Info Guide Acknowledgement",
    "RECO Self-Represented Party Disclosure Form",
    "Tenant Self-Represented Party Acknowledgement & Consent (355)",
  ];

  const [DealDocs, setDealDocs] = useState([
    "Agreement to Lease (400)",
    "Confirmation of Co-operation (324)",
    "Additional Required Documents (as per your Brokerage)",
  ]);

  const [IsDisclosure, setIsDisclosure] = useState("No");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const addDisclosure = (e) => {
    setIsDisclosure("Yes");
    if (
      DealDocs.includes(
        "Registrant's Disclosure of Interest - Disposition (161)"
      ) === true
    ) {
      return;
    } else {
      setDealDocs([
        ...DealDocs,
        "Registrant's Disclosure of Interest - Disposition (161)",
      ]);
    }
  };

  const removeDisclosure = (e) => {
    setIsDisclosure("No");
    setDealDocs(
      DealDocs.filter(
        (item) =>
          item !== "Registrant's Disclosure of Interest - Disposition (161)"
      )
    );
  };

  return (
    <div className="checklists w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-2">
          <div className="pr-5 pt-5">
            Do you or close relatives own this property?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={addDisclosure} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={removeDisclosure} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>

      <ChecklistCard
        CheckListData2={TenantData}
        CheckListData3={DealDocs}
        Header="Lease: Landlord Client"
        Header2="(Tenant is an SRP)"
        Title2="Tenant SRP Documents"
        Title3="Deal Documents"
      />
      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>
        <div className="pb-3"></div>
        {IsDisclosure === "Yes" ? <DisclosureNotes /> : ""}
        <LeaseNotes />
      </div>
    </div>
  );
}
