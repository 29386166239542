import React from "react";

export default function PurchaseNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        As per TRESA, all representation agreements should be accompanied by a
        Schedule A, outlining the services to be provided to the client.
      </li>
      <li className="pb-2">
        <strong>FINTRAC Receipt of Funds:</strong> Required when deposit is
        going to a brokerage trust account. Typical situations when ROF is not
        required include when deposit the goes directly to a builder or lawyer's
        trust account.
      </li>
      <li className="pb-2">
        Ensure your RECO Guide and BRA are signed on or before commencement date
        of the agreement and before an offer is made.
      </li>
      <li className="pb-2">
        It is important to identify your Buyer clients before proceeding with
        signing an agreement to ensure the names on the agreement are correct
        and all parties signing have the authority to do so.
      </li>
      <li className="pb-2">
        “Time is of the essence” when it comes to agreements. Please ensure
        agreements and associated deal documents are signed within their
        respective time frames in order to best protect your clients.
      </li>
    </ul>
  );
}
