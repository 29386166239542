import React, { useState } from "react";
import ChecklistCard from "../Cards/ChecklistCard.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MultiRepCardLease from "../Cards/MultiRepCardLease.js";
import MustRefer from "../Cards/MustRefer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import DisclosureNotes from "../../notes/DisclosureNotes.js";
import LeaseNotes from "../../notes/LeaseNotes.js";

export default function LandlordSRP() {
  const RepresentationData = [
    "RECO Info Guide Acknowledgement",
    "Tenant Representation Agreement with Schedule A (372)",
  ];

  const [SellerData, setSellerData] = useState([
    "RECO Info Guide Acknowledgement",
    "RECO Self-Represented Party Disclosure Form",
    "Landlord Self-Represented Party Acknowledgement & Consent (256)",
  ]);

  const [DealDocs, setDealDocs] = useState([
    "Agreement to Lease (400)",
    "Confirmation of Co-operation (324)",
    "Additional Required Documents (as per your Brokerage)",
  ]);

  const [IsSRP, setIsSRP] = useState("SRP");
  const [IsDisclosure, setIsDisclosure] = useState("No");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const addDisclosure = (e) => {
    setIsDisclosure("Yes");
    if (
      DealDocs.includes(
        "Registrant's Disclosure of Interest - Acquisition (160)"
      ) === true
    ) {
      return;
    } else {
      setDealDocs([
        ...DealDocs,
        "Registrant's Disclosure of Interest - Acquisition (160)",
      ]);
    }
  };

  const removeDisclosure = (e) => {
    setIsDisclosure("No");
    setDealDocs(
      DealDocs.filter(
        (item) =>
          item !== "Registrant's Disclosure of Interest - Acquisition (160)"
      )
    );
  };

  const handleSeller = (e) => {
    setIsSRP(e.target.value);
    if (e.target.value === "SRP") {
      setSellerData([
        "RECO Info Guide Acknowledgement",
        "RECO Self-Represented Disclosure Form",
        "Landlord Self-Represented Party Acknowledgement & Consent (256)",
      ]);
    } else {
      setSellerData([
        "RECO Info Guide Acknowledgement",
        "Listing Agreement with Schedule A (272) ",
      ]);
    }
  };
  return (
    <div className="checklists w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-2">
          <div className="flex items-center justify-center pt-4">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size="2xl"
              color="#cc0000"
            />
            <p>
              <strong>IMPORTANT:</strong> Commission cannot be paid by the
              Landlord unless they agree to representation, obtained by signing
              a Listing Agreement (272) or if your Brokerage has a Remuneration
              Clause. If the Landlord signs an agreement, this also creates
              Multiple Representation. If the Landlord chooses to stay
              Self-Represented, commission can only be paid by the Tenant unless
              you insert the Remuneration Clause into the agreement.
            </p>
          </div>

          <div className="pr-5 pt-3">Landlord's Choice?</div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="SRP"
              >
                <FormControlLabel
                  value="Representation"
                  control={<Radio size="small" onClick={handleSeller} />}
                  label="Landlord signed Listing Agreement"
                />
                <FormControlLabel
                  value="SRP"
                  control={<Radio size="small" onClick={handleSeller} />}
                  label="Landlord chose to remain an SRP"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>

          <div className="pr-5 pt-5">
            Are you or close relatives leasing this property?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={addDisclosure} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={removeDisclosure} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>

      {IsSRP === "Representation" ? (
        <>
          {IsDisclosure === "Yes" ? <MustRefer /> : ""}
          {IsDisclosure === "No" ? (
            <>
              <MultiRepCardLease />
              <ChecklistCard
                CheckListData={RepresentationData}
                CheckListData2={SellerData}
                CheckListData3={DealDocs}
                Header={"Lease: Tenant Client"}
                Header2={"(Landlord chose Representation)"}
                Title1="Tenant Representation Documents"
                Title2={"Landlord Representation Documents"}
                Title3="Deal Documents"
              />
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {IsSRP === "SRP" ? (
        <ChecklistCard
          CheckListData={RepresentationData}
          CheckListData2={SellerData}
          CheckListData3={DealDocs}
          Header={"Lease: Tenant Client"}
          Header2={"(Landlord is an SRP)"}
          Title1="Tenant Representation Documents"
          Title2={"Landlord SRP Documents"}
          Title3="Deal Documents"
        />
      ) : (
        ""
      )}

      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>
        <div className="pb-3"></div>
        {IsDisclosure === "Yes" ? <DisclosureNotes /> : ""}
        <LeaseNotes />
      </div>
    </div>
  );
}
