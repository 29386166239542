import React, { useState } from "react";
import "./Listing.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import LeaseTenantListed from "../Checklists/LeaseTenant.js";
import LandlordSRP from "../Checklists/LandlordSRP.js";
import TenantSRP from "../Checklists/TenantSRP.js";

export default function Purchase() {
  const [PurchaseType, setPurchaseType] = useState("");

  let PurchaseTemplate = "";
  if (PurchaseType === "TenantClientListed") {
    PurchaseTemplate = <LeaseTenantListed />;
  } else if (PurchaseType === "LandlordSRP") {
    PurchaseTemplate = <LandlordSRP />;
  } else if (PurchaseType === "TenantSRP") {
    PurchaseTemplate = <TenantSRP />;
  } else {
    PurchaseTemplate = "";
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const setCheckList = (e) => {
    setPurchaseType(e.target.value);
  };

  return (
    <div className="checklists flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-2">
          <ThemeProvider theme={theme}>
            <FormControl sx={{ minWidth: 325, maxWidth: 110 }}>
              <InputLabel size="small">Representation Type</InputLabel>
              <Select
                value={PurchaseType}
                name="Representation Type"
                label="Representation Type"
                onChange={setCheckList}
                size="small"
              >
                <MenuItem key={Math.random()} value="TenantClientListed">
                  Tenant Client (Listed/Exclusive Property)
                </MenuItem>
                <MenuItem key={Math.random()} value="LandlordSRP">
                  Tenant Client (Landlord is an SRP)
                </MenuItem>
                <MenuItem key={Math.random()} value="TenantSRP">
                  Landlord Client (Tenant is an SRP)
                </MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      {PurchaseTemplate}
    </div>
  );
}
