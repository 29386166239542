import React from "react";
import "./ChecklistCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function ChecklistCard({
  CheckListData,
  CheckListData2,
  CheckListData3,
  Header,
  Header2,
  Title1,
  Title2,
  Title3,
}) {
  return (
    <div className="checklist-card ">
      <div className="flex flex-wrap checklist-card-header mb-5">
        <div>{Header}</div> &nbsp;
        <div>{Header2}</div>
      </div>
      {Title1 !== undefined ? (
        <>
          <h2 className="pl-3 pb-5">{Title1}</h2>
          <ul>
            {CheckListData.map((item, index) => (
              <div key={index} className="pl-5 flex items-top">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color="#00ad43"
                  className=" pr-1 pb-1"
                />
                <li>{item}</li>
              </div>
            ))}
          </ul>
        </>
      ) : (
        ""
      )}
      <br />
      {Title2 !== undefined ? (
        <>
          <h2 className="pl-3 pb-5">{Title2}</h2>
          <ul>
            {CheckListData2.map((item, index) => (
              <div key={index} className="pl-5 flex items-top">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color="#00ad43"
                  className="pr-1 pb-1"
                />
                <li>{item}</li>
              </div>
            ))}
          </ul>
        </>
      ) : (
        ""
      )}
      <br />
      {Title3 !== undefined ? (
        <>
          <h2 className="pl-3 pb-5">{Title3}</h2>
          <ul>
            {CheckListData3.map((item, index) => (
              <div key={index} className="pl-5 flex items-top">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  color="#00ad43"
                  className="pr-1 pb-1"
                />
                <li>{item}</li>
              </div>
            ))}
          </ul>
          <div className="pb-5"></div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
