import React from "react";

export default function DisclosureNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        <strong>Registrant's Disclosure:</strong> As per RECO, a registrant's
        disclosure form should be signed by the party being disclosed to BEFORE
        an offer is made.
      </li>
    </ul>
  );
}
