import React from "react";
import CustomAppAd from "../Globals/CustomAppAd";

export default function MultipleRep() {
  return (
    <div className="body pt-10">
      <CustomAppAd />
      <div className="title-header">
        <h1>Multiple Representation </h1>
      </div>
      <p>
        Your brokerage may be operating under the{" "}
        <strong>“Designated Representation Model”</strong> or{" "}
        <strong>"Brokerage Representation Model”</strong> or a combination of
        both.
        <br />
        <br />
        <strong>
          IMPORTANT: This web application is suited for REALTORS® whose
          Brokerage operates under the "Designated Representation Model"
        </strong>
        <br />
        <br />
        Under the designated representation model, multiple representation can
        only arise in the following instances:
      </p>
      <br />
      <ol>
        <li>
          You are the designated representative for both Buyer and Seller in the
          same trade
        </li>
        <p>OR</p>
        <li>
          You are the designated representative for more than one Buyer in the
          same trade
        </li>
      </ol>
      <br />
      <p>
        If multiple representation is discovered to be a possibility in a
        specific trade, this must be disclosed to both Buyer and Seller at the
        time of it arising.
      </p>
      <br />
      <p>
        You cannot proceed with the trade until ALL parties have given written
        consent to allow multiple representation. This consent is obtained with
        2 documents (One for the Buyer(s), One for the Seller(s)):
      </p>
      <br />
      <ol>
        <li>
          <strong>FORM 325 </strong> – Multiple Representation, Seller –
          Acknowledgement & Consent Disclosure <br />
          OR <br />
          <strong>FORM 327 </strong> – Multiple Representation, Landlord –
          Acknowledgement & Consent Disclosure
        </li>
        <br />

        <li>
          <strong>FORM 326</strong> - Multiple Representation, Buyer –
          Acknowledgement & Consent Disclosure <br />
          OR <br />
          <strong>FORM 328 </strong> – Multiple Representation, Tenant –
          Acknowledgement & Consent Disclosure
        </li>
      </ol>
      <br />
      <p>
        If parties do not agree to multiple representation, the designated
        representative must either:
      </p>
      <br />
      <ol>
        <li>Release one client/or not sign a representation agreement</li>
        <p>OR</p>

        <li>Refer one of the parties to another agent</li>
      </ol>
      <br />

      <p>
        <strong>Important:</strong> These documents should be signed in a
        separate signing from any other documents to ensure a separate
        conversation took place.
      </p>
    </div>
  );
}
