const formsList = [
  {
    type: "form",
    category: "B",
    formNumber: "100",
    title: "Form 100 - Agreement of Purchase and Sale - Residential",
  },
  {
    type: "form",
    category: "B",
    formNumber: "101",
    title: "Form 101 - Agreement of Purchase and Sale - Condo",
  },
  {
    type: "form",
    category: "B",
    formNumber: "102",
    title: "Form 102 - Agreement of Purchase and Sale - Co-Operative",
  },
  {
    type: "form",
    category: "B",
    formNumber: "110",
    title:
      "Form 110 - Agreement of Purchase and Sale - Mobile/Modular/Manufactured",
  },
  {
    type: "form",
    category: "B",
    formNumber: "120",
    title: "Form 120 - Amendment to Agreement of Purchase and Sale",
  },
  {
    type: "form",
    category: "B",
    formNumber: "121",
    title: "Form 121 - Notice to Remove Conditions",
  },
  {
    type: "form",
    category: "B",
    formNumber: "122",
    title: "Form 122 - Mutual Release",
  },
  {
    type: "form",
    category: "B",
    formNumber: "123",
    title: "Form 123 - Waiver",
  },
  {
    type: "form",
    category: "B",
    formNumber: "124",
    title: "Form 124 - Notice of Fulfillment of Conditions",
  },
  {
    type: "form",
    category: "B",
    formNumber: "127",
    title: "Form 127 - Conditions in Offer - Buyer Acknowledgement",
  },

  {
    type: "form",
    category: "B",
    formNumber: "145",
    title:
      "Form 145 - Assignment of Agreement of Purchase and Sale - Residential",
  },
  {
    type: "form",
    category: "B",
    formNumber: "150",
    title: "Form 150 - Assignment of Agreement of Purchase and Sale - Condo",
  },
  {
    type: "form",
    category: "B",
    formNumber: "160",
    title: "Form 160 - Registrant's Disclosure - Acquisition",
  },
  {
    type: "form",
    category: "S",
    formNumber: "161",
    title: "Form 161 - Registrant's Disclosure - Disposition",
  },
  {
    type: "form",
    category: "SB",
    formNumber: "170",
    title: "Form 170 - Consent to Advertise",
  },
  {
    type: "form",
    category: "S",
    formNumber: "240",
    title: "Form 240 - Amendment to Listing Agreement",
  },
  {
    type: "form",
    category: "S",
    formNumber: "242",
    title: "Form 242 - Cancellation of Listing Agreement",
  },
  {
    type: "form",
    category: "S",
    formNumber: "271",
    title: "Form 271 - Listing Agreement - Authority for Sale",
  },
  {
    type: "form",
    category: "T",
    formNumber: "272",
    title: "Form 272 - Listing Agreement - Authority for Lease",
  },

  {
    type: "form",
    category: "S",
    formNumber: "244",
    title: "Form 244 - Sellers Direction re: Property/Offers",
  },
  {
    type: "form",
    category: "S",
    formNumber: "299",
    title: "Form 299 - Seller Disclosure: Latent Defect(s)",
  },

  {
    type: "form",
    category: "B",
    formNumber: "320",
    title: "Form 320 - Confirmation of Co-Operation and Representation",
  },

  {
    type: "form",
    category: "B",
    formNumber: "371",
    title: "Form 371 - Buyer Representation Agreement",
  },
  {
    type: "form",
    category: "T",
    formNumber: "372",
    title: "Form 372 - Tenant Representation Agreement",
  },
  {
    type: "form",
    category: "TL",
    formNumber: "400",
    title: "Form 400 - Agreement to Lease",
  },
  {
    type: "form",
    category: "SB",
    formNumber: "641",
    title: "Form 641 - Referral Agreement",
  },
  {
    type: "form",
    category: "S",
    formNumber: "650",
    title: "Form 650 - Co-Brokerage Agreement",
  },
];

export default formsList;
