import React from "react";
import "./CustomAppAd.css";

export default function CustomAppAd() {
  return (
    <div className="sample-page sample-page-text">
      <div>
        Brokerages can get a{" "}
        <strong>Custom Password Protected Transaction Application</strong>{" "}
        specific to their Operations.
        <br />
        <a href="/custom-app">Click Here for More Info.</a>
      </div>
    </div>
  );
}
