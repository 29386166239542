import React from "react";
import "./MBSig.css";
import CustomAppAd from "../Globals/CustomAppAd";

export default function MBSig() {
  return (
    <div className="body pt-10">
      <CustomAppAd />
      <div className="title-header">
        <h1>Brokerage Document Submission Procedures </h1>
      </div>
      <div className="mb-card">
        <h2>Listing Cancellations/Suspensions </h2>
        <ol>
          <li>Check your Brokerage Policy for this process.</li>
          <li>
            Sample Statement: Send an email to: testemail@testemail.com to let
            them know a signature is required.
          </li>
        </ol>
        <br />

        <p>
          To maximize showings/open house visitors on weekends, any “cancel &
          relists” to change pricing or other info should ideally be submitted
          by Wednesdays to allow a day or two for the updates to populate to the
          various public websites.
        </p>
      </div>
      <br />
      <div className="mb-card">
        <h2>Mutual Releases</h2>
        <ol>
          <li>Check your Brokerage Policy for this process.</li>
          <li>
            Sample Statement: Send an email to: testemail@testemail.com to let
            them know a signature is required.
          </li>
        </ol>
        <br />
        <p>
          Ideally, the order of signing should be the buyer, seller, buyer’s
          brokerage and the listing brokerage is typically last. Once the deal
          is reviewed and all paperwork is in the file, the transaction staff
          will send off to your Managing Broker for signature.{" "}
        </p>
        <br />
        <p>
          <strong>Please Note:</strong> There is no urgency to have a Managing
          Broker sign a mutual release. Once buyers/sellers have signed the
          document, the deal between those parties is considered released.
        </p>
      </div>
      <br />
      <div className="mb-card">
        <h2>Registrant Disclosures (Disposition/Acquisition) </h2>
        <ol>
          <li>Check your Brokerage Policy for this process.</li>
          <li>
            Sample Statement: Send an email to: testemail@testemail.com to let
            them know a signature is required.
          </li>
        </ol>
        <br />
        <p>
          <strong>Please Note:</strong> There is no urgency to have a Managing
          Broker sign disclosure documents. The important aspect is ensuring the
          parties being disclosed to sign before an offer is presented.{" "}
        </p>
      </div>
      <div className="mb-card">
        <br />
        <h2>Additional Procedures</h2>

        <br />
        <p>
          Additional Brokerage submission procedures can be added here so that
          your agents know and follow internal processes.
        </p>
      </div>
      <br />
      <p className="pt-5">
        All documents are processed by admin during regular office hours: Monday
        to Friday: 9am - 5pm
      </p>
    </div>
  );
}
