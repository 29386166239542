import React from "react";

export default function DirectionNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        <strong>Form 244 - Seller's Direction: </strong>Any directions
        concerning the listing and/or specifically the delaying of an offer
        presentation date should be clearly outlined in the Sellers Direction
        Form. If at any point the details of the directions change, a new form
        should be completed, communicated to interested parties and uploaded to
        your listing.
      </li>
    </ul>
  );
}
