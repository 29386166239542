import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
export default function Footer() {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="relative footer w-full flex justify-center">
      <div className="flex flex-col w-full max-w-5xl">
        <div className="site-map-title h-1/5 pt-3 pl-3">Site Map {">"}</div>
        <div className="h-3/5 flex flex-col justify-center pb-5 pl-3">
          <div className="w-full flex">
            <Link to="/" className="w-1/2 site-map">
              Home
            </Link>
            <Link
              to="/office-documents"
              className="w-1/2 site-map"
              onClick={scrollTop}
            >
              Office Document Procedures
            </Link>
          </div>
          <div className="w-full flex ">
            <Link
              to="/reco-info-guide"
              className="w-1/2 site-map"
              onClick={scrollTop}
            >
              RECO Information Guide
            </Link>

            <Link
              to="/multiple-representation"
              className="w-1/2 site-map"
              onClick={scrollTop}
            >
              Multiple Representation
            </Link>
          </div>
          <div className="w-full flex">
            <Link
              to="/common-forms"
              className="w-1/2 site-map"
              onClick={scrollTop}
            >
              Common Forms
            </Link>
            <Link
              to="/transaction-checklists"
              className="w-1/2 site-map"
              onClick={scrollTop}
            >
              Transaction Checklists
            </Link>
          </div>
          <div className="w-full flex">
            <Link to="/form-320" className="w-1/2 site-map" onClick={scrollTop}>
              FORM 320: CoC Checkboxes
            </Link>
          </div>
        </div>

        <div className="copyright pb-6 pl-3">
          <span>Copyright &#169;</span> {new Date().getFullYear()} &nbsp; |
          &nbsp; Pange Studios &nbsp; <br /> Questions or Comments?{" "}
          <a style={{ color: "#0c0f24" }} href="mailto:PangeStudiosRE@gmail.com">
          PangeStudiosRE@gmail.com
          </a>{" "}
          <br />
          <br />
          <a style={{ color: "#0c0f24" }} href="/privacy-policy">
            Privacy Policy
          </a>
        </div>
      </div>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5571064446079546"
        crossorigin="anonymous"
      ></script>
    </div>
  );
}
