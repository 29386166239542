import React from "react";

export default function SRPNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        A person would be considered a consumer until they either choose to be a
        Self-Represented Party (SRP) or a Client.
      </li>
      <li className="pb-2">
        It is important to take the necessary steps to identify an SRP not only
        for the transaction itself but also for the best interests of your
        client. As per FINTRAC requirements, we are obligated to take best
        measures to identify and complete the necessary paperwork for all
        parties to the transaction.
      </li>
      <li className="pb-2">
        You cannot prepare an offer on behalf of an SRP. However, in the case
        where you have a listing, you can prepare an offer on behalf of your
        client (the Seller) and send it to the SRP. This is also known as a
        reverse offer.
      </li>
    </ul>
  );
}
