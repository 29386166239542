import React from "react";

export default function LeaseNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        FINTRAC documents are not required on leases. However, it is important
        to check the identification of your client(s).
      </li>
      <li className="pb-2">
        Ensure you have read and understand the Residential Tenancies Act (RTA),
        or you may put your client, the brokerage, and yourself at risk. Do not
        include any unenforceable clauses.
      </li>
      <li className="pb-2">
        Please verify the unit is legal and has met fire, electrical and zoning
        requirements with the Municipality.
      </li>
      <li>
        The government mandated Ontario Standard Lease Agreement is to be filled
        out between the landlord and tenant. In addition, do not serve notices
        on behalf of the landlord. These should be completed and submitted by
        the landlord, a paralegal or a lawyer.
      </li>
    </ul>
  );
}
