import React from "react";
import HotSpot from "./HotSpot";

export default function FormPage({ PageData }) {
  return (
    <div className="relative max-w-3xl  form-img flex justify-center mb-2">
      <img src={PageData.imageURL} alt="form-pic" />
      {PageData.hotspots.map((item) => (
        <HotSpot key={Math.random()} HotSpotData={item} />
      ))}
    </div>
  );
}

/*
 
*/
