import React from "react";

export default function LOINotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        In order to collect payment for a service, a representation agreement
        must be completed.
      </li>
    </ul>
  );
}
