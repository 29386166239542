import React from "react";

export default function PreConNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        New Builds typically pay commission calculated on the sale price net of
        HST.
      </li>
    </ul>
  );
}
