import React from "react";

export default function FSBONotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        In addition to your client, it is important to identify the Seller(s) in
        this type of transaction to ensure they have authority to sell the
        property.
      </li>
      <li className="pb-2">
        It is advisable that the deposit should held in a brokerage trust
        account or lawyer's and NEVER directly to the Seller.
      </li>
    </ul>
  );
}
