import React, { useState, useEffect, useRef } from "react";
import "./HotSpot.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";

export default function HotSpot({ HotSpotData }) {
  const [isHotSpotOpen, setisHotSpotOpen] = useState(false);

  const handleHotSpotCard = (e) => {
    setisHotSpotOpen(!isHotSpotOpen);
  };

  const HotSpotLocation = {
    top: HotSpotData.y,
    left: HotSpotData.x,
  };

  let buttonRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (isHotSpotOpen === true && !buttonRef.current.contains(e.target)) {
        setisHotSpotOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [isHotSpotOpen]);

  return (
    <div style={HotSpotLocation} className="button-card absolute   ">
      <div className=" " ref={buttonRef}>
        {isHotSpotOpen === true ? (
          <div className="flex   ">
            <FontAwesomeIcon
              icon={faCircleMinus}
              color="#cc0000"
              onClick={handleHotSpotCard}
              className="form-icon"
            />
          </div>
        ) : (
          <div className="flex   ">
            <FontAwesomeIcon
              icon={faCirclePlus}
              color="#506CAA"
              onClick={handleHotSpotCard}
              className="form-icon"
            />
          </div>
        )}

        <div className="h-2/3 ">
          {isHotSpotOpen === true ? (
            <div className="relative button-window ">
              <div
                dangerouslySetInnerHTML={{
                  __html: HotSpotData.data,
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

/*
import React, { useState, useEffect, useRef } from "react";
import "./HotSpot.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";

export default function HotSpot({ HotSpotData }) {
  const [isHotSpotOpen, setisHotSpotOpen] = useState(false);

  const handleHotSpotCard = (e) => {
    setisHotSpotOpen(!isHotSpotOpen);
  };

  const HotSpotLocation = {
    top: HotSpotData.y,
    left: HotSpotData.x,
  };

  let buttonRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (isHotSpotOpen === true && !buttonRef.current.contains(e.target)) {
        setisHotSpotOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [isHotSpotOpen]);

  return (
    <div
      style={HotSpotLocation}
      className="button-card absolute w-full test-border justify-start items-start  "
      ref={buttonRef}
    >
      <div className="relative button w-full flex flex-col   ">
        {isHotSpotOpen === true ? (
          <div className="flex items-center justify-start w-full ">
            <FontAwesomeIcon
              icon={faCircleMinus}
              color="#00ad43"
              size="lg"
              onClick={handleHotSpotCard}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full test-button ">
            <FontAwesomeIcon
              icon={faCirclePlus}
              color="#00ad43"
              size="lg"
              onClick={handleHotSpotCard}
            />
          </div>
        )}

        <div className="h-2/3">
          {isHotSpotOpen === true ? (
            <div className="absolute w-full h-full button-window ">
              <div
                dangerouslySetInnerHTML={{
                  __html: HotSpotData.data,
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

*/
