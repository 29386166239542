import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleHome = (e) => {
    setAnchorEl(null);
    window.scrollTo(0, 0);
  };

  return (
    <AppBar position="sticky" className=" w-screen">
      <Toolbar
        className="appBar "
        sx={{
          backgroundColor: "white",
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ color: "#000000" }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {anchorEl === null ? (
            <MenuIcon fontSize="large" />
          ) : (
            <ClearIcon fontSize="large" />
          )}
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem component={Link} to="/" onClick={handleHome}>
            Home
          </MenuItem>

          <MenuItem component={Link} to="/reco-info-guide" onClick={handleHome}>
            RECO Guide Information
          </MenuItem>
          <MenuItem
            component={Link}
            to="/multiple-representation"
            onClick={handleHome}
          >
            Multiple Representation
          </MenuItem>

          <MenuItem
            component={Link}
            to="/transaction-checklists"
            onClick={handleHome}
          >
            Transaction Checklists
          </MenuItem>
          <MenuItem component={Link} to="/form-320" onClick={handleHome}>
            FORM 320/324: CoC Checkboxes
          </MenuItem>
          <MenuItem
            component={Link}
            to="/office-documents"
            onClick={handleHome}
          >
            Brokerage Document Submission Procedures
          </MenuItem>
          <MenuItem component={Link} to="/common-forms" onClick={handleHome}>
            Common Forms
          </MenuItem>
          <MenuItem component={Link} to="/custom-app" onClick={handleHome}>
            Custom Brokerage Application Info
          </MenuItem>
        </Menu>
        <div className="logo-box flex items-center">
          <div>
            <Link to="/">
              <img
                onClick={handleHome}
                src="../logos/onre-logo.png"
                className="header-logo cursor-pointer"
                alt="logo"
                data-my-value=""
              />
            </Link>
          </div>

          <div className="header-text">
            Ontario Real Estate Transaction Guide
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
