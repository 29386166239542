import React, { useState } from "react";
import "./CommonForms.css";
import FormPageData from "../Data/formPageData.js";
import FormPage from "../Cards/FormPage.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import formsList from "../Data/formsList.js";

export default function CommonForms() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#506CAA",
        dark: "#506CAA",
      },
      text: {
        primary: "#31303F",
        secondary: "#506CAA",
      },
    },
  });
  const [FormType, setFormType] = useState("");

  const handleFormType = (e) => {
    setFormType(e.target.value);
  };

  let currentPageData = FormPageData.filter((item) => item.form === FormType);

  const ITEM_HEIGHT = 100;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 360,
      },
    },
  };

  return (
    <div className="w-full  flex flex-col justify-center items-center">
      <div className="flex  ">
        <ThemeProvider theme={theme}>
          <FormControl
            sx={{
              mt: 5,
              mb: 5,
              minWidth: 360,
              maxWidth: 360,
            }}
          >
            <InputLabel size="small">Form Type</InputLabel>
            <Select
              value={FormType}
              name="Form Type"
              label="Form Type"
              onChange={handleFormType}
              size="small"
              MenuProps={MenuProps}
            >
              {formsList.map((item) => (
                <MenuItem
                  key={Math.random()}
                  value={item.formNumber}
                  style={{ whiteSpace: "normal" }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ThemeProvider>
      </div>
      <div className="max-w-3xl w-full flex justify-left">
        {currentPageData.length > 0 ? (
          <>
            <div
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html: currentPageData[0].description,
              }}
            />
          </>
        ) : (
          ""
        )}
      </div>
      {FormType === "" ? (
        <div className="max-w-3xl w-full flex justify-left">
          <p>
            <strong>Disclaimer:</strong>
            <br />
            Since every deal is unique, the forms displayed within this section
            are for educational purposes only and are not to be relied upon as
            an all inclusive source of information. They are prepared to provide
            a general understanding of how to fill out the forms in common
            scenarios. Depending on your specific transaction, you may require
            legal, accounting or other professional advice, as the descriptions
            and tips on each form do not cover all circumstances.
          </p>
        </div>
      ) : (
        ""
      )}
      {currentPageData.map((item) => (
        <FormPage key={Math.random()} PageData={item} />
      ))}
    </div>
  );
}
