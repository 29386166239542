import React, { useState } from "react";
import ChecklistCard from "../Cards/ChecklistCard.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MultiRepCardLease from "../Cards/MultiRepCardLease.js";
import MustRefer from "../Cards/MustRefer.js";
import DisclosureNotes from "../../notes/DisclosureNotes.js";
import LeaseNotes from "../../notes/LeaseNotes.js";

export default function LeaseTenantListed() {
  const RepresentationData = [
    "RECO Info Guide Acknowledgement",
    "Tenant Representation Agreement with Schedule A (372)",
  ];

  const [DealDocs, setDealDocs] = useState([
    "Agreement to Lease (400)",
    "Confirmation of Co-operation (324)",
    "Additional Required Documents (as per your Brokerage)",
  ]);

  const [IsMultiRep, setIsMultiRep] = useState("No");
  const [IsDisclosure, setIsDisclosure] = useState("No");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const addDisclosure = (e) => {
    setIsDisclosure("Yes");
    if (
      DealDocs.includes(
        "Registrant's Disclosure of Interest - Acquisition (160)"
      ) === true
    ) {
      return;
    } else {
      setDealDocs([
        ...DealDocs,
        "Registrant's Disclosure of Interest - Acquisition (160)",
      ]);
    }
  };

  const removeDisclosure = (e) => {
    setIsDisclosure("No");
    setDealDocs(
      DealDocs.filter(
        (item) =>
          item !== "Registrant's Disclosure of Interest - Acquisition (160)"
      )
    );
  };

  const handleMultiRep = (e) => {
    setIsMultiRep(e.target.value);
  };

  return (
    <div className="checklists w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-2">
          <div className="pr-5 pt-3">
            Do you also represent the Landlord in this transaction?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={handleMultiRep} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={handleMultiRep} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>

          <div className="pr-5 pt-5">
            Are you or close relatives leasing this property?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={addDisclosure} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={removeDisclosure} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>

      {IsMultiRep === "Yes" ? (
        <>
          {IsDisclosure === "Yes" ? <MustRefer /> : ""}
          {IsDisclosure === "No" ? (
            <>
              <MultiRepCardLease />
              <ChecklistCard
                CheckListData={RepresentationData}
                CheckListData2={DealDocs}
                Header="Lease: Tenant Client"
                Header2=" (Listed Property)"
                Title1="Tenant Representation Documents"
                Title2="Deal Documents"
              />
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {IsMultiRep === "No" ? (
        <ChecklistCard
          CheckListData={RepresentationData}
          CheckListData2={DealDocs}
          Header="Lease: Tenant Client"
          Header2=" (Listed Property)"
          Title1="Tenant Representation Documents"
          Title2="Deal Documents"
        />
      ) : (
        ""
      )}

      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>
        <div className="pb-3"></div>
        {IsDisclosure === "Yes" ? <DisclosureNotes /> : ""}
        <LeaseNotes />
      </div>
    </div>
  );
}
