import React, { useState } from "react";
import ChecklistCard from "../Cards/ChecklistCard.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SRPNotes from "../../notes/SRPNotes.js";
import DisclosureNotes from "../../notes/DisclosureNotes.js";

export default function SRPBuyer() {
  const RepresentationData = [
    "RECO Info Guide Acknowledgement",
    "RECO Self-Represented Disclosure Form",
    "Buyer Self-Represented Acknowledgement & Consent (311)",
    "Buyer Related FINTRAC Documents",
  ];

  const [DealDocs, setDealDocs] = useState([
    "Agreement of Purchase & Sale (100 series)",
    "Confirmation of Co-operation (320)",
    "Additional Required Documents (as per your Brokerage)",
  ]);

  const [IsBuyer, setIsBuyer] = useState("Yes");
  const [IsDisclosure, setIsDisclosure] = useState("No");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });

  const addDisclosure = (e) => {
    setIsDisclosure("Yes");
    if (
      DealDocs.includes(
        "Registrant's Disclosure of Interest - Disposition (161)"
      ) === true
    ) {
      return;
    } else {
      setDealDocs([
        ...DealDocs,
        "Registrant's Disclosure of Interest - Disposition (161)",
      ]);
    }
  };

  const removeDisclosure = (e) => {
    setIsDisclosure("No");
    setDealDocs(
      DealDocs.filter(
        (item) =>
          item !== "Registrant's Disclosure of Interest - Disposition (161)"
      )
    );
  };

  const setAssignment = (e) => {
    setIsBuyer("No");
    let DealData = DealDocs;
    let newData = DealData.toSpliced(0, 1, e.target.value);
    setDealDocs(newData);
    if (
      e.target.value === "Assignment of Agreement of Purchase & Sale (145)" &&
      DealDocs.includes("Original Purchase Contract (Schedule C)") === false
    ) {
      setDealDocs(
        newData.toSpliced(1, 0, "Original Purchase Contract (Schedule C)")
      );
    }
  };

  const setResidential = (e) => {
    setIsBuyer("Yes");
    let DealData = DealDocs;
    let newData = DealData.toSpliced(0, 1, e.target.value);
    setDealDocs(newData);
    if (DealDocs.includes("Original Purchase Contract (Schedule C)") === true) {
      setDealDocs(
        newData.filter(
          (item) => item !== "Original Purchase Contract (Schedule C)"
        )
      );
    }
  };

  return (
    <div className="checklists w-full flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-2">
          <div className="paragraph">
            <p>
              In this Scenario, you have a Seller Client and the Buyer chooses
              to be Self-Represented.
            </p>
          </div>
          <div className="pr-5 pt-5">Type of Listing?</div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="Agreement of Purchase & Sale (100 series)"
              >
                <FormControlLabel
                  value="Agreement of Purchase & Sale (100 series)"
                  control={<Radio size="small" onClick={setResidential} />}
                  label="Residential"
                />

                <FormControlLabel
                  value="Assignment of Agreement of Purchase & Sale (145)"
                  control={<Radio size="small" onClick={setAssignment} />}
                  label="Assignment"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>

          <div className="pr-5 pt-5">
            Are you or close relatives selling this property?
          </div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" onClick={addDisclosure} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" onClick={removeDisclosure} />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      <ChecklistCard
        CheckListData={RepresentationData}
        CheckListData2={DealDocs}
        Header={
          IsBuyer === "Yes"
            ? "Purchase: Buyer is an SRP"
            : "Assignment: Assignee is an SRP"
        }
        Header2={
          IsBuyer === "Yes" ? "(Seller is Client)" : "(Assignor is Client)"
        }
        Title1="Buyer SRP Documents"
        Title2="Deal Documents"
      />
      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>
        <div className="pb-3"></div>
        {IsDisclosure === "Yes" ? <DisclosureNotes /> : ""}
        <SRPNotes />
      </div>
    </div>
  );
}
