import React from "react";
import "../body/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faBookOpen,
  faSquareCheck,
  faPeopleGroup,
  faFile,
  faFileImport,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Home() {
  const scrollTop = (e) => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="home-wrapper  w-full relative">
      <div className="home-pic relative">
        <img src="../../images/txnguide2.png" alt="home-pic" />
      </div>
      <br />
      <div className="flex flex-wrap justify-center">
        <div className="px-2">
          <Link to="/transaction-checklists" style={{ textDecoration: "none" }}>
            <div className="flex flex-col justify-center home-buttons ">
              <div className="flex items-center justify-center  ">
                <FontAwesomeIcon
                  icon={faListCheck}
                  color="#3d5a80"
                  className="home-button"
                  onClick={scrollTop}
                />
              </div>
              <div className="flex leading-none w-full justify-center mt-1">
                Transaction
                <br />
                &nbsp; Checklists
              </div>
            </div>
          </Link>
        </div>
        <div className="px-2">
          <Link
            to="/form-320"
            style={{ textDecoration: "none" }}
            onClick={scrollTop}
          >
            <div className="flex flex-col justify-center home-buttons ">
              <div className="flex items-center justify-center  ">
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  color="#3d5a80"
                  className="home-button"
                />
              </div>
              <div className="flex justify-center items-center mt-3">
                320/324 Boxes
              </div>
            </div>
          </Link>
        </div>
        <div className="px-2">
          <Link
            to="/reco-info-guide"
            style={{ textDecoration: "none" }}
            onClick={scrollTop}
          >
            <div className="flex flex-col justify-center home-buttons ">
              <div className="flex items-center justify-center  ">
                <FontAwesomeIcon
                  icon={faBookOpen}
                  color="#3d5a80"
                  className="home-button"
                />
              </div>
              <div className="flex justify-center mt-3">RECO Guide</div>
            </div>
          </Link>
        </div>
        <div className="px-2">
          <Link
            to="/multiple-representation"
            style={{ textDecoration: "none" }}
            onClick={scrollTop}
          >
            <div className="flex flex-col justify-center home-buttons ">
              <div className="flex items-center justify-center  ">
                <FontAwesomeIcon
                  icon={faPeopleGroup}
                  color="#3d5a80"
                  className="home-button"
                />
              </div>
              <div className="flex justify-center mt-3">Multiple Rep</div>
            </div>
          </Link>
        </div>
        <div className="px-2">
          <Link
            to="/common-forms"
            style={{ textDecoration: "none" }}
            onClick={scrollTop}
          >
            <div className="flex flex-col justify-center home-buttons ">
              <div className="flex items-center justify-center  ">
                <FontAwesomeIcon
                  icon={faFile}
                  color="#3d5a80"
                  className="home-button"
                />
              </div>
              <div className="flex justify-center mt-3">Common Forms</div>
            </div>
          </Link>
        </div>
        <div className="px-2">
          <Link
            to="/office-documents"
            style={{ textDecoration: "none" }}
            onClick={scrollTop}
          >
            <div className="flex flex-col justify-center home-buttons ">
              <div className="flex items-center justify-center  ">
                <FontAwesomeIcon
                  icon={faFileImport}
                  color="#3d5a80"
                  className="home-button"
                />
              </div>
              <div className="flex justify-center mt-3">Office Procedures</div>
            </div>
          </Link>
        </div>
        <div className="px-2">
          <Link
            to="/custom-app"
            style={{ textDecoration: "none" }}
            onClick={scrollTop}
          >
            <div className="flex flex-col justify-center home-buttons ">
              <div className="flex items-center justify-center  ">
                <FontAwesomeIcon
                  icon={faCartPlus}
                  color="#3d5a80"
                  className="home-button"
                />
              </div>
              <div className="flex justify-center mt-3">Custom App</div>
            </div>
          </Link>
        </div>
      </div>
      <br />
      <p>
        <strong>
          IMPORTANT: This web application is suited for REALTORS® whose
          Brokerage operates under the "Designated Representation Model"
        </strong>
      </p>
      <br />
      <p>
        These checklists are general guidance on common residential real estate
        transactions and are not intended to be a comprehensive list. Each deal
        is unique, so the responsibility is on every REALTOR® to perform proper
        due diligence as part of their legal obligation to provide competent
        service. These lists have been compiled based on best practices.
      </p>
      <br />
      <p>
        OREA form revisions are made at the outset of each year, and we are
        legally required to be familiar with the contents and use the current
        forms.
      </p>
      <br />

      <p>
        REALTORS® are also legally obligated to use the appropriate form for a
        given situation. For a full database of the latest OREA forms, clause
        booklets, OREA webinars and many other helpful training/webinar
        resources please check out the{" "}
        <a href="https://www.orea.com/" rel="noreferrer" target="_blank">
          {" "}
          OREA Website
        </a>
        .
      </p>
      <br />
      <br />
    </div>
  );
}
