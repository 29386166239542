const FormPageData = [
  {
    id: 1001,
    form: `100`,
    page: `1`,
    imageURL: "../../forms/100/form100-1.png",
    description: `<p>The Agreement of Purchase and Sale (APS) stands as 
    the primary document employed in acquiring residential resale properties. As you 
    complete this agreement, it is imperative to cross-reference all property 
    details with secondary sources like GeoWarehouse and MPAC. While the listing 
    agent bears the responsibility of ensuring listing accuracy, it remains crucial 
    to undertake thorough due diligence for your client, refraining from solely 
    depending on the listing information.
     </p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p>This particular date corresponds to the offer's creation date and 
        serves as a point of reference for subsequent documents you might incorporate 
        or utilize later on, including but not limited to: Amendments, Notices of 
        Fulfillments/Waivers, Mutual Releases, and so forth.</p>`,
      },
      {
        x: `1.5%`,
        y: `19%`,
        data: `<p>Ensure that you input the complete legal names of both Buyers and 
        Sellers. Take the extra step of cross-referencing your clients' IDs to 
        guarantee accuracy. Verify the Seller names using GeoWarehouse; refrain 
        from simply copying them from the listing as discrepancies in names may 
        exist.</p>`,
      },
      {
        x: `1.5%`,
        y: `30%`,
        data: `<p><ol><li>Enter the full address of the property.  Verify address 
        details in GeoWarehouse</li><li><strong>fronting on:
        </strong> indicates the property's orientation in relation to the street 
        (e.g., "fronting on the North side of Main Street" means the property's 
        front faces the North side of Main Street). </li><li>Frontage and depth 
        length (lot dimensions)
        typically found in GeoWarehouse</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `40%`,
        data: `<p><strong>Legal Description:</strong> Verify the legal description in 
        GeoWarehouse, do not copy from listing</p>`,
      },
      {
        x: `1.5%`,
        y: `49%`,
        data: `<p><strong>Purchase Price:</strong> This is the price the Buyer is 
        offering for the property.  It's important to confirm that the price is 
        consistent in both numerical and textual formats (example: $500,000 / 
        Five Hundred Thousand)</p>`,
      },
      {
        x: `1.5%`,
        y: `53%`,
        data: `<p><strong>Deposit Submit Options:</strong> 3 Options to choose from 
        <ol><li><strong>upon acceptance</strong> This option means the deposit is due 
        within 24 hours of acceptance (example: if offer is accepted Friday at 10pm, 
        deposit is due Saturday at 10pm)</li><li><strong>as otherwise described in 
        this Agreement:</strong> Used when the Buyer may not be able to make their 
        deposit within the 24 hour timeframe (ie. weekend delivery). Details of when 
        the deposit is due should be clearly explained in Schedule A. Could be used 
        for situations involving multiple deposits and when they are due.</li>
        <li><strong>Herewith:</strong> This is rarely used nowadays but when this 
        is selected the Buyer is providing the deposit with the offer.</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `58%`,
        data: `<p><strong>Deposit Amount:</strong> This is the amount of deposit the 
        Buyer will be submitting according to the terms above (upon acceptance, as 
        otherwise described...). Ensure both numbers and text match. <br/><strong>
        Deposit Holder:</strong> Insert where the deposit will be held. This is 
        typically the listing brokerage in most circumstances. Sometimes it can be 
        the clients' lawyer, if requested. </p>`,
      },
      {
        x: `1.5%`,
        y: `70%`,
        data: `<p><strong>Schedules:</strong> As per the statement, Schedule A is 
        already to be included with this agreement. Add additional schedules/forms if 
        applicable. Many times, listings have additional schedules uploaded to the 
        listing that are to be sent with the offer (example: Schedule B).  
        Download these schedules, add them to the agreement and insert the 
        letter/number of that schedule here.</p>`,
      },
      {
        x: `1.5%`,
        y: `75%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Buyer or 
        Seller, presenting the offer is bound by it until the specified time. 
        Moreover, if the Seller intends to accept or counter the offer, they should 
        do so before the stipulated time. You must communicate
        with the other party/agent that an action has been taken before this time 
        expires. 
        After this period elapses without acceptance, the offer becomes null 
        and void.</p>`,
      },
      {
        x: `1.5%`,
        y: `81%`,
        data: `<p><strong>Completion Date:</strong> This is the proposed date of 
        closing (the date your clients would like to take ownership of the property). 
        Ensure that the date placed here is not a weekend or holiday. </p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1002,
    form: `100`,
    page: `2`,
    imageURL: `../../forms/100/form100-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `24%`,
        data: `<p>Insert your email here (the side you represent) for the delivery 
        of notices (Notice of Fulfillment, Waivers...etc.).<br/><br/><strong>Important:
        </strong> If your deal involves Multiple Representation, the agent cannot 
        accept notices on behalf of the clients or acknowledge their receipt. As a 
        result, leave this area empty or insert your clients' or lawyers emails 
        for the delivery of notices, as they will have to acknowledge receipt of those
        documents.</p>`,
      },
      {
        x: `1.5%`,
        y: `31%`,

        data: `<p><strong>Chattels:</strong> These are items that are to be included
        in the purchase price and typically involve items not fixed to the property. 
        Chattels are movable items such as: appliances, garage door remotes, mirrors
        not fixed to the walls...etc. It is important to be as detailed as possible
        when describing the chattels to be included. Some items such as fixed tv mounts
        have been greatly debated as to whether they need to be included in chattels. 
        As a rule of thumb, "if in doubt, include it" so that it is clear to all parties.</p>`,
      },
      {
        x: `1.5%`,
        y: `49%`,

        data: `<p><strong>Fixtures Excluded:</strong> These are items that are
        attached to the home but the Seller wishes to exclude them from the sale. 
        One reason may be that the item listed has sentimental value like a lighting 
        fixture. Precision is key when itemizing excluded fixtures, for instance, 
        specifying "bedroom light fixture."</p>`,
      },
      {
        x: `1.5%`,
        y: `65%`,

        data: `<p><strong>Rental Items:</strong> These are items that are not owned
        by the Seller but instead leased/rented. If there are any items in the listing
        that are rented, be sure to include them in this section.  Otherwise, you may
        end up having to cover the cost to buyout that item on closing. In most 
        circumstances, the water heater is listed here as it is very commonly rented. 
        If you are representing the buyer and there are no rental items, insert "None".
    </p>`,
      },
      {
        x: `1.5%`,
        y: `80%`,
        data: `<p><strong>HST:</strong><ol><li><strong>included in:</strong> Means 
        that HST is included in the purchase price/exempt from the sale.</li>
        <li><strong>in addition to:</strong> Means HST is added onto the purchase
        price. (example: if you offer $500,000 the price will be $500,000 + HST).
        </li></ol> In most circumstances, residential resale
        purchases are exempt from HST and so "included in" should be selected. If
        you run into a circumstance where it is suggested that HST should be "in 
        addition to", have your client consult with an accountant/lawyer before
        making or agreeing to an offer. </p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1003,
    form: `100`,
    page: `3`,
    imageURL: `../../forms/100/form100-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Requisition Date:</strong> This marks the deadline by 
        which the lawyer should finalize tasks like conducting a title search, 
        searching for any pending work orders, etc., to ensure the title's absence 
        of encumbrances. Usually, this date is set approximately two weeks prior 
        to the closing date. However, for shorter closing periods, it may be nearer 
        to the closing date.</p>`,
      },
      {
        x: `1.5%`,
        y: `10%`,
        data: `<p><strong>Present Use:</strong> This is the intended use of the 
        property which usually aligns with its current use (example: Single Family
        Residential). It is important to verify the zoning of the property in order 
        to confirm that its intended/current use is allowed. Confirming zoning, 
        also gives the Buyer the idea as to whether or not they can potentially 
        use the property for its intended purpose, especially if they have different
        plans for the property. This should also be investigated & verified with the 
        local municipality.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1004,
    form: `100`,
    page: `4`,
    imageURL: `../../forms/100/form100-4.png`,
    hotspots: [
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1005,
    form: `100`,
    page: `5`,
    imageURL: `../../forms/100/form100-5.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p><strong>Successor and Assigns:</strong> Clients signify their 
        agreement to the offer terms by signing at this location. The offer may 
        undergo multiple rounds of back-and-forth signing (signbacks). </p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p><strong>Spousal Consent:</strong> In the case where this property 
        is deemed the matrimonial home and the spouse, although not listed on the 
        title, is necessary to grant consent for the property's sale, please have 
        the spouse sign at this location.</p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Seller initiates a signback and the Buyer 
          agrees to the terms, the Buyer signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },
      {
        x: `1.5%`,
        y: `50%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `65%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign, after an accepted agreement, to confirm they received a copy of
        the signed agreement. It is important that you ensure your clients receive a 
        fully signed copy of the agreement, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage after the agreement is 
        signed and when your clients choose who they will use to facilitate the 
        closing of the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `89%`,
        data: `<p><strong>Commission Trust Agreement</strong> If there is more than
        one brokerage involved in the transaction, the agents representing their 
        respective side should sign here. If only one brokerage involved, there is
        no need to sign as a commission trust agreement does not need to be 
        created.</p>`,
      },
    ],
  },
  {
    id: 1006,
    form: `100`,
    page: `6`,
    imageURL: `../../forms/100/form100-6.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Buyers/Sellers names here, same as indicated on page 1 of 
        this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p>Add all applicable conditions and clauses pertaining to the 
        Agreement in this section. The OREA Clause Booklet, available on the OREA 
        Website, contains most of the clauses commonly used. For any customized 
        clauses, it's essential to seek legal advice to ensure they are drafted 
        correctly and protect your clients' best interests.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1011,
    form: `101`,
    page: `1`,
    imageURL: "../../forms/101/form101-1.png",
    description: `<p>This Agreement of Purchase and Sale (APS) is similar to Form
    100 but is specific to the purchase of Condominiums. As you 
    complete this agreement, it is imperative to cross-reference all property 
    details with secondary sources like GeoWarehouse and MPAC. While the listing 
    agent bears the responsibility of ensuring listing accuracy, it remains crucial 
    to undertake thorough due diligence for your client, refraining from solely 
    depending on the listing information.
     </p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p>This particular date corresponds to the offer's creation date and 
        serves as a point of reference for subsequent documents you might incorporate 
        or utilize later on, including but not limited to: Amendments, Notices of 
        Fulfillments/Waivers, Mutual Releases, and so forth.</p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Ensure that you input the complete legal names of both Buyers and 
        Sellers. Take the extra step of cross-referencing your clients' IDs to 
        guarantee accuracy. Verify the Seller names using GeoWarehouse; refrain 
        from simply copying them from the listing as discrepancies in names may 
        exist.</p>`,
      },
      {
        x: `1.5%`,
        y: `25%`,
        data: `<p>Enter the full municipal address of the property, including 
        the Unit Number (if applicable).  Select the type of unit that applies 
        (Apartment, Townhouse, Suite or Unit). Verify address details in 
        GeoWarehouse.</p>`,
      },
      {
        x: `1.5%`,
        y: `32%`,
        data: `<p>Enter the Condominium Corporation Name along with its Plan Number. 
        You can verify these details in GeoWarehouse in the "Condominium Details"
         Section.</p>`,
      },
      {
        x: `1.5%`,
        y: `37%`,
        data: `<p><strong>Legal Description:</strong> The information here represents
        how the unit is described in the condo declaration and description.  This 
        information can be verified in GeoWarehouse and is located in the Legal 
        Description as well as the "Condominium Details" section.  This is not the
        municipal address information. Sometimes only certain details apply and  
        not all fields are necessary (example: sometimes you may only have Unit, 
        Level and no Building No.). Complete all the fields with available 
        information, as well as any Parking/Locker details (if applicable).</p>`,
      },
      {
        x: `1.5%`,
        y: `50%`,
        data: `<p><strong>Purchase Price:</strong> This is the price the Buyer is 
        offering for the property.  It's important to confirm that the price is 
        consistent in both numerical and textual formats (example: $500,000 / 
        Five Hundred Thousand)</p>`,
      },
      {
        x: `1.5%`,
        y: `56%`,
        data: `<p><strong>Deposit Submit Options:</strong> 3 Options to choose from 
        <ol><li><strong>upon acceptance</strong> This option means the deposit is due 
        within 24 hours of acceptance (example: if offer is accepted Friday at 10pm, 
        deposit is due Saturday at 10pm)</li><li><strong>as otherwise described in 
        this Agreement:</strong> Used when the Buyer may not be able to make their 
        deposit within the 24 hour timeframe (ie. weekend delivery). Details of when 
        the deposit is due should be clearly explained in Schedule A. Could be used 
        for situations involving multiple deposits and when they are due.</li>
        <li><strong>Herewith:</strong> This is rarely used nowadays but when this 
        is selected the Buyer is providing the deposit with the offer.</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `60%`,
        data: `<p><strong>Deposit Amount:</strong> This is the amount of deposit the 
        Buyer will be submitting according to the terms above (upon acceptance, as 
        otherwise described...). Ensure both numbers and text match. <br/><strong>
        Deposit Holder:</strong> Insert where the deposit will be held. This is 
        typically the listing brokerage in most circumstances. Sometimes it can be 
        the clients' lawyer, if requested. </p>`,
      },
      {
        x: `1.5%`,
        y: `72%`,
        data: `<p><strong>Schedules:</strong> As per the statement, Schedule A is 
        already to be included with this agreement. Add additional schedules/forms if 
        applicable. Many times, listings have additional schedules uploaded to the 
        listing that are to be sent with the offer (example: Schedule B).  
        Download these schedules, add them to the agreement and insert the 
        letter/number of that schedule here.</p>`,
      },
      {
        x: `1.5%`,
        y: `76%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Buyer or 
        Seller, presenting the offer is bound by it until the specified time. 
        Moreover, if the Seller intends to accept or counter the offer, they should 
        do so before the stipulated time. You must communicate
        with the other party/agent that an action has been taken before this time 
        expires. 
        After this period elapses without acceptance, the offer becomes null 
        and void.</p>`,
      },
      {
        x: `1.5%`,
        y: `84%`,
        data: `<p><strong>Completion Date:</strong> This is the proposed date of 
        closing (the date your clients would like to take ownership of the property). 
        Ensure that the date placed here is not a weekend or holiday. </p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1012,
    form: `101`,
    page: `2`,
    imageURL: `../../forms/101/form101-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Insert your email here (the side you represent) for the delivery 
        of notices (Notice of Fulfillment, Waivers...etc.).<br/><br/><strong>Important:
        </strong> If your deal involves Multiple Representation, the agent cannot 
        accept notices on behalf of the clients or acknowledge their receipt. As a 
        result, leave this area empty or insert your clients' or lawyers emails 
        for the delivery of notices, as they will have to acknowledge receipt of those
        documents.</p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,

        data: `<p><strong>Chattels:</strong> These are items that are to be included
        in the purchase price and typically involve items not fixed to the property. 
        Chattels are movable items such as: appliances, garage door remotes, mirrors
        not fixed to the walls...etc. It is important to be as detailed as possible
        when describing the chattels to be included. Some items such as fixed tv mounts
        have been greatly debated as to whether they need to be included in chattels. 
        As a rule of thumb, "if in doubt, include it" so that it is clear to all parties.</p>`,
      },
      {
        x: `1.5%`,
        y: `46%`,

        data: `<p><strong>Fixtures Excluded:</strong> These are items that are
        attached to the home but the Seller wishes to exclude them from the sale. 
        One reason may be that the item listed has sentimental value like a lighting 
        fixture. It is important to be as detailed as possible  when listing excluded
        fixtures (example: "bedroom light fixture").</p>`,
      },
      {
        x: `1.5%`,
        y: `58%`,
        data: `<p><strong>Rental Items:</strong> These are items that are not owned
        by the Seller but instead leased/rented. If there are any items in the listing
        that are rented, be sure to include them in this section.  Otherwise, you may
        end up having to cover the cost to buyout that item on closing. In most 
        circumstances, the water heater is listed here as it is very commonly rented. 
        If you are representing the buyer and there are no rental items, insert "None".
    </p>`,
      },
      {
        x: `1.5%`,
        y: `73%`,
        data: `<p><strong>Common Expenses:</strong> Enter the common expenses as 
        indicated in the listing. In addition, enter what is covered in the common
        element fee. These details can be verified in the status 
        certificate which can be obtained from the property management company. 
        Typically a status certificate review clause is added to this agreement so 
        that the lawyer can review all details.
    </p>`,
      },
      {
        x: `1.5%`,
        y: `83%`,
        data: `<p><strong>Parking and Lockers:</strong> If Parking and/or Lockers 
        are included, add the numbers (if assigned) in this location. If unassigned
        but they are still given a spot/locker, notate those details here. If they 
        have the opportunity to purchase additional spaces for a cost, enter that
        here.</p>`,
      },

      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1013,
    form: `101`,
    page: `3`,
    imageURL: `../../forms/101/form101-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>HST:</strong><ol><li><strong>included in:</strong> Means 
        that HST is included in the purchase price/exempt from the sale.</li>
        <li><strong>in addition to:</strong> Means HST is added onto the purchase
        price. (example: if you offer $500,000 the price will be $500,000 + HST).
        </li></ol> In most circumstances, condo resale
        purchases are exempt from HST and so "included in" should be selected. If
        you run into a circumstance where it is suggested that HST should be "in 
        addition to", have your client consult with an accountant/lawyer before
        making or agreeing to an offer. </p>`,
      },
      {
        x: `1.5%`,
        y: `11%`,
        data: `<p><strong>Requisition Date:</strong> This marks the deadline by 
        which the lawyer should finalize tasks like conducting a title search, 
        searching for any pending work orders, etc., to ensure the title's absence 
        of encumbrances. Usually, this date is set approximately two weeks prior 
        to the closing date. However, for shorter closing periods, it may be nearer 
        to the closing date.</p>`,
      },
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p><strong>Present Use:</strong> This is the intended use of the 
        property which usually aligns with its current use (example: Single Family
        Residential). It is important to verify the zoning of the property in order 
        to confirm that its intended/current use is allowed. Confirming zoning, 
        also gives the Buyer the idea as to whether or not they can potentially 
        use the property for its intended purpose, especially if they have different
        plans for the property. This should also be investigated & verified with the 
        local municipality.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1014,
    form: `101`,
    page: `4`,
    imageURL: `../../forms/101/form101-4.png`,
    hotspots: [
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1015,
    form: `101`,
    page: `5`,
    imageURL: `../../forms/101/form101-5.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `9%`,
        data: `<p><strong>Successor and Assigns:</strong> Clients signify their 
        agreement to the offer terms by signing at this location. The offer may 
        undergo multiple rounds of back-and-forth signing (signbacks). </p>`,
      },
      {
        x: `1.5%`,
        y: `28%`,
        data: `<p><strong>Spousal Consent:</strong> In the case where this property 
        is deemed the matrimonial home and the spouse, although not listed on the 
        title, is necessary to grant consent for the property's sale, please have 
        the spouse sign at this location.</p>`,
      },
      {
        x: `1.5%`,
        y: `34%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Seller initiates a signback and the Buyer 
          agrees to the terms, the Buyer signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },
      {
        x: `1.5%`,
        y: `45%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `54%`,
        data: `<p>Insert the Property Management Company details for the condominium
         here. This is typically the same company your Buyer will contact to order 
         the status certificate.</p>`,
      },
      {
        x: `1.5%`,
        y: `62%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign, after an accepted agreement, to confirm they received a copy of
        the signed agreement. It is important that you ensure your clients receive a 
        fully signed copy of the agreement, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage after the agreement is 
        signed and when your clients choose who they will use to facilitate the 
        closing of the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `89%`,
        data: `<p><strong>Commission Trust Agreement</strong> If there is more than
        one brokerage involved in the transaction, the agents representing their 
        respective side should sign here. If only one brokerage involved, there is
        no need to sign as a commission trust agreement does not need to be 
        created.</p>`,
      },
    ],
  },
  {
    id: 1016,
    form: `101`,
    page: `6`,
    imageURL: `../../forms/101/form101-6.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Buyers/Sellers names here, same as indicated on page 1 of 
        this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p>Add all applicable conditions and clauses pertaining to the 
        Agreement in this section. The OREA Clause Booklet, available on the OREA 
        Website, contains most of the clauses commonly used. For any customized 
        clauses, it's essential to seek legal advice to ensure they are drafted 
        correctly and protect your clients' best interests.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1021,
    form: `102`,
    page: `1`,
    imageURL: "../../forms/102/form102-1.png",
    description: `<p>This Agreement of Purchase and Sale (APS) is similar to Form
    100 but is specific to the purchase of Co-operatives. Co-operatives differ 
    greatly with respect to ownership. In a co-operative, the Buyer is purchasing
    a share in the corporation with a right to occupy a specific unit. As a result, 
    the Buyer does not hold title to a specific unit. The board has to approve 
    the sale (Clause #10 - Page 3) in order for the Seller to transfer their share
    to the Buyer.
     </p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p>This particular date corresponds to the offer's creation date and 
        serves as a point of reference for subsequent documents you might incorporate 
        or utilize later on, including but not limited to: Amendments, Notices of 
        Fulfillments/Waivers, Mutual Releases, and so forth.</p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Ensure that you input the complete legal names of both Buyers and 
        Sellers. Take the extra step of cross-referencing your clients' IDs to 
        guarantee accuracy. Verify the Seller names using GeoWarehouse; refrain 
        from simply copying them from the listing as discrepancies in names may 
        exist.</p>`,
      },
      {
        x: `1.5%`,
        y: `30%`,
        data: `<p>Enter the full address of the property and Unit to 
        be occupied.  Verify address details in GeoWarehouse. </p>`,
      },
      {
        x: `1.5%`,
        y: `36%`,
        data: `<p>Enter the Parking and Locker # (if applicable). </p>`,
      },
      {
        x: `1.5%`,
        y: `40%`,
        data: `<p>With a co-op the Buyer is buying a share in the corporation with
        a right to occupy.  Typically the share value is "1", enter the value here
        along with the Corporation Name.</p>`,
      },
      {
        x: `1.5%`,
        y: `45%`,
        data: `<p><strong>Purchase Price:</strong> This is the price the Buyer is 
        offering for the property.  It's important to confirm that the price is 
        consistent in both numerical and textual formats (example: $500,000 / 
        Five Hundred Thousand)</p>`,
      },
      {
        x: `1.5%`,
        y: `49%`,
        data: `<p><strong>Deposit Submit Options:</strong> 3 Options to choose from 
        <ol><li><strong>upon acceptance</strong> This option means the deposit is due 
        within 24 hours of acceptance (example: if offer is accepted Friday at 10pm, 
        deposit is due Saturday at 10pm)</li><li><strong>as otherwise described in 
        this Agreement:</strong> Used when the Buyer may not be able to make their 
        deposit within the 24 hour timeframe (ie. weekend delivery). Details of when 
        the deposit is due should be clearly explained in Schedule A. Could be used 
        for situations involving multiple deposits and when they are due.</li>
        <li><strong>Herewith:</strong> This is rarely used nowadays but when this 
        is selected the Buyer is providing the deposit with the offer.</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `54%`,
        data: `<p><strong>Deposit Amount:</strong> This is the amount of deposit the 
        Buyer will be submitting according to the terms above (upon acceptance, as 
        otherwise described...). Ensure both numbers and text match. <br/><strong>
        Deposit Holder:</strong> Insert where the deposit will be held. This is 
        typically the listing brokerage in most circumstances. Sometimes it can be 
        the clients' lawyer, if requested. </p>`,
      },
      {
        x: `1.5%`,
        y: `68%`,
        data: `<p><strong>Schedules:</strong> As per the statement, Schedule A is 
        already to be included with this agreement. Add additional schedules/forms if 
        applicable. Many times, listings have additional schedules uploaded to the 
        listing that are to be sent with the offer (example: Schedule B).  
        Download these schedules, add them to the agreement and insert the 
        letter/number of that schedule here.</p>`,
      },
      {
        x: `1.5%`,
        y: `73%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Buyer or 
        Seller, presenting the offer is bound by it until the specified time. 
        Moreover, if the Seller intends to accept or counter the offer, they should 
        do so before the stipulated time. You must communicate
        with the other party/agent that an action has been taken before this time 
        expires. 
        After this period elapses without acceptance, the offer becomes null 
        and void.</p>`,
      },
      {
        x: `1.5%`,
        y: `81%`,
        data: `<p><strong>Completion Date:</strong> This is the proposed date of 
        closing (the date your clients would like to take ownership of the home). 
        Ensure that the date placed here is not a weekend or holiday. </p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1022,
    form: `102`,
    page: `2`,
    imageURL: `../../forms/102/form102-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `24%`,
        data: `<p>Insert your email here (the side you represent) for the delivery 
        of notices (Notice of Fulfillment, Waivers...etc.).<br/><br/><strong>Important:
        </strong> If your deal involves Multiple Representation, the agent cannot 
        accept notices on behalf of the clients or acknowledge their receipt. As a 
        result, leave this area empty or insert your clients' or lawyers emails 
        for the delivery of notices, as they will have to acknowledge receipt of those
        documents.</p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,

        data: `<p><strong>Chattels:</strong> These are items that are to be included
        in the purchase price and typically involve items not fixed to the property. 
        Chattels are movable items such as: appliances, garage door remotes, mirrors
        not fixed to the walls...etc. It is important to be as detailed as possible
        when describing the chattels to be included. Some items such as fixed tv mounts
        have been greatly debated as to whether they need to be included in chattels. 
        As a rule of thumb, "if in doubt, include it" so that it is clear to all parties.</p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,

        data: `<p><strong>Fixtures Excluded:</strong> These are items that are
        attached to the home but the Seller wishes to exclude them from the sale. 
        One reason may be that the item listed has sentimental value like a lighting 
        fixture. It is important to be as detailed as possible  when listing excluded
        fixtures (example: "bedroom light fixture").</p>`,
      },
      {
        x: `1.5%`,
        y: `60%`,

        data: `<p><strong>Rental Items:</strong> These are items that are not owned
        by the Seller but instead leased/rented. If there are any items in the listing
        that are rented, be sure to include them in this section.  Otherwise, you may
        end up having to cover the cost to buyout that item on closing. In most 
        circumstances, the water heater is listed here as it is very commonly rented. 
        If you are representing the buyer and there are no rental items, insert "None".
    </p>`,
      },

      {
        x: `1.5%`,
        y: `77%`,
        data: `<p><strong>Maintenance Expenses:</strong>Insert the cost of monthly 
        expenses and what that cost includes. The details of these items can be 
        verified in the Status Certificate.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1023,
    form: `102`,
    page: `3`,
    imageURL: `../../forms/102/form102-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Parking and Lockers:</strong> Enter the assigned parking
        and lockers associated with the property (if applicable). If the Buyer has
        the opportunity to purchase additional spaces, note the cost to do so here.
        These items can all be verified in the Status Certificate. </p>`,
      },
      {
        x: `1.5%`,
        y: `10%`,
        data: `<p><strong>HST:</strong><ol><li><strong>included in:</strong> Means 
        that HST is included in the purchase price/exempt from the sale.</li>
        <li><strong>in addition to:</strong> Means HST is added onto the purchase
        price. (example: if you offer $500,000 the price will be $500,000 + HST).
        </li></ol> In most circumstances, co-operative resale
        purchases are exempt from HST and so "included in" should be selected. If
        you run into a circumstance where it is suggested that HST should be "in 
        addition to", have your client consult with an accountant/lawyer before
        making or agreeing to an offer. </p>`,
      },
      {
        x: `1.5%`,
        y: `22%`,
        data: `<p><strong>Approval:</strong> With a co-operative purchase, the Seller
        needs to obtain approval from the Board of Directors to sell and transfer
        their Shares to the Buyer. This condition will have to be waived by the Seller
        and is part of the agreements. If the Seller fails to waive by the
        specified time, then the offer is considered null and void. The Buyer will have
        to provide information requested by the Board so that they can evaluate and 
        assess approval.</p>`,
      },
      {
        x: `1.5%`,
        y: `28%`,
        data: `<p><strong>Requisition Date:</strong> This marks the deadline by 
        which the lawyer should finalize tasks like conducting a title search, 
        searching for any pending work orders, etc., to ensure the title's absence 
        of encumbrances. Usually, this date is set approximately two weeks prior 
        to the closing date. However, for shorter closing periods, it may be nearer 
        to the closing date.</p>`,
      },

      {
        x: `1.5%`,
        y: `34%`,
        data: `<p><strong>Present Use:</strong> This is the intended use of the 
        property which usually aligns with its current use (example: Single Family
        Residential). It is important to verify the zoning of the property in order 
        to confirm that its intended/current use is allowed. Confirming zoning, 
        also gives the Buyer the idea as to whether or not they can potentially 
        use the property for its intended purpose, especially if they have different
        plans for the property. This should also be investigated & verified with the 
        local municipality.</p>`,
      },
      {
        x: `1.5%`,
        y: `48%`,
        data: `<p>This clause is important to point out for this specific type of 
        transaction. Certain items as noted must be delivered by the Seller on or 
        before closing.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1024,
    form: `102`,
    page: `4`,
    imageURL: `../../forms/102/form102-4.png`,
    hotspots: [
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1025,
    form: `102`,
    page: `5`,
    imageURL: `../../forms/102/form102-5.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Successor and Assigns:</strong> Clients signify their 
        agreement to the offer terms by signing at this location. The offer may 
        undergo multiple rounds of back-and-forth signing (signbacks). </p>`,
      },
      {
        x: `1.5%`,
        y: `30%`,
        data: `<p><strong>Spousal Consent:</strong> In the case where this property 
        is deemed the matrimonial home and the spouse, although not listed on the 
        title, is necessary to grant consent for the property's sale, please have 
        the spouse sign at this location.</p>`,
      },
      {
        x: `1.5%`,
        y: `36%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Seller initiates a signback and the Buyer 
          agrees to the terms, the Buyer signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },
      {
        x: `1.5%`,
        y: `46%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `46%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `54%`,
        data: `<p>Insert the Property Management Company details for the co-operative
         here. This is typically the same company your Buyer will contact to order 
         the status certificate.</p>`,
      },
      {
        x: `1.5%`,
        y: `64%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign, after an accepted agreement, to confirm they received a copy of
        the signed agreement. It is important that you ensure your clients receive a 
        fully signed copy of the agreement, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage after the agreement is 
        signed and when your clients choose who they will use to facilitate the 
        closing of the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `89%`,
        data: `<p><strong>Commission Trust Agreement</strong> If there is more than
        one brokerage involved in the transaction, the agents representing their 
        respective side should sign here. If only one brokerage involved, there is
        no need to sign as a commission trust agreement does not need to be 
        created.</p>`,
      },
    ],
  },
  {
    id: 1026,
    form: `102`,
    page: `6`,
    imageURL: `../../forms/102/form102-6.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `17%`,
        data: `<p>Insert Buyers/Sellers names here, same as indicated on page 1 of 
        this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p>Add all applicable conditions and clauses pertaining to the 
        Agreement in this section. The OREA Clause Booklet, available on the OREA 
        Website, contains most of the clauses commonly used. For any customized 
        clauses, it's essential to seek legal advice to ensure they are drafted 
        correctly and protect your clients' best interests.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1101,
    form: `110`,
    page: `1`,
    imageURL: "../../forms/110/form110-1.png",
    description: `<p>This Agreement of Purchase and Sale (APS) is similar to Form 
    100 but is specific to the purchase of Mobile/Modular/Manufactured homes. This
    APS is commonly used when a Buyer is looking to purchase within a Mobile Home
    Community and/or Leased Land. This APS contains some pre-printed
    clauses, worth noting, relating to leased land approvals 
    (Clause #8 and #9 on page 3). Schedule B (Lease) is included as a requirement 
    for this agreement, since review and approval is typically required as part of 
    the process. 
    </p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p>This date relates to the date the offer is created and is used as 
        reference for the date on other documents you may include or use later such as: 
        <br/>Amendments, Notice of Fullfilments/Waivers, Mutual Releases...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Enter the full legal names of all Buyers & Sellers. 
        Double check your clients IDs to ensure accuracy. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Enter the full address of the property (add unit # if applicable).
          Verify address details in GeoWarehouse (if available). </p>`,
      },
      {
        x: `1.5%`,
        y: `26%`,
        data: `<p>Enter the type of building that the client is purchasing. 3 options:
        Mobile, Modular or Manufactured. Most commonly, these purchases occur in Mobile
        Park Communities where the Buyer also pays an annual land lease fee.</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p>Enter the Manufacturer, Model, Serial Number & Year the home was 
        built. The most common locations for this information is on the Data Plate
        which can usually be found in a kitchen cabinet, electrical panel, 
        bedroom closet, or stamped on the steel frame of the building. </p>`,
      },
      {
        x: `1.5%`,
        y: `39%`,
        data: `<p>Enter the length and width of the building (typically in feet).</p>`,
      },
      {
        x: `1.5%`,
        y: `44%`,
        data: `<p><strong>Located at:</strong> This is the address of where the 
        land, on which the building resides, is located. If the building is located
        within a Mobile Home Park, enter that information here.</p>`,
      },
      {
        x: `1.5%`,
        y: `53%`,
        data: `<p><strong>Purchase Price:</strong> This is the price the Buyer is 
        offering for the property. Ensure that the price matches with respect to 
        both the numbers and the text (example: $500,000 / Five Hundred Thousand)</p>`,
      },
      {
        x: `1.5%`,
        y: `59%`,
        data: `<p><strong>Deposit Submit Options:</strong> 3 Options to choose from 
        <ol><li><strong>upon acceptance</strong> This option means the deposit is due 
        within 24 hours of acceptance (example: if offer is accepted Friday at 10pm, 
        deposit is due Saturday at 10pm)</li><li><strong>as otherwise described in 
        this Agreement:</strong> Used when the Buyer may not be able to make their 
        deposit within the 24 hour timeframe (ie. weekend delivery). Details of when 
        the deposit is due should be clearly explained in Schedule A. Could be used 
        for situations involving multiple deposits and when they are due.</li>
        <li><strong>Herewith:</strong> This is rarely used nowadays but when this 
        is selected the Buyer is providing the deposit with the offer.</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `63%`,
        data: `<p><strong>Deposit Amount:</strong> This is the amount of deposit the 
        Buyer will be submitting according to the terms above (upon acceptance, as 
        otherwise described...). Ensure both numbers and text match. <br/><strong>
        Deposit Holder:</strong> Insert where the deposit will be held. This is 
        typically the listing brokerage in most circumstances. Sometimes it can be 
        the clients' lawyer, if requested. </p>`,
      },
      {
        x: `1.5%`,
        y: `77%`,
        data: `<p><strong>Schedules:</strong> As per the statement, Schedule A is 
        already to be included with this agreement. This type of agreement also should
        contain Schedule B as noted in the pre-printed Schedules Statement. Schedule B
        is the Lease for the property, which typically requires approval from the 
        landlord (included as a pre-printed clause - #9 on page 3). Your client will
        want to review the lease to ensure they agree to the terms of the lease before
        purchasing. Download these schedules or obtain from the listing agent and add 
        them to the agreement. Insert any additional Schedule letters here, if they
        exist.</p>`,
      },
      {
        x: `1.5%`,
        y: `82%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Buyer or 
        Seller, presenting the offer is bound by it until the specified time. 
        Moreover, if the Seller intends to accept or counter the offer, they should 
        do so before the stipulated time. You must communicate
        with the other party/agent that an action has been taken before this time 
        expires. 
        After this period elapses without acceptance, the offer becomes null 
        and void.</p>`,
      },

      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1102,
    form: `110`,
    page: `2`,
    imageURL: `../../forms/110/form110-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Completion Date:</strong> This is the proposed date of 
        closing (the date your clients would like to take ownership of the home). 
        Ensure that the date placed here is not a weekend or holiday. </p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p>Insert your email here (the side you represent) for the delivery 
        of notices (Notice of Fulfillment, Waivers...etc.).<br/><br/><strong>Important:
        </strong> If your deal involves Multiple Representation, the agent cannot 
        accept notices on behalf of the clients or acknowledge their receipt. As a 
        result, leave this area empty or insert your clients' or lawyers emails 
        for the delivery of notices, as they will have to acknowledge receipt of those
        documents.</p>`,
      },
      {
        x: `1.5%`,
        y: `36%`,

        data: `<p><strong>Chattels:</strong> These are items that are to be included
        in the purchase price and typically involve items not fixed to the property. 
        Chattels are movable items such as: appliances, mirrors
        not fixed to the walls...etc. With mobile purchases, there may be quite a few
        chattels to include.  Ensure all items that your client wishes to include with 
        the purchase are listed here. It is important to be as detailed as possible
        when describing the chattels to be included. Some items such as fixed tv mounts
        have been greatly debated as to whether they need to be included in chattels. 
        As a rule of thumb, "if in doubt, include it" so that it is clear to all parties.</p>`,
      },
      {
        x: `1.5%`,
        y: `57%`,

        data: `<p><strong>Fixtures Excluded:</strong> These are items that are
        attached to the home but the Seller wishes to exclude them from the sale. 
        One reason may be that the item listed has sentimental value like a lighting 
        fixture. It is important to be as detailed as possible  when listing excluded
        fixtures (example: "bedroom light fixture").</p>`,
      },
      {
        x: `1.5%`,
        y: `74%`,

        data: `<p><strong>Rental Items:</strong> These are items that are not owned
        by the Seller but instead leased/rented. If there are any items in the listing
        that are rented, be sure to include them in this section.  Otherwise, you may
        end up having to cover the cost to buyout that item on closing. In most 
        circumstances, the water heater is listed here as it is very commonly rented. 
        If you are representing the buyer and there are no rental items, insert "None".
    </p>`,
      },

      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1103,
    form: `110`,
    page: `3`,
    imageURL: `../../forms/110/form110-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>HST:</strong> This agreement contains a standard HST statement
        that if the property is subject to HST then it will be in addition to. Typically
        resale homes are not subject to HST, but as per the statement, you would want to 
        include a clause where the Seller certifies that the sale is not subject to HST
        if that is the understood circumstance.</p>`,
      },
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p>These 2 pre-printed clauses are especially important to be aware of
        with respect to these types of purchases.<ol><li><strong>Rules and Regulations:</strong> 
        As with most Land lease purchases, the Buyer must agree to abide by the Rules
        and Regulations for the occupancy of the Land. This information would be included
        with the lease.</li> <li><strong>Lease:</strong> If the Lease requires consent by the Landlord (most
        do require) and the Buyer is not approved, the agreement will be null and void.
        </li></ol></p>`,
      },

      {
        x: `1.5%`,
        y: `34%`,
        data: `<p><strong>Requisition Date:</strong> This marks the deadline by 
        which the lawyer should finalize tasks like conducting a title search, 
        searching for any pending work orders, etc., to ensure the title's absence 
        of encumbrances. Usually, this date is set approximately two weeks prior 
        to the closing date. However, for shorter closing periods, it may be nearer 
        to the closing date.</p>`,
      },

      {
        x: `1.5%`,
        y: `40%`,
        data: `<p><strong>Present Use:</strong> This is the intended use of the 
        property which usually aligns with its current use (example: Single Family
        Residential). This should also be verified with the landlord/mobile park to 
        ensure the intended use complies with the Buyers intentions, if they are not 
        the same.</p>`,
      },

      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1104,
    form: `110`,
    page: `4`,
    imageURL: `../../forms/110/form110-4.png`,
    hotspots: [
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1105,
    form: `110`,
    page: `5`,
    imageURL: `../../forms/110/form110-5.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Successor and Assigns:</strong> Clients signify their 
        agreement to the offer terms by signing at this location. The offer may 
        undergo multiple rounds of back-and-forth signing (signbacks).</p>`,
      },
      {
        x: `1.5%`,
        y: `30%`,
        data: `<p><strong>Spousal Consent:</strong> In the case where this property 
        is deemed the matrimonial home and the spouse, although not listed on the 
        title, is necessary to grant consent for the property's sale, please have 
        the spouse sign at this location.</p>`,
      },
      {
        x: `1.5%`,
        y: `36%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Seller initiates a signback and the Buyer 
          agrees to the terms, the Buyer signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },
      {
        x: `1.5%`,
        y: `46%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },

      {
        x: `1.5%`,
        y: `61%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign, after an accepted agreement, to confirm they received a copy of
        the signed agreement. It is important that you ensure your clients receive a 
        fully signed copy of the agreement, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage after the agreement is 
        signed and when your clients choose who they will use to facilitate the 
        closing of the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `89%`,
        data: `<p><strong>Commission Trust Agreement</strong> If there is more than
        one brokerage involved in the transaction, the agents representing their 
        respective side should sign here. If only one brokerage involved, there is
        no need to sign as a commission trust agreement does not need to be 
        created.</p>`,
      },
    ],
  },
  {
    id: 1106,
    form: `110`,
    page: `6`,
    imageURL: `../../forms/110/form110-6.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `17%`,
        data: `<p>Insert Buyers/Sellers names here, same as indicated on page 1 of 
        this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p>Add all applicable conditions and clauses pertaining to the 
        Agreement in this section. The OREA Clause Booklet, available on the OREA 
        Website, contains most of the clauses commonly used. For any customized 
        clauses, it's essential to seek legal advice to ensure they are drafted 
        correctly and protect your clients' best interests.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1451,
    form: `145`,
    page: `1`,
    imageURL: "../../forms/145/form145-1.png",
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p>This date relates to the date the offer is created and is used as 
        reference for the date on other documents you may include or use later such as: 
        <br/>Amendments, Notice of Fullfilments/Waivers, Mutual Releases...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Enter the full legal names of all Assignees and Assignors. 
         Double check your clients IDs to ensure accuracy. </li>
        </p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p><ol><li>Enter the full address of the property.  Verify address 
        details in GeoWarehouse (if available) or Original Purchase Contract</li><li>Frontage and depth length (lot dimensions)
        typically found in GeoWarehouse or in Original Purchase Contract</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `36%`,
        data: `<p><strong>Legal Description:</strong> Verify the legal description in 
        GeoWarehouse (if available), do not copy from listing</p>`,
      },
      {
        x: `1.5%`,
        y: `42%`,
        data: `<p><strong>Purchase Price:</strong> This is the price the Assignee is 
        offering for the property. Ensure that the price matches with respect to 
        both the numbers and the text (example: $500,000 / Five Hundred Thousand)</p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,
        data: `<p><strong>Deposit Submit Options:</strong> 3 Options to choose from 
        <ol><li><strong>upon acceptance</strong> This option means the deposit is due 
        within 24 hours of acceptance (example: if offer is accepted Friday at 10pm, 
        deposit is due Saturday at 10pm)</li><li><strong>as otherwise described in 
        this Agreement:</strong> Used when the Assignee may not be able to make their 
        deposit within the 24 hour timeframe (ie. weekend delivery). Details of when 
        the deposit is due should be clearly explained in Schedule A. Could be used 
        for situations involving multiple deposits and when they are due.</li>
        <li><strong>Herewith:</strong> This is rarely used nowadays but when this 
        is selected the Assignee is providing the deposit with the offer.</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `52%`,
        data: `<p><strong>Deposit Amount:</strong> This is the amount of deposit the 
        Assignee will be submitting according to the terms above (upon acceptance, as 
        otherwise described...). Ensure both numbers and text match. <br/><strong>
        Deposit Holder:</strong> Insert where the deposit will be held. This is 
        typically the listing brokerage in most circumstances. Sometimes it can be 
        the clients' lawyer, if requested. </p>`,
      },
      {
        x: `1.5%`,
        y: `75%`,
        data: `<p><strong>Schedules:</strong> As per the statement, Schedule A, B and C 
        are already to be included with this agreement. Add additional schedules if 
        applicable. Many times, listings have additional schedules uploaded to the 
        listing that are to be sent with the offer. Download these schedules, add 
        them to the agreement and insert the letter/number of that schedule here.
        <ol><li><strong>Schedule B:</strong> This schedule is included for the 
        calculation of funds owed for this assignment agreement</li><li><strong>
        Schedule C:</strong> This is the Original Purchase Contract that is being assigned
        and must be included with this agreement. Obtain this from the Assignor (original
          purchaser of the property)</li> </ol></p>`,
      },
      {
        x: `1.5%`,
        y: `81%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Assignee or 
        Assignor, presenting the offer is bound by it until the specified time. 
        Moreover, if the Assignor intends to accept or counter the offer, they should 
        do so before the stipulated time. You must communicate
        with the other party/agent that an action has been taken before this time 
        expires. 
        After this period elapses without acceptance, the offer becomes null 
        and void.</p>`,
      },
      {
        x: `1.5%`,
        y: `88%`,
        data: `<p><strong>Completion Date:</strong> Notice that there is no completion
        date on this agreement. This is because the completion date will depend on the 
        details of the Original Purchase Contract (Schedule C). </p>`,
      },
      {
        x: `21%`,
        y: `88%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1452,
    form: `145`,
    page: `2`,
    imageURL: `../../forms/145/form145-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `57%`,
        data: `<p>Insert your email here (the side you represent) for the delivery 
        of notices (Notice of Fulfillment, Waivers...etc.).<br/><br/><strong>Important:
        </strong> If your deal involves Multiple Representation, the agent cannot 
        accept notices on behalf of the clients or acknowledge their receipt. As a 
        result, leave this area empty or insert your clients' or lawyers emails 
        for the delivery of notices, as they will have to acknowledge receipt of those
        documents.</p>`,
      },

      {
        x: `1.5%`,
        y: `65%`,
        data: `<p><strong>HST:</strong><ol><li><strong>included in:</strong> Means 
        that HST is included in the purchase price/exempt from the sale.</li>
        <li><strong>in addition to:</strong> Means HST is added onto the purchase
        price. (example: if you offer $500,000 the price will be $500,000 + HST).
        </li></ol> In an assignment situation, it is important for your client to
        consult a lawyer/accountant to ensure that HST is either included in or in 
        addition to. Some sales, especially pre-con, may be subject to partial HST
        based on the sale price of the assignment. Have your clients consult a 
        professional in order to protect their best interests as well as mitigate 
        your liability.</p>`,
      },
      {
        x: `21%`,
        y: `88%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1453,
    form: `145`,
    page: `3`,
    imageURL: `../../forms/145/form145-3.png`,
    hotspots: [
      {
        x: `21%`,
        y: `88%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1454,
    form: `145`,
    page: `4`,
    imageURL: `../../forms/145/form145-4.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `10%`,
        data: `<p><strong>Successor and Assigns:</strong> Clients signify their 
        agreement to the offer terms by signing at this location. The offer may 
        undergo multiple rounds of back-and-forth signing (signbacks).</p>`,
      },

      {
        x: `1.5%`,
        y: `34%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Assignor initiates a signback and the Assignee 
          agrees to the terms, the Assignee signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `62%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign, after an accepted agreement, to confirm they received a copy of
        the signed agreement. It is important that you ensure your clients receive a 
        fully signed copy of the agreement, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage after the agreement is 
        signed and when your clients choose who they will use to facilitate the 
        closing of the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `89%`,
        data: `<p><strong>Commission Trust Agreement</strong> If there is more than
        one brokerage involved in the transaction, the agents representing their 
        respective side should sign here. If only one brokerage involved, there is
        no need to sign as a commission trust agreement does not need to be 
        created.</p>`,
      },
    ],
  },
  {
    id: 1455,
    form: `145`,
    page: `5`,
    imageURL: `../../forms/145/form145-5.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Assignee/Assignor names here, same as indicated on page 1 of 
      this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p><strong>Conditions and Clauses:</strong> Add all your conditions 
        and clauses in this section. With Pre-Con assignments (the usual circumstance)
        it is common to include 3 conditions, at minimum:
        <ol><li>Assignor's solicitor review condition</li><li>Assignee's solicitor 
        review condition</li><li>Builder's consent condtion</li></ol>Builder's 
        consent is almost always a requirement for Pre-Con assignments, so it is 
        important to include.  Assignments can be very complex transactions. It
        is important to ensure your clients find a lawyer that is comfortable with 
        doing assignment deals.</p>`,
      },
      {
        x: `21%`,
        y: `88%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1456,
    form: `145`,
    page: `6`,
    imageURL: `../../forms/145/form145-6.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Assignee/Assignor names here, same as indicated on page 1 of 
        this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `31%`,
        data: `<p><strong>Schedule B Math:</strong><ol>
        <li>Total Purchase Price of the assignment as indicated 
        on Page 1 of this Agreement.</li>
        <li>Original Purchase Price of the property (as indicated
        in Schedule C - Original Purchase Contract)</li>
        <li>Amount of all the deposits the Assignor has paid up until the 
        time of this agreement (as indicated in Schedule C)</li>
        <li>This amount equals:  <strong>$500,000 - $450,000 + $25,000 = $75,000</strong><br/>In words: <strong>New 
        Price (1)</strong> minus <strong>Original Price (2)</strong> plus 
        <strong>Deposits already made by Assignor (3)</strong> </li>
        <li>Deposit to be made by the Assignee for this Agreement
        (as indicated on page 1 - Deposit)</li>
        <li>Final Amount Owing for this Agreement = <strong>$75,000 - $10,000 = $65,000</strong><br/>
        In words: <strong>Payment by Assignee to Assignor for this Agreement (4)</strong> 
        minus <strong>Deposit made by Assignee for this Agreement (5)</strong> equals 
        <strong>Balance Owing for this Assignment (6)</strong>
        <br/><br/> In the end we have: <strong>$500,000 - $450,000  + $25,000  = $75,000</strong> 
        <br/>Then: <strong>$75,000  - $10,000  = $65,000 (Total Amount Owing for this Agreement)
        </strong><br/><br/>It is important to keep in mind this amount is for this agreement. 
        In some cases, where the Assignor is selling at a loss, #6 could be a 
        negative number. The Assignee would still owe the balance of the purchase 
        price owing on the Original Purchase Contract in addition to this amount.`,
      },
      {
        x: `21%`,
        y: `88%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1501,
    form: `150`,
    page: `1`,
    imageURL: "../../forms/150/form150-1.png",
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p>This date relates to the date the offer is created and is used as 
        reference for the date on other documents you may include or use later such as: 
        <br/>Amendments, Notice of Fullfilments/Waivers, Mutual Releases...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Enter the full legal names of all Assignees and Assignors. 
         Double check your clients IDs to ensure accuracy. </li>
        </p>`,
      },
      {
        x: `1.5%`,
        y: `25%`,
        data: `<p>Enter the full municipal address of the property, including 
        the Unit Number (if applicable).  Select the type of unit that applies 
        (Apartment, Townhouse, Suite or Unit). Verify address details in 
        GeoWarehouse (if available).</p>`,
      },

      {
        x: `1.5%`,
        y: `33%`,
        data: `<p><strong>Legal Description:</strong> The information here represents
        how the unit is described in the condo declaration and description.  This 
        information can be verified in GeoWarehouse or the Original Purchase Contract.
          This is not the
        municipal address information. Sometimes only certain details apply and  
        not all fields are necessary (example: sometimes you may only have Unit, 
        Level and no Building No.). Complete all the fields with available 
        information, as well as any Parking/Locker details (if applicable).</p>`,
      },
      {
        x: `1.5%`,
        y: `50%`,
        data: `<p><strong>Purchase Price:</strong> This is the price the Assignee is 
        offering for the property. Ensure that the price matches with respect to 
        both the numbers and the text (example: $500,000 / Five Hundred Thousand)</p>`,
      },
      {
        x: `1.5%`,
        y: `55%`,
        data: `<p><strong>Deposit Submit Options:</strong> 3 Options to choose from 
        <ol><li><strong>upon acceptance</strong> This option means the deposit is due 
        within 24 hours of acceptance (example: if offer is accepted Friday at 10pm, 
        deposit is due Saturday at 10pm)</li><li><strong>as otherwise described in 
        this Agreement:</strong> Used when the Assignee may not be able to make their 
        deposit within the 24 hour timeframe (ie. weekend delivery). Details of when 
        the deposit is due should be clearly explained in Schedule A. Could be used 
        for situations involving multiple deposits and when they are due.</li>
        <li><strong>Herewith:</strong> This is rarely used nowadays but when this 
        is selected the Assignee is providing the deposit with the offer.</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `59%`,
        data: `<p><strong>Deposit Amount:</strong> This is the amount of deposit the 
        Assignee will be submitting according to the terms above (upon acceptance, as 
        otherwise described...). Ensure both numbers and text match. <br/><strong>
        Deposit Holder:</strong> Insert where the deposit will be held. This is 
        typically the listing brokerage in most circumstances. Sometimes it can be 
        the clients' lawyer, if requested. </p>`,
      },
      {
        x: `1.5%`,
        y: `84%`,
        data: `<p><strong>Schedules:</strong> As per the statement, Schedule A, B and C 
        are already to be included with this agreement. Add additional schedules if 
        applicable. Many times, listings have additional schedules uploaded to the 
        listing that are to be sent with the offer. Download these schedules, add 
        them to the agreement and insert the letter/number of that schedule here.
        <ol><li><strong>Schedule B:</strong> This schedule is included for the 
        calculation of funds owed for this assignment agreement</li><li><strong>
        Schedule C:</strong> This is the Original Purchase Contract that is being assigned
        and must be included with this agreement. Obtain this from the Assignor (original
          purchaser of the property)</li> </ol></p>`,
      },

      {
        x: `1.5%`,
        y: `88%`,
        data: `<p><strong>Completion Date:</strong> Notice that there is no completion
        date on this agreement. This is because the completion date will depend on the 
        details of the Original Purchase Contract (Schedule C). </p>`,
      },
      {
        x: `23%`,
        y: `89%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1502,
    form: `150`,
    page: `2`,
    imageURL: `../../forms/150/form150-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Assignee or 
        Assignor, presenting the offer is bound by it until the specified time. 
        Moreover, if the Assignor intends to accept or counter the offer, they should 
        do so before the stipulated time. You must communicate
        with the other party/agent that an action has been taken before this time 
        expires. 
        After this period elapses without acceptance, the offer becomes null 
        and void.</p>`,
      },
      {
        x: `1.5%`,
        y: `57%`,
        data: `<p>Insert your email here (the side you represent) for the delivery 
        of notices (Notice of Fulfillment, Waivers...etc.).<br/><br/><strong>Important:
        </strong> If your deal involves Multiple Representation, the agent cannot 
        accept notices on behalf of the clients or acknowledge their receipt. As a 
        result, leave this area empty or insert your clients' or lawyers emails 
        for the delivery of notices, as they will have to acknowledge receipt of those
        documents.</p>`,
      },

      {
        x: `1.5%`,
        y: `65%`,
        data: `<p><strong>HST:</strong><ol><li><strong>included in:</strong> Means 
        that HST is included in the purchase price/exempt from the sale.</li>
        <li><strong>in addition to:</strong> Means HST is added onto the purchase
        price. (example: if you offer $500,000 the price will be $500,000 + HST).
        </li></ol> In an assignment situation, it is important for your client to
        consult a lawyer/accountant to ensure that HST is either included in or in 
        addition to. Some sales, especially pre-con, may be subject to partial HST
        based on the sale price of the assignment. Have your clients consult a 
        professional in order to protect their best interests as well as mitigate 
        your liability.</p>`,
      },
      {
        x: `23%`,
        y: `89%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1503,
    form: `150`,
    page: `3`,
    imageURL: `../../forms/150/form150-3.png`,
    hotspots: [
      {
        x: `23%`,
        y: `89%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1504,
    form: `150`,
    page: `4`,
    imageURL: `../../forms/150/form150-4.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `10%`,
        data: `<p><strong>Successor and Assigns:</strong> Clients signify their 
        agreement to the offer terms by signing at this location. The offer may 
        undergo multiple rounds of back-and-forth signing (signbacks).</p>`,
      },

      {
        x: `1.5%`,
        y: `34%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Assignor initiates a signback and the Assignee 
          agrees to the terms, the Assignee signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `62%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign, after an accepted agreement, to confirm they received a copy of
        the signed agreement. It is important that you ensure your clients receive a 
        fully signed copy of the agreement, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage after the agreement is 
        signed and when your clients choose who they will use to facilitate the 
        closing of the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `89%`,
        data: `<p><strong>Commission Trust Agreement</strong> If there is more than
        one brokerage involved in the transaction, the agents representing their 
        respective side should sign here. If only one brokerage involved, there is
        no need to sign as a commission trust agreement does not need to be 
        created.</p>`,
      },
    ],
  },
  {
    id: 1505,
    form: `150`,
    page: `5`,
    imageURL: `../../forms/150/form150-5.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p>Insert Assignee/Assignor names here, same as indicated on page 1 of 
      this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p><strong>Conditions and Clauses:</strong> Add all your conditions 
        and clauses in this section. With Pre-Con assignments (the usual circumstance)
        it is common to include 3 conditions, at minimum:
        <ol><li>Assignor's solicitor review condition</li><li>Assignee's solicitor 
        review condition</li><li>Builder's consent condtion</li></ol>Builder's 
        consent is almost always a requirement for Pre-Con assignments, so it is 
        important to include.  Assignments can be very complex transactions. It
        is important to ensure your clients find a lawyer that is comfortable with 
        doing assignment deals.</p>`,
      },
      {
        x: `23%`,
        y: `89%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 1506,
    form: `150`,
    page: `6`,
    imageURL: `../../forms/150/form150-6.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Assignee/Assignor names here, same as indicated on page 1 of 
        this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `31%`,
        data: `<p><strong>Schedule B Math:</strong><ol>
        <li>Total Purchase Price of the assignment as indicated 
        on Page 1 of this Agreement.</li>
        <li>Original Purchase Price of the property (as indicated
        in Schedule C - Original Purchase Contract)</li>
        <li>Amount of all the deposits the Assignor has paid up until the 
        time of this agreement (as indicated in Schedule C)</li>
        <li>This amount equals:  <strong>$500,000 - $450,000 + $25,000 = $75,000</strong><br/>In words: <strong>New 
        Price (1)</strong> minus <strong>Original Price (2)</strong> plus 
        <strong>Deposits already made by Assignor (3)</strong> </li>
        <li>Deposit to be made by the Assignee for this Agreement
        (as indicated on page 1 - Deposit)</li>
        <li>Final Amount Owing for this Agreement = <strong>$75,000 - $10,000 = $65,000</strong><br/>
        In words: <strong>Payment by Assignee to Assignor for this Agreement (4)</strong> 
        minus <strong>Deposit made by Assignee for this Agreement (5)</strong> equals 
        <strong>Balance Owing for this Assignment (6)</strong>
        <br/><br/> In the end we have: <strong>$500,000 - $450,000  + $25,000  = $75,000</strong> 
        <br/>Then: <strong>$75,000  - $10,000  = $65,000 (Total Amount Owing for this Agreement)
        </strong><br/><br/>It is important to keep in mind this amount is for this agreement. 
        In some cases, where the Assignor is selling at a loss, #6 could be a 
        negative number. The Assignee would still owe the balance of the purchase 
        price owing on the Original Purchase Contract in addition to this amount.`,
      },
      {
        x: `23%`,
        y: `89%`,
        data: `<p>As with most other pages within the APS, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 3711,
    form: `371`,
    page: `1`,
    imageURL: `../../forms/371/form371-1.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `17%`,
        data: `<p>Insert Brokerage information here: Name, Address, City...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `22%`,
        data: `<p>Insert the full legal names of all Buyer clients and their address
        information. Double check your clients IDs to ensure accuracy. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `30%`,
        data: `<p>Insert the Designated Representative(s). The names listed here
        will be the agents authorized to provide services to the clients named above. 
        If you are on a team it is important to note that Team Names are not 
        acceptable as designated representatives. Each agent that will be providing 
        services to the client must be listed. Alternatively, you can also have 1 designated 
        representative here and a "See Schedule A" statement which may include 
        additional designated representatives. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `37%`,
        data: `<p>This is the time and date that representation with the client 
        commences and expires. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `42%`,
        data: `<p>This is a mandatory bubble the client must initial to acknowledge
        that the client understands that the time length of representation is a 
        negotiable element of the agreement. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `48%`,
        data: `<p><strong>Property Type:</strong> List the property types or general type (example: single family
          residential) of property that the client is looking for.<br/>
          <br/><strong>Geographic Location:</strong> Insert the geographic area(s) that 
          your client wants for their property search.  Do not list too broad of an 
          area (example: "Ontario") as this will cause potential issues if a contract
          dispute arises.  Use locations in a similar style to the example shown.
          
        </p>`,
      },
      {
        x: `1.5%`,
        y: `56%`,
        data: `<p> Have your clients initial here to warrant that they are not party
        to another representation agreement.  It is important to have this discussion
        with your clients to ensure they are aware of the repercussions associated
        with already being under contract with another agent. Do your due diligence
        and ask questions like: "Are you working with another REALTOR?" "Have you been out
        and seen any properties?" If so, "Have you made any offers on any properties in 
        the last X months?" By asking these questions, you may help avoid situations
        where a Buyer might have signed a representation agreement and not been fully 
        aware of it.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `60%`,
        data: `<p>As per TRESA, all agreements should be accompanied by a Schedule
        that outlines the services to be provided to the client.  Since Schedule A is
        included with this agreement, only add additional Schedule Letters here if 
        they exist in addition to the pre-printed A.</p>`,
      },
      {
        x: `1.5%`,
        y: `83%`,
        data: `<p>Insert the amount of commission that you typically charge for 
        providing services to your Buyer clients. This amount can be a percentage, a
        specific dollar value, or a combination of both. In addition to the amount, you 
        can insert other statements,  similar to those in the example, that clarify the
        amount you may receive in a given scenario. Even though in the majority of 
        circumstances the commission comes from the Listing Side of the transaction,
        it is important to have the conversation with your clients that there may be
        scenarios (example: for sale by owner properties or low commission listings)
         in which your Buyer client may have to pay commission.  Having these 
         discussions up front, and when the situations arise, will likely lead to a 
         much better buying experience for your clients. 
        </p>`,
      },
      {
        x: `23%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Buyers" bubble.
         The agent can initial in the "Initials of Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 3712,
    form: `371`,
    page: `2`,
    imageURL: `../../forms/371/form371-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `9%`,
        data: `<p>Insert the number of holdover days that apply to this agreement. Read
        the printed statement associated with this holdover period to see how it applies.
        In short, if this agreement expires and the Buyer purchases/leases a property that 
        you introduced them to within this holdover period (example: 90 days), they 
        agree to pay the commission as per this agreement. However, if they enter into 
        a new buyers representation agreement with another Brokerage, the commission
        amount is reduced by the amount on their new agreement.</p>`,
      },
      {
        x: `23%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Buyers" bubble.
         The agent can initial in the "Initials of Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 3713,
    form: `371`,
    page: `3`,
    imageURL: `../../forms/371/form371-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `46%`,
        data: `<p>You sign here, add the date and insert your name.</p>`,
      },
      {
        x: `1.5%`,
        y: `59%`,
        data: `<p>Have your buyer clients sign here and insert date. Adding their
        telephone is not mandatory but advised in case the Brokerage needs to 
        contact the Buyers.</p>`,
      },
      {
        x: `1.5%`,
        y: `68%`,
        data: `<p><strong>Declaration of Insurance:</strong> Insert your name along
        with signature below. In this section you are declaring that you are insured
        as an agent (TRESA requirement).</p>`,
      },
      {
        x: `1.5%`,
        y: `83%`,
        data: `<p><strong>Acknowledgement:</strong> Have your clients sign here to 
        acknowledge that you have provided them with a fully signed copy of this 
        agreement. As per our Code of Ethics, all clients must be provided with a 
        copy of the fully signed agreement.</p>`,
      },
    ],
  },
  {
    id: 3714,
    form: `371`,
    page: `4`,
    imageURL: `../../forms/371/form371-4.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p><strong>Brokerage:</strong> Insert your Brokerage name.<br/><br/>
        <strong>Buyer:</strong> Insert your Buyer clients names</p>`,
      },

      {
        x: `1.5%`,
        y: `26.5%`,
        data: `<p>As per TRESA, all agreements should include a list of services
        to be provided to the client. Add those services here. Keep in mind, this
         will likely be an ever changing Schedule.  This is your opportunity to 
         showcase all of the unique services that you will provide to your clients. 
         Follow the "don't overpromise and under-deliver" principle so that you do 
         not create a situation in which you have breached the agreement.</p>`,
      },
      {
        x: `23%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Buyers" bubble.
         The agent can initial in the "Initials of Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 4001,
    form: `400`,
    page: `1`,
    imageURL: `../../forms/400/form400-1.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p>This date relates to the date the offer is created and is used as 
        reference for the date on other documents you may include or use later such as: 
        <br/>Amendments, Notice of Fullfilments/Waivers, Mutual Releases...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `17%`,
        data: `<p>Enter the full legal names of all Tenants & Landlords. 
        <ol> <li>Double check your clients IDs to ensure accuracy. </li>
        <li> Verify the Landlord names in GeoWarehouse, do not just copy from the 
        listing as the names may not be accurate. </li>
        <li> If available, enter the Landlords address here (not the property being 
          leased). Otherwise, leave blank.
        </li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p><strong>Premises:</strong> Enter the full address of the property to be leased.  
        If applicable, include the Unit #. Verify address details in GeoWarehouse.<br/><br/>
        <strong>Term of Lease:</strong> Enter the term length of the lease. This is typically 1 year but can
        be shorter/longer.  If a lease is signed for longer than 1 year, it does not
        prevent the landlord from being able to raise the rent as per the Acts rent 
        increase guidelines.
        </p>`,
      },

      {
        x: `1.5%`,
        y: `35%`,
        data: `<p><strong>Rent:</strong> This is the amount of rent the Tenant agrees to pay the Landlord on a 
        monthly basis. Indicate when the rent is due, typically on the 1st of every month. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `42%`,
        data: `<p><strong>Deposit Submit Options:</strong> 3 Options to choose from 
        <ol><li><strong>upon acceptance</strong> This option means the deposit is due 
        within 24 hours of acceptance (example: if offer is accepted Friday at 10pm, 
        deposit is due Saturday at 10pm)</li><li><strong>as otherwise described in 
        this Agreement:</strong> Used when the Tenant may not be able to make their 
        deposit within the 24 hour timeframe (ie. weekend delivery). Details of when 
        the deposit is due should be clearly explained in Schedule A. Could be used 
        for situations involving multiple deposits/pre-paid rent and when they are due.</li>
        <li><strong>Herewith:</strong> This is rarely used nowadays but when this 
        is selected the Tenant is providing the deposit with the offer.</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,
        data: `<p><strong>Deposit Amount:</strong> This is the amount of deposit the 
        Tenant will be submitting according to the terms above (upon acceptance, as 
        otherwise described...). Ensure both numbers and text match. <br/><strong>
        Deposit Holder:</strong> Insert where the deposit will be held. This is 
        typically the listing brokerage in most circumstances. Sometimes it can be 
        the directly to the landlord but this is not advised, as it may be difficult
        to collect commission after the deal has closed. </p>`,
      },
      {
        x: `1.5%`,
        y: `51%`,
        data: `<p>Amount of deposit to be applied for the specified purpose, typically 
        first/last month.  If the Tenant has volunteered pre-paid rent and as a
         result the deposit is larger than first/last months rent, choose
        "as otherwise described in this agreement" for the deposit and indicate the
        amounts going to their respective party.  The brokerage does not want to hold
        more than first/last months rent.  You can specify, in Schedule A, the 
        additional amounts to go to the Landlord, if agreeable by Tenant 
        (post dated to closing date). </p>`,
      },
      {
        x: `1.5%`,
        y: `65%`,
        data: `<p>Specify the use of the property during the rental period.  This is
        typically "Residential Use."
        </p>`,
      },
      {
        x: `1.5%`,
        y: `75%`,
        data: `<p>Use the checkmarks to indicate which party is responsible for paying 
        the associated services.  If the pre-printed item does not apply, 
        you can leave the checkbox empty or strike it out. There are additional spots 
        available to add items, if needed.
        </p>`,
      },
      {
        x: `23%`,
        y: `89%`,
        data: `<p>As with most other pages within the ATL, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 4002,
    form: `400`,
    page: `2`,
    imageURL: `../../forms/400/form400-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Parking:</strong> Indicate whether or not parking is included. Be as specific as
        possible. If a specific spot is assigned, notate as such.  If not, add 
        its general location (above ground or below ground) and how many spots are
        available for the tenant.<br/><br/>
        <strong>Additional Terms:</strong> If there are any additional terms to be 
        added, insert here.</p>`,
      },

      {
        x: `1.5%`,
        y: `14%`,
        data: `<p>Add any additional Schedules included with this agreement. Schedule
        A is already included as per the pre-printed statement.</p>`,
      },
      {
        x: `1.5%`,
        y: `19%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Tenant or 
        Landlord, presenting the offer is bound by it until the specified time. 
        Moreover, if the Landlord intends to accept or counter the offer, they should 
        do so before the stipulated time. You must communicate
        with the other party/agent that an action has been taken before this time 
        expires. 
        After this period elapses without acceptance, the offer becomes null 
        and void.</p>`,
      },
      {
        x: `1.5%`,
        y: `39%`,
        data: `<p>Insert your email here (the side you represent) for the delivery 
        of notices (Notice of Fulfillment, Waivers...etc.).<br/><br/><strong>Important:
        </strong> If your deal involves Multiple Representation, the agent cannot 
        accept notices on behalf of the clients or acknowledge their receipt. As a 
        result, leave this area empty or insert your clients' emails for the delivery
         of notices, as they will have to acknowledge receipt of those documents.</p>`,
      },
      {
        x: `23%`,
        y: `89%`,
        data: `<p>As with most other pages within the ATL, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 4003,
    form: `400`,
    page: `3`,
    imageURL: `../../forms/400/form400-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p><strong>Successor and Assigns:</strong> Clients signify their 
        agreement to the offer terms by signing at this location. The offer may 
        undergo multiple rounds of back-and-forth signing (signbacks).</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p><strong>Spousal Consent:</strong> In the case where this property 
        is deemed the matrimonial home and the spouse, although not listed on the 
        title, is necessary to grant consent for the property's sale, please have 
        the spouse sign at this location.</p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Landlord initiates a signback and the Tenant 
          agrees to the terms, the Tenant signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },
      {
        x: `1.5%`,
        y: `50%`,
        data: `<p>Insert the Listing & Selling Brokerages as well as the agents 
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `63%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign, after an accepted agreement, to confirm they received a copy of
        the signed agreement. It is important that you ensure your clients receive a 
        fully signed copy of the agreement, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage after the agreement is 
        signed and when your clients choose who they will use to facilitate the 
        closing of the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `89%`,
        data: `<p><strong>Commission Trust Agreement</strong> If there is more than
        one brokerage involved in the transaction, the agents representing their 
        respective side should sign here. If only one brokerage involved, there is
        no need to sign as a commission trust agreement does not need to be 
        created.</p>`,
      },
    ],
  },
  {
    id: 4004,
    form: `400`,
    page: `4`,
    imageURL: `../../forms/400/form400-4.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Buyers/Sellers names here, same as indicated on page 1 of 
        this agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Input the property details here, matching those listed on 
        page 1 of this Agreement. The date of this Schedule (and any additional 
          Schedules) corresponds to the date stated at the top of page 1 of this 
          Agreement. Even if Schedules are added later, the date should remain 
          consistent with the date at the top of page 1 of this Agreement, rather 
          than the date on which the Schedule was appended.</p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p>Add all applicable conditions and clauses pertaining to the 
        Agreement in this section. The OREA Clause Booklet, available on the OREA 
        Website, contains most of the clauses commonly used. For any customized 
        clauses, it's essential to seek legal advice to ensure they are drafted 
        correctly and protect your clients' interests. Do not use any clauses that 
        contradict the Residential Tenancies Act
         as they will be unenforceable once the Tenant takes possession. If there is
         a conflict, The RTA 
         supercedes any clause noted here.</p>`,
      },
      {
        x: `25%`,
        y: `88.5%`,
        data: `<p>As with most other pages within the ATL, have ALL of your clients 
        initial in the bubble that applies.</p>`,
      },
    ],
  },
  {
    id: 3721,
    form: `372`,
    page: `1`,
    imageURL: `../../forms/372/form372-1.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p>Insert Brokerage information here: Name, Address, City...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `22.5%`,
        data: `<p>Insert the full legal names of all Tenant clients and their address
        information. Double check your clients IDs to ensure accuracy. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `28%`,
        data: `<p>Insert the Designated Representative(s). The names listed here
        will be the agents authorized to provide services to the clients named above. 
        If you are on a team it is important to note that Team Names are not 
        acceptable as designated representatives. Each agent that will be providing 
        services to the client must be listed. Alternatively, you can also have 1 designated 
        representative here and a "See Schedule A" statement which may include 
        additional designated representatives. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `36%`,
        data: `<p>This is the time and date that representation with the client 
        commences and expires. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `41%`,
        data: `<p>This is a mandatory bubble the client must initial to acknowledge
        that the client understands that the time length of representation is a 
        negotiable element of the agreement. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `48%`,
        data: `<p><strong>Property Type:</strong> List the property types or general type (example: single family
          residential) of property that the client is looking for.<br/><br/>
          <strong>Geographic Location:</strong> Insert the geographic area(s) that 
          your client wants for their property search.  Do not list too broad of an 
          area (example: "Ontario") as this will cause potential issues if a contract
          dispute arises.  Use locations in a similar style to the example shown.
          
        </p>`,
      },
      {
        x: `1.5%`,
        y: `56%`,
        data: `<p> Have your clients initial here to warrant that they are not party
        to another representation agreement.  It is important to have this discussion
        with your clients to ensure they are aware of the repercussions associated
        with already being under contract with another agent. Do your due diligence
        and ask questions like: "Are you working with another REALTOR?" "Have you been out
        and seen any properties?" If so, "Have you made any offers on any properties in 
        the last X months?" By asking these questions, you may help avoid situations
        where a Tenant might have signed a representation agreement and not been fully 
        aware of it.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `60%`,
        data: `<p>As per TRESA, all agreements should be accompanied by a Schedule
        that outlines the services to be provided to the client.  Since Schedule A is
        included with this agreement, only add additional Schedule Letters here if 
        they exist in addition to the pre-printed A.</p>`,
      },
      {
        x: `23%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Tenants" bubble.
         The agent can initial in the "Initials of Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 3722,
    form: `372`,
    page: `2`,
    imageURL: `../../forms/372/form372-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `6%`,
        data: `<p>Insert the amount of commission that you typically charge for 
        providing services to your Tenant clients. This amount can be a percentage, a
        specific dollar value, or a combination of both. In addition to the amount, you 
        can insert other statements,  similar to those in the example, that clarify the
        amount you may receive in a given scenario. Even though in the majority of 
        circumstances the commission comes from the Listing Side of the transaction,
        it is important to have the conversation with your clients that there may be
        scenarios (example: low commission listings)
         in which your Tenant client may have to pay commission.  Having these 
         discussions up front, and when the situations arise, will likely lead to a 
         much better buying experience for your clients. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `17%`,
        data: `<p>Insert the number of holdover days that apply to this agreement. Read
        the printed statement associated with this holdover period to see how it applies.
        In short, if this agreement expires and the Tenant purchases/leases a property that 
        you introduced them to within this holdover period (example: 90 days), they 
        agree to pay the commission as per this agreement. However, if they enter into 
        a new tenant representation agreement with another Brokerage, the commission
        amount is reduced by the amount on their new agreement.</p>`,
      },
      {
        x: `23%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Tenants" bubble.
         The agent can initial in the "Initials of Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 3723,
    form: `372`,
    page: `3`,
    imageURL: `../../forms/372/form372-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `41%`,
        data: `<p>You sign here, add the date and insert your name.</p>`,
      },
      {
        x: `1.5%`,
        y: `52%`,
        data: `<p>Have your tenant clients sign here and insert date. Adding their
        telephone is not mandatory but advised in case the Brokerage needs to 
        contact the Tenant.</p>`,
      },
      {
        x: `1.5%`,
        y: `60%`,
        data: `<p><strong>Declaration of Insurance:</strong> Insert your name along
        with signature below. In this section you are declaring that you are insured
        as an agent (TRESA requirement).</p>`,
      },
      {
        x: `1.5%`,
        y: `77%`,
        data: `<p><strong>Acknowledgement:</strong> Have your clients sign here to 
        acknowledge that you have provided them with a fully signed copy of this 
        agreement. As per our Code of Ethics, all clients must be provided with a 
        copy of the fully signed agreement.</p>`,
      },
    ],
  },
  {
    id: 3724,
    form: `372`,
    page: `4`,
    imageURL: `../../forms/372/form372-4.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p><strong>Brokerage:</strong> Insert your Brokerage name.<br/><br/>
        <strong>Tenant:</strong> Insert your Tenant clients names</p>`,
      },

      {
        x: `1.5%`,
        y: `26.5%`,
        data: `<p>As per TRESA, all agreements should include a list of services
        to be provided to the client. Add those services here. Keep in mind, this
         will likely be an ever changing Schedule.  This is your opportunity to 
         showcase all of the unique services that you will provide to your clients. 
         Follow the "don't overpromise and under-deliver" principle so that you do 
         not create a situation in which you have breached the agreement.</p>`,
      },
      {
        x: `23%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Tenants" bubble.
         The agent can initial in the "Initials of Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 1201,
    form: `120`,
    page: `1`,
    imageURL: `../../forms/120/form120-1.png`,
    description: `<p>Any changes to accepted agreements should be done by way of an 
    amendment. If using an amendment to extend a condition, please note that the 
    amendment must be accepted and fully signed before the expiry of the condition.<br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 113: Amendment to - Option to Purchase Agreement<br/>
    FORM 143: Amendment to - Assignment of Agreement of Purchase and Sale<br/>
    FORM 420: Amendment to - Agreement to Lease - Residential</p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p>Insert Buyers and Sellers names, as shown on the APS.</p>`,
      },
      {
        x: `1.5%`,
        y: `20%`,
        data: `<p><strong>Date:</strong> Insert the date, found at the top of page 1
        of the APS, that this amendment refers to. By matching this date to the date
        on the APS we are able to identify which APS is being amended. </p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p><strong>Address:</strong> Insert the address of the property that is
        being sold. </p>`,
      },
      {
        x: `1.5%`,
        y: `35%`,
        data: `<p><strong>Insert/Delete:</strong> Start this form by deleting the items
        in the APS which are being amended (example:  Purchase Price: $500,000). Then 
        Insert the item that is being amended (example: Purchase Price: $475,000). See
        the example below. Also, if you are adjusting something like a condition, you 
        cannot just "Insert" the new condtion.  The condtion already in the APS would 
        need to be deleted in order to avoid having 2 of the same conditions in the
        offer.<br/>
        <br/><strong>DELETE:</strong><br/>Purchase Price: $500,000<br/>
        <br/><strong>INSERT:</strong><br/>Purchase Price: $475,000<br/></p>`,
      },
      {
        x: `23%`,
        y: `88.5%`,
        data: `<p>You sign here, add the date and insert your name.</p>`,
      },
    ],
  },
  {
    id: 1202,
    form: `120`,
    page: `2`,
    imageURL: `../../forms/120/form120-2.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `5%`,
        data: `<p><strong>Irrevocability:</strong> The party, whether Buyer or 
        Seller, sending the amendment is bound by it until the specified time. Additionally, if the Seller is to accept or counter the 
        amendment, it must be done before the time stated here. You must communicate 
        with the other party/agent that an action has been taken before this time 
        expires. Once this time has passed, and the amendment has not been accepted, 
        the amendment is considered null and void. It is important to note that an
        amendment does not extend the expiry of a condtion. As a result, the 
        irrevocability time should be before the condtion(s) expire.</p>`,
      },
      {
        x: `1.5%`,
        y: `24%`,
        data: `<p> Clients sign in this section agreeing 
        to the terms of the amendment. The amendment may go back and forth in multiple 
        signbacks. The Confirmation of Acceptance is to be signed by the opposite 
        party from which the offer/signback came from (example: if the Seller does 
        a signback and the Buyer agrees to the terms, the Buyer signs the confirmation
        of acceptance). <br/><br/>2 things must happen in order for the amendment to be 
        considered accepted: <ol><li>The confirmation of acceptance must be signed 
        before the expiry of the irrevocable period.</li><li>You must communicate to 
        the other side, before the expiry of the irrevocable period, that the amendment
         has been accepted</li></ol></p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,
        data: `<p><strong>Spousal Consent:</strong> If spousal consent is required
        in order to accept the amendment, have the spouse sign here.</p>`,
      },
      {
        x: `1.5%`,
        y: `52%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> The Confirmation of 
        Acceptance should be signed by the party opposite to the one initiating 
        the offer/signback (e.g., if the Seller initiates a signback and the Buyer 
          agrees to the terms, the Buyer signs the confirmation of acceptance). 
          Two conditions must be met for the offer to be considered accepted: 
         <ol><li>The confirmation of acceptance should be signed before the expiration 
          of the irrevocable period.</li><li>You must inform the other party, before the 
          irrevocable period ends, that the offer has been accepted.</li></ol>
           Note the time/date at which
        acceptance occurred. Only one of the parties accepting the offer needs to sign, 
        but multiple can. The time/date noted here, starts the timer for the conditions (if
        present in the offer).</p>`,
      },

      {
        x: `1.5%`,
        y: `73%`,
        data: `<p><strong>Acknowledgement:</strong> In this section, your clients
        should sign after the amendment is accepted, to confirm they received a copy 
        of the signed amendment. 
        It is important that you ensure your clients receive a 
        fully signed copy, as per the Code of Ethics. You can also 
        insert the lawyers name/information but it is not required.  The lawyer 
        information can always be provided to the brokerage afterwards.</p>`,
      },
    ],
  },
  {
    id: 1231,
    form: `123`,
    page: `1`,
    imageURL: `../../forms/123/form123-1.png`,
    description: `<p>In addition to amendments & notice of fulfillments, this form can be utilized to 
    deal 
    with notifying the opposite party that the conditions are being waived. 
    Time is of the essence: the appropriate party should acknowledge before the 
    expiry of the condition.<br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 133: Waiver - Option to Purchase Agreement<br/>
    FORM 153: Waiver - Assignment of Agreement of Purchase and Sale<br/>
    FORM 403: Waiver - Agreement to Lease - Residential</p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p>Insert Buyers and Sellers names, as shown on the APS.</p>`,
      },

      {
        x: `1.5%`,
        y: `20%`,
        data: `<p><strong>Address:</strong> Insert the address of the property that is
        being sold. </p>`,
      },
      {
        x: `1.5%`,
        y: `25%`,
        data: `<p><strong>Date:</strong> Insert the date, found at the top of page 1
        of the APS, that this waiver refers to. By matching this date to the date
        on the APS, we are able to identify which APS if being affected by this
        waiver. </p>`,
      },
      {
        x: `1.5%`,
        y: `31%`,
        data: `<p>Insert the conditions that are being waived from the APS. If
        an amendment was used to change the condtion at any point during the 
        conditional period, use the most recent version of the condition
        </p>`,
      },
      {
        x: `1.5%`,
        y: `69%`,
        data: `<p>Insert the location, time and date of when these conditions are 
        being waived. Then have your clients sign below.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `86%`,
        data: `<p><strong>Acknowledgement Receipt:</strong> This is where the opposite
        party inserts date/time of when the waiver was received. Additionally, their
        name and signature are inserted to finalize the acknowledgement.  Similar to
        the confirmation of acceptance on an APS, it is important that you not only
        have your clients sign the waiver before the expiry of the condition, but 
        also that the signed waiver is communicated to the other side before the 
        expiry of the condition.
        </p>`,
      },
    ],
  },
  {
    id: 1241,
    form: `124`,
    page: `1`,
    imageURL: `../../forms/124/form124-1.png`,
    description: `<p>In addition to amendments & waivers, this form can be utilized to 
    deal 
    with notifying the opposite party that the conditions are considered fulfilled. 
    Time is of the essence: the appropriate party should acknowledge before the 
    expiry of the condition.<br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 134: Notice of Fulfillment of Condition(s) - Option to Purchase Agreement<br/>
    FORM 154: Notice of Fulfillment of Condition(s) - Assignment of Agreement of Purchase and Sale<br/>
    FORM 404: Notice of Fulfillment of Condition(s) - Agreement to Lease - Residential</p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p>Insert Buyers and Sellers names, as shown on the APS.</p>`,
      },

      {
        x: `1.5%`,
        y: `20%`,
        data: `<p><strong>Address:</strong> Insert the address of the property that is
        being sold. </p>`,
      },
      {
        x: `1.5%`,
        y: `25%`,
        data: `<p><strong>Date:</strong> Insert the date, found at the top of page 1
        of the APS, that this notice of fulfillment refers to. By matching this date 
        to the date on the APS, we are able to identify which APS if being affected 
        by this notice.
         </p>`,
      },
      {
        x: `1.5%`,
        y: `31%`,
        data: `<p>Insert the conditions that are being waived from the APS. If
        an amendment was used to change a condtion at any point during the 
        conditional period, use the most recent version of the condition
        </p>`,
      },
      {
        x: `1.5%`,
        y: `69%`,
        data: `<p>Insert the location, time and date of when these conditions are 
        being fulfilled. Then have your clients sign below.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `86%`,
        data: `<p><strong>Acknowledgement Receipt:</strong> This is where the opposite
        party inserts date/time of when the notice was received. Additionally, their
        name and signature are inserted to finalize the acknowledgement.  Similar to
        the confirmation of acceptance on an APS, it is important that you not only
        have your clients sign the notice before the expiry of the conditions, but 
        also that the signed notice is communicated to the other side before the 
        expiry of the conditions.
        </p>`,
      },
    ],
  },
  {
    id: 1271,
    form: `127`,
    page: `1`,
    imageURL: `../../forms/127/form127-1.png`,
    description: `<p>If your Buyer Client is choosing to not include recommended 
    conditions in their offer, use this form to have them acknowledge the risks 
    associated with not including said conditions.<br/><br/>
    <strong>Forms similar to this one based on client type:</strong><br/>
    FORM 128: Condition(s) In Offer - Seller Acknowledgement<br/></p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `15%`,
        data: `<p>Insert Buyers and Brokerage name(s), as shown on the APS.</p>`,
      },

      {
        x: `1.5%`,
        y: `23%`,
        data: `<p><strong>Address:</strong> Insert the address of the property that is
        being offered on. </p>`,
      },
      {
        x: `1.5%`,
        y: `26%`,
        data: `<p>Insert Sellers names, as shown on the APS.</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p>This section contains 2 parts:
        <ol><li>Have the Buyers initial whichever scenario applies. The Buyers are
        either choosing not to include conditions in the original offer OR they are
        choosing to waive conditions that were not fulfilled in their accepted offer.</li>
        <li><strong>Pertaining To:</strong> Have the Buyers initial in all bubbles 
        that apply.</li></ol>
        If the Buyers have chosen to not include/waive multiple conditions, then they
        will be initialing in all bubbles that apply.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `73%`,
        data: `<p>If you have advised your clients to do include additional clauses and
        they have chosen not to include them, insert statements relating to that fact 
        here.  This helps protect the clients by revisiting important items to include
        in the APS as well as protect you as an agent to show that a separate discussion
        took place regarding these matters.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `86%`,
        data: `<p>Have your clients sign here to acknowledge and agree to the contents
        of this acknowledgement, as well as to acknowledge having received a fully
        completed copy.
        </p>`,
      },
    ],
  },
  {
    id: 2401,
    form: `240`,
    page: `1`,
    imageURL: `../../forms/240/form240-1.png`,
    description: `<p><br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 212: Amendment to Listing Agreement - Authority to Offer for Lease<br/>
    </p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p>Insert the Property Address, Seller names, and Brokerage name.</p>`,
      },

      {
        x: `1.5%`,
        y: `23%`,
        data: `<p><strong>MLS Number:</strong> Insert the MLS Number of your listing.
        <br/><br/><strong>L/BR ID#:</strong> Enter the Brokerage ID here. You can 
        check your listing or search your own profile in the MLS system to obtain the
        Brokerage ID.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `27%`,
        data: `<p><strong>InterBoard:</strong> If your listing is interboarded enter
        that MLS Number here.<br/><br/><strong>Board:</strong> Enter the Board ID 
        here.<br/><br/><strong>Listing Expiry Date:</strong> Enter the current listing
        expiry date here (if you are amending expiry date, do not put new date
          here).</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p><strong>Listing Price:</strong> If you are amending the listing
        price, enter the current and new listing price here. Have your clients initial
        the bubble as well.  If not amending price, leave this section blank.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p><strong>Expiry Date:</strong> If you are amending the expiry
        date, enter the current and new expiry date here. Have your clients initial
        in both bubbles within this section. If not amending expiry date, 
        leave this section blank.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,
        data: `<p><strong>Other Amendments:</strong> If you are amending other items
        in the listing agreement then add them here. Have your clients initial
        in the bubbles that apply within this section. For example, amending the 
        commission amounts, holdover days, designated representatives...etc. 
        Essentially anything not included in the previous sections can be added here. 
        If not amending other items, leave this section blank.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `71%`,
        data: `<p>Have your clients sign here along with their printed names.<br/><br/>
        If spousal consent is required, have them sign in the area below.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `86%`,
        data: `<p>Insert the name of your Brokerage as well as sign below with printed
        name.
        </p>`,
      },
    ],
  },
  {
    id: 1601,
    form: `160`,
    page: `1`,
    imageURL: `../../forms/160/form160-1.png`,
    description: `<p>As per TRESA regulations, if you have any interest in a property 
    (ownership or close relative) a disclosure of interest must be signed by the 
    opposite party BEFORE an offer is made. This document should be uploaded to the
    listing so that interested parties can deal with the document before an offer 
    is made.</p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p>Insert the name of the agent disclosing, their designation and 
        brokerage.</p>`,
      },
      {
        x: `1.5%`,
        y: `22%`,
        data: `<p>Insert the property address that your Buyer client is making an 
        offer on.</p>`,
      },
      {
        x: `1.5%`,
        y: `30%`,
        data: `<p>If the disclosure involves an indirect interest then explain the
        relationship to the Buyer client here. Be as descriptive as possible when 
        describing the relationship.  Something like "Buyers are my relatives" would 
        not be sufficient. If this is your own purchase and you are the only Buyer 
        in the transaction, you can leave this blank.
        l</p>`,
      },
      {
        x: `1.5%`,
        y: `45%`,
        data: `<p>If you have any knowledge that may affect the value of the property 
        that you or your relative is purchasing, you must disclose it here. 
        Example: If you came to learn that a new development was coming to 
        the area and it would greatly affect the value of the property, then disclose
        as such.</p>`,
      },
      {
        x: `1.5%`,
        y: `65%`,
        data: `<p>As per the "will/will not" statement, you must disclose here whether 
        or not you are collecting commission for this transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `72%`,
        data: `<p>The Registrant making the disclosure would sign here, as well as
        their Broker of Record or Managing Broker.</p>`,
      },
      {
        x: `1.5%`,
        y: `85%`,
        data: `<p>This is where the Seller will sign to acknowledge that the disclosure
        has been made. As per TRESA, it is extremely important that the disclosure is
        not only sent, but also signed BEFORE an offer is made.
        </p>`,
      },
    ],
  },
  {
    id: 1611,
    form: `161`,
    page: `1`,
    imageURL: `../../forms/161/form161-1.png`,
    description: `<p>As per RECO regulations, if you have any interest in a property 
    (ownership or close relative) a disclosure of interest must be signed by the 
    opposite party BEFORE an offer is made.</p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p>Insert the name of the agent disclosing, their designation and 
        brokerage.</p>`,
      },
      {
        x: `1.5%`,
        y: `22%`,
        data: `<p>Insert the property address that the Seller client is selling.</p>`,
      },
      {
        x: `1.5%`,
        y: `30%`,
        data: `<p>If the disclosure involves an indirect interest then explain the
        relationship to the Seller client here. Be as descriptive as possible when 
        describing the relationship.  Something like "Sellers are my relatives" would 
        not be sufficient. If this is your own sale and you are the only Seller 
        in the transaction, you can leave this blank.
        l</p>`,
      },
      {
        x: `1.5%`,
        y: `45%`,
        data: `<p>If you have any knowledge that may affect the value of the property 
        that you or your relative is selling, you must disclose it here. 
        Example: If you came to learn that a quarry was being built down the road and 
        it would greatly affect the value of the property, then disclose as such.</p>`,
      },

      {
        x: `1.5%`,
        y: `62%`,
        data: `<p>The Registrant making the disclosure would sign here, as well as
        their Broker of Record or Managing Broker.</p>`,
      },
      {
        x: `1.5%`,
        y: `78%`,
        data: `<p>This is where the Buyer will sign to acknowledge that the disclosure
        has been made. As per TRESA, it is extremely important that the disclosure is
        not only sent, but also signed BEFORE an offer is made.
        </p>`,
      },
    ],
  },
  {
    id: 2441,
    form: `244`,
    page: `1`,
    imageURL: `../../forms/244/form244-1.png`,
    description: `<p>If your Seller Client is choosing to include specific directions 
    with their listing (example: delayed offers, 24 hour irrevocable...etc), then 
    complete this form so that the desired directions are clear and 
    communicated to all. Boards typically require this document
    to be uploaded to the listing.
    <br/>
    <strong>Please note: </strong>If the directions change at any point during the listing, a new 
    form must be completed and uploaded to the listing in order to reflect the most
    recent directions. Additionally, all those that 
    have seen the property, including upcoming booked showings, should be made aware
    that the information has changed.</p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p>Insert the Property Address, Seller names, and Brokerage name.</p>`,
      },

      {
        x: `1.5%`,
        y: `23%`,
        data: `<p><strong>MLS Number:</strong> Insert the MLS Number of your listing.
        <br/><br/><strong>L/BR ID#:</strong> Enter the Brokerage ID here. You can 
        check your listing or search your own profile in the MLS system to obtain the
        Brokerage ID.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `27%`,
        data: `<p><strong>InterBoard:</strong> If your listing is interboarded enter
        that MLS Number. Interboarding is where you are feeding your listing to 
        another board that you do not belong to.<br/><br/><strong>
        Board:</strong> Enter the Board ID.
        <br/><br/><strong>Listing Commencement Date:</strong> Enter the listing
        commencement date.</p>`,
      },
      {
        x: `1.5%`,
        y: `41%`,
        data: `<p><strong>No Conveyance Of Any Written Signed Offers:</strong> This
        section is to be used only if the Seller does not want to know if any offer has
        been received before the date stated here. By initialing this bubble and 
        completing the date/time, the Seller
        wants absolutely no communication whatsoever regarding offers. This includes
        pre-emptive offers. If the Seller is open to reviewing pre-emptive offers, see
        the next section.</p>`,
      },
      {
        x: `1.5%`,
        y: `47%`,
        data: `<p><strong>Other Directions:</strong> This section allows you to add any other 
        directions the Seller may want to include in the listing.
        <br/><br/>
      <strong>Typical Example re: Holding Offers/Pre-emptives:</strong> If the Seller 
      wishes to  have an offer presentation date but is also open to reviewing pre-emptive
      offers you could put something like this in the "Other Directions Section": 
      <br/><br/>"Offers to be presented Wednesday February 28th, 2024 at 9:00pm. The Seller
      reserves the right to review and/or accept pre-emptive offers." <br/><br/>If a pre-emptive
      offer is received before the current offer review date/time, this will likely change the 
      offer presentation date/time.  In this case, a new Form 244 must be signed with the new
      offer presentation date/time and uploaded to the listing. Additionally, everyone who 
      viewed the property, including upcoming showings, should be notified that the 
      offer presentation date has changed and notified when offers are due.</p> 
      `,
      },
      {
        x: `1.5%`,
        y: `82%`,
        data: `<p>Sellers will sign here as well as the Brokerage name, Agent signature
        and name of agent signing.
        </p>`,
      },
    ],
  },
  {
    id: 6501,
    form: `650`,
    page: `1`,
    imageURL: `../../forms/650/form650-1.png`,
    description: `<p>This form establishes a relationship between 2 brokerages, giving 
    them equal authority to represent the Seller for the sale of their property. 
    The listing paperwork should be done by one of the agents, with their brokerage
    named on all the agreements, do not put both brokerages. Essentially, all the 
    paperwork would look as though only 1 brokerage is listing the property. 
    This form is then used to establish a relationship between the 2 brokerages so
    that both brokerages can represent the client and facilitate the sale of the 
    property. Keep in mind, if the brokerages/agents involved are members of different 
    real estate boards then MLS Data Forms would be completed for each board.</p>
     `,
    hotspots: [
      {
        x: `1.5%`,
        y: `14%`,
        data: `<p><strong>Listing Brokerage:</strong> Insert the Listing Brokerage and Agent names.</p>`,
      },

      {
        x: `1.5%`,
        y: `20%`,
        data: `<p><strong>Co-Listing Brokerage:</strong> Insert the Co-Listing Brokerage and Agent names.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `25%`,
        data: `<p><strong>Property for Sale/Lease:</strong> Insert the address details
        of the property that is being sold, as shown on the listing agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `29%`,
        data: `<p><strong>Seller/Landlord:</strong> Insert the Seller(s)/Landlord(s)
        names here, as shown on the listing agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p><strong>Commission Split between the Brokerages:</strong> Insert 
        the amount of total commission that the Listing Brokerage will pay the Co-Listing 
        Brokerage (after payment to the Co-Operating Brokerage). Use a % out of 100
        for this statement. (Example: if splitting the commission 50/50 between the 
        2 brokerages, insert 50%).
      </p>`,
      },
      {
        x: `1.5%`,
        y: `43%`,
        data: `<p><strong>Details of Service:</strong> In this section, check the
        boxes to notate the responsibilities of each brokerage for the listed 
        activities. Both brokerages can be selected for a specific activity. As shown, 
        insert which boards/MLS Systems the listing will be uploaded to. Additionally, 
        check the box to indicate which brokerage will upload the listing to the 
        specified board.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `73%`,
        data: `<p><strong>Agreement & Acknowledgment:</strong> Insert the Location & 
        Date of when this agreement took place and have all parties sign the agreement.
         This includes both Brokerages as well as all Sellers involved.
      </p>`,
      },
    ],
  },
  {
    id: 1211,
    form: `121`,
    page: `1`,
    imageURL: `../../forms/121/form121-1.png`,
    description: `<p>This form is utilized when a party in the contract holds a 
    clause granting them the authority to issue a notice to waive conditions to 
    the other party. A common situation where this form is applicable is when an 
    escape clause exists in the Agreement of Purchase and Sale (APS), allowing the 
    Seller to entertain another offer. Upon acceptance of such an offer, this 
    notice is delivered to the Buyer with the initial offer, alerting them to 
    a specific timeframe to waive their conditions. If the conditions are not 
    waived within the stipulated time, the offer is rendered null and void. A 
    mutual release would still be required for the offer that is nullified.<br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 131: Notice to Remove Condition(s) - Option to Purchase Agreement<br/>
    FORM 151: Notice to Remove Condition(s) - Assignment of Agreement of Purchase and Sale<br/>
    FORM 411: Notice to Remove Condition(s) - Agreement to Lease - Residential</p>`,
    hotspots: [
      {
        x: `1.5%`,
        y: `14%`,
        data: `<p>Insert Buyers and Sellers names.</p>`,
      },
      {
        x: `1.5%`,
        y: `20%`,
        data: `<p><strong>Date:</strong> Insert the date of the APS that this notice is referencing. This
        date is found at the top of the APS on page 1.<br/><br/>
        <strong>Address:</strong> Insert the address of the property that is being 
        purchased.</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p>Insert the time and date the conditions must be removed by. The 
        time provided is typically referenced from the clause in the APS that allows
        the party to serve this notice.<br/><br/>
        <strong>Example:</strong> If there is an escape clause in the APS which states
        48 hours, then you would choose a date/time that is 48 hours from when you 
        serve this notice.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p>Insert the condtions, found in the APS, that need to be removed within the 
        specified timeframe. You do not need to copy/paste the whole condition. You can
        reference what the conditions pertain to.</p>`,
      },

      {
        x: `1.5%`,
        y: `67%`,
        data: `<p>Have all Buyers and Sellers sign in their respective sections.
        </p>`,
      },
    ],
  },
  {
    id: 1221,
    form: `122`,
    page: `1`,
    imageURL: `../../forms/122/form122-1.png`,
    description: `<p>Once this document is signed by both the buyer and the seller, 
    it releases all parties from any obligations or liabilities 
    arising from a previously agreed-upon contract. This document is typically used 
    in situations where an agreement has been terminated or becomes null and void 
    due to specific conditions not being met by either party. By signing the 
    mutual release, both parties agree to release each other from the terms of 
    the agreement, allowing them to move forward without any further  
    obligations or liabilities towards each other related to that particular 
    agreement. It essentially serves as a formal agreement to end the contract 
    and any associated obligations mutually. Furthermore, it addresses the 
    release of the deposit to the party specified on the form.<br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 132: Mutual Release - Option to Purchase Agreement<br/>
    FORM 152: Mutual Release - Assignment of Agreement of Purchase and Sale<br/>
    FORM 402: Mutual Release - Agreement to Lease - Residential
    </p>`,

    hotspots: [
      {
        x: `1.5%`,
        y: `14%`,
        data: `<p>Insert Buyers and Sellers names.</p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p><strong>Brokerages:</strong> Insert the names of the Brokerages
        involved in the transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `22%`,
        data: `<p><strong>Date:</strong> Insert the date of the APS that this mutual
        release is referencing. This date is found at the top of the APS on page 1. 
         <br/><br/>
        <strong>Address:</strong> Insert the address, referenced from the APS,
        of the transaction that is being mutually released.</p>`,
      },
      {
        x: `1.5%`,
        y: `35%`,
        data: `<p>Insert the total amount of funds to be returned to the party 
        specified in the "payable to" section below.  
        </p>`,
      },
      {
        x: `1.5%`,
        y: `39%`,
        data: `<p><strong>Payable to:</strong> Insert the party/parties to receive
        the funds stated above. If it is a typical release where the funds are being
        returned to the Buyer side, insert the name of the person who provided the 
        deposit.  It is important to double check who provided the deposit as it  
        could have been just 1 of the Buyers. In some instances, someone other than 
        one of the Buyers (example: parents) could have provided the deposit.</p>`,
      },

      {
        x: `1.5%`,
        y: `44%`,
        data: `<p><strong>Irrevocability:</strong> Insert the irrevocablility 
        details. Make sure you provide enough time so the other side can sign before
        the irrevocable time expires.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `63%`,
        data: `<p><strong>Signatures:</strong> Have all Buyers and Sellers sign 
        within the irrevocable period to prevent the mutual release from being 
        considered null and void. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `74%`,
        data: `<p><strong>Confirmation of Acceptance:</strong> Have the appropriate
        party sign the confirmation of acceptance within the irrevocable period to 
        prevent the mutual release from being considered null and void. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `86%`,
        data: `<p><strong>Broker Signature</strong> In this section the Brokers
        from their respective brokerages sign to release claim for commission, as 
        shown in the printed statement. 
        </p>`,
      },
    ],
  },
  {
    id: 1701,
    form: `170`,
    page: `1`,
    imageURL: `../../forms/170/form170-1.png`,
    description: `<p>The purpose of Form 170 is to obtain consent from the Buyer 
    & Seller to advertise their property in various media channels, including print,
    online platforms, social media, and other promotional materials. By signing 
    this form, and initialing appropriate bubbles, the parties gives their 
    permission to advertise their property according to the terms specified in
    the form. For more information on which parties need to consent for SOLD or 
    SOLD plus price/terms advertising, visit the <a href="https://www.reco.on.ca/agents-and-brokerages/tresa-explained/reco-bulletins"
    target="_blank" rel="noreferrer">RECO Bulletin 5.4 "Advertising sold 
    properties"</a></p>`,

    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Buyers and Sellers names.</p>`,
      },
      {
        x: `1.5%`,
        y: `24%`,
        data: `<p><strong>Brokerages:</strong> Insert the names of the Brokerages
        involved in the transaction.</p>`,
      },

      {
        x: `1.5%`,
        y: `30%`,
        data: `<p>Provide the property address for which advertising consent is 
        being requested.  
        </p>`,
      },
      {
        x: `1.5%`,
        y: `39%`,
        data: `<p>Within this section, select the relevant checkboxes and provide 
        any necessary remarks pertaining to the items requested for advertising. Ensure
        both Buyers & Sellers initial in their respective bubble for each requested
        item. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `67%`,
        data: `<p>It is important to note that as per the pre-printed statement, the
        consent to advertise is effective once the deal is firm & binding. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `79%`,
        data: `<p>Obtain signatures from ALL Buyers and Sellers, confirming their 
        consent to the advertising request outlined above. 
        </p>`,
      },
    ],
  },
  {
    id: 2421,
    form: `242`,
    page: `1`,
    imageURL: `../../forms/242/form242-1.png`,
    description: `<p><br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 214: Cancellation of Listing Agreement – Authority to Offer for Lease<br/>
    </p>`,

    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p>Insert the Property Address, Seller names, and Brokerage name.</p>`,
      },

      {
        x: `1.5%`,
        y: `23.5%`,
        data: `<p><strong>MLS Number:</strong> Insert the MLS Number of the listing
        that is being cancelled.
        <br/><br/><strong>L/BR ID#:</strong> Enter the Brokerage ID here. You can 
        check your listing or search your own profile in the MLS system to obtain the
        Brokerage ID.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `28%`,
        data: `<p><strong>InterBoard:</strong> If your listing is interboarded enter
        that MLS Number here.<br/><br/><strong>Board:</strong> Enter the Board ID 
        here.<br/><br/><strong>Agreement Expiry Date:</strong> Enter the listing
        agreement expiry date.</p>`,
      },
      {
        x: `1.5%`,
        y: `33%`,
        data: `<p><strong>Reason for Cancelling:</strong> Enter the reason the 
        Sellers wish to cancel the agreement. 
        </p>`,
      },

      {
        x: `1.5%`,
        y: `47%`,
        data: `<p><strong>Holdover:</strong> It's important to highlight that even 
        after the cancellation of the listing, the Holdover Clause from the 
        original listing agreement remains applicable. As specified in this 
        context, it is governed by the identical terms and conditions outlined 
        in the Listing Agreement.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `75%`,
        data: `<p>Have the Sellers sign here.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `84%`,
        data: `<p>Enter the name of the listing brokerage. Additionally, make sure 
        that brokerage management signs this document. As mentioned earlier, the 
        cancellation doesn't become effective until all parties have signed this 
        cancellation. Follow your brokerage policies when submitting this 
        cancellation.
        </p>`,
      },
    ],
  },
  {
    id: 3201,
    form: `320`,
    page: `1`,
    imageURL: `../../forms/320/form320-1.png`,
    description: `<p><br/><br/>
    <strong>Forms similar to this one based on transaction type:</strong><br/>
    FORM 324: Confirmation of Co-operation and Representation - Tenant/Landlord<br/>
    </p>`,

    hotspots: [
      {
        x: `1.5%`,
        y: `14%`,
        data: `<p>Insert the Buyers and Sellers names as shown on the Agreement.</p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Provide the address of the property related to this transaction.</p>`,
      },
      {
        x: `1.5%`,
        y: `32%`,
        data: `<p>Within these sections, mark the checkboxes that indicate the 
        representation of the Brokerages/Agents, Buyers, and Sellers involved in 
        the transaction.<br/><br/>
        For clear direction on which boxes to check, refer to the 
        <a href="/form-320" target="_blank" rel="noreferrer">CoC Boxes Section</a> 
        on this website (will open in new tab).
      </p>`,
      },
      {
        x: `1.5%`,
        y: `88%`,
        data: `<p>Have the appropriate parties inital in the bubbles.</p>`,
      },
    ],
  },
  {
    id: 3202,
    form: `320`,
    page: `1`,
    imageURL: `../../forms/320/form320-2.png`,

    hotspots: [
      {
        x: `1.5%`,
        y: `28%`,
        data: `<p>Enter the details regarding the Brokerages and Agents 
        participating in the transaction, including those from the Co-Operating 
        Brokerage (if applicable) and the Listing Brokerage (if applicable).</p>`,
      },
      {
        x: `1.5%`,
        y: `44%`,
        data: `<p>If this transaction involves multiple representation, have both
        Buyers and Sellers initial their respective bubble. This is required in 
        addition to Form 325 & Form 326 (consent for multiple representation), 
        which should have been dealt with before these offer documents. Refer to
        the <a href="/multiple-representation" target="_blank" rel="noreferrer">
        Multiple Rep Section</a> for more info</p>`,
      },
      {
        x: `1.5%`,
        y: `58%`,
        data: `<p>Buyers and Sellers sign here to complete the document.</p>`,
      },
    ],
  },
  {
    id: 2711,
    form: `271`,
    page: `1`,
    imageURL: `../../forms/271/form271-1.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `14%`,
        data: `<p>Have your clients initial in ONE of the bubbles depending on the
        type of listing they have chosen to go with. If you sign an exclusive 
        agreement and intend to list on the MLS System in the future, you should use
        a FORM 240 - Amendment to Listing Agreement to convert it to an MLS Listing. 
        Both bubbles should not be initialed.
        <br/><br/>
        <strong>MLS Bubble:</strong> If your clients initial in this bubble then 
        the property will be listed on the MLS System.<br/><br/>
        <strong>Exclusive:</strong> If your clients initial in this bubble then 
        the property will not be listed on the MLS System and will be an Exclusive
        Listing.
      
        </p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Brokerage information here: Name, Address, City...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `23%`,
        data: `<p>Insert the full legal names of all Seller clients. Double check 
        your clients IDs to ensure accuracy. Additionally, you should check 
        GeoWarehouse to ensure that the names on title match the Sellers names.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `26%`,
        data: `<p>Insert the Designated Representative(s). The names listed here
        will be the agents authorized to provide services to the clients named above. 
        If you are on a team it is important to note that Team Names are not 
        acceptable as designated representatives. Each agent that will be providing 
        services to the client must be listed. Alternatively, you can also have 1 designated 
        representative here and a "See Schedule A" statement which may include 
        additional designated representatives. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `32%`,
        data: `<p>Insert the address information of the property to be listed. Verify
        the details in GeoWarehouse as some properties are registered under a different
        address based on the land registration. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p>This marks the start and end of representation with the client, 
        as well as the listing period for the property, set by these particular 
        times and dates. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `43%`,
        data: `<p>This is a mandatory bubble the client must initial to acknowledge
        that the client understands that the time length of representation is a 
        negotiable element of the agreement. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `48%`,
        data: `<p>This value represents the asking price for the property. It's 
        important to confirm that the price is 
        consistent in both numerical and textual formats (example: $500,000 / 
        Five Hundred Thousand) 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `53%`,
        data: `<p> Have your clients initial here to warrant that they are not party
        to another listing agreement.  It is important to have this discussion
        with your clients to ensure they are aware of the repercussions associated
        with already being under contract with another agent. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `56%`,
        data: `<p>As per TRESA, all agreements should be accompanied by a Schedule
        that outlines the services to be provided to the client.  Since Schedule A is
        included with this agreement, only add additional Schedule Letters here if 
        they exist in addition to the pre-printed A.</p>`,
      },
      {
        x: `1.5%`,
        y: `73%`,
        data: `<p><strong>Total Commission (Listing + Selling Sides):</strong>
        The first spot is where you insert the amount of TOTAL commission 
        that the Seller will pay for the Sale of their property. Whether or not a
        co-operating brokerage is involved, the full amount of the TOTAL commission
        is to be collected from the Listing Brokerage upon a successful sale.
        This amount can be a percentage, a specific dollar value, or a combination
        of both. If the total commission is a specific amount or combination, use 
        the longer line that is available. You can also add additional statements 
        here, if applicable.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `77%`,
        data: `<p><strong>Co-operating Brokerage Commission:</strong>
        This spot is where you insert the amount of commission 
        that the Seller will pay to the co-operating brokerage for the Sale of 
        their property, if a co-operating brokerage is involved in the sale. This 
        amount is comes out of the TOTAL commission and is not in addition to the 
        amount stated above. 
        This amount can be a percentage, a specific dollar value, or a combination
        of both. If the co-operating brokerage commission is a specific amount or 
        combination, use the longer line that is available. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `82%`,
        data: `<p>Insert the number of holdover days that apply to this agreement. Read
        the printed statement associated with this holdover period to see how it applies.
        In short, if this agreement expires and the Seller obtains an
        offer from anyone introduced to the property during the listing period
         (example: 90 days), they 
        agree to pay the commission as per this agreement if that offer turns into a
        successful transaction. However, if they enter into 
        a new listing agreement with another Brokerage, the commission
        amount is reduced by the amount on their new agreement.</p>`,
      },
      {
        x: `18%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Sellers" bubble.
         The agent can initial in the "Initials of Listing Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 2712,
    form: `271`,
    page: `2`,
    imageURL: `../../forms/271/form271-2.png`,
    hotspots: [
      {
        x: `18%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Sellers" bubble.
         The agent can initial in the "Initials of Listing Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 2713,
    form: `271`,
    page: `3`,
    imageURL: `../../forms/271/form271-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `28%`,
        data: `<p>Have your clients initial one of the bubbles based on their choice:<br/>
        <strong>Does:</strong> By choosing this option, the Seller authorizes 
        other members of the real estate board to reach out to them upon the 
        expiration of the listing.<br/>
        <strong>Does Not:</strong> By choosing this option, the Seller DOES NOT 
        authorize other members of the real estate board to reach out to them upon 
        the expiration of the listing. (this is the most common option, but it is
          up to the Seller which option they would like to select).
        </p>`,
      },
      {
        x: `1.5%`,
        y: `50%`,
        data: `<p>You sign here, add the date and insert your name.</p>`,
      },
      {
        x: `1.5%`,
        y: `59%`,
        data: `<p>Have your Seller clients sign here and insert date. Adding their
        telephone is not mandatory but advised in case the Brokerage needs to 
        contact the Sellers.</p>`,
      },
      {
        x: `1.5%`,
        y: `67%`,
        data: `<p><strong>Spousal Consent:</strong> In the case where this property 
        is deemed the matrimonial home and the spouse, although not listed on the 
        title, is necessary to grant consent for the property's sale, please have 
        the spouse sign at this location.</p>`,
      },
      {
        x: `1.5%`,
        y: `74%`,
        data: `<p><strong>Declaration of Insurance:</strong> Insert your name along
        with signature below. In this section you are declaring that you are insured
        as an agent (TRESA requirement).</p>`,
      },
      {
        x: `1.5%`,
        y: `83%`,
        data: `<p><strong>Acknowledgement:</strong> Have your clients sign here to 
        acknowledge that you have provided them with a fully signed copy of this 
        agreement. As per our Code of Ethics, all clients must be provided with a 
        copy of the fully signed agreement.</p>`,
      },
    ],
  },
  {
    id: 2714,
    form: `271`,
    page: `4`,
    imageURL: `../../forms/271/form271-4.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `16%`,
        data: `<p><strong>Brokerage:</strong> Insert your Brokerage name.<br/><br/>
        <strong>Seller:</strong> Insert your Seller clients names<br/><br/>
        <strong>Property:</strong> Insert the address of the property being listed.</p>`,
      },

      {
        x: `1.5%`,
        y: `30.5%`,
        data: `<p>As per TRESA, all agreements should include a list of services
        to be provided to the client. Add those services here. Keep in mind, this
         will likely be an ever changing Schedule.  This is your opportunity to 
         showcase all of the unique services that you will provide to your clients. 
         Follow the "don't overpromise and under-deliver" principle so that you do 
         not create a situation in which you have breached the agreement.</p>`,
      },
      {
        x: `18%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Sellers" bubble.
         The agent can initial in the "Initials of Listing Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 2721,
    form: `272`,
    page: `1`,
    imageURL: `../../forms/272/form272-1.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `14%`,
        data: `<p>Have your clients initial in ONE of the bubbles depending on the
        type of listing they have chosen to go with. If you sign an exclusive 
        agreement and intend to list on the MLS System in the future, you should use
        a FORM 212 - Amendment to Listing Agreement to convert it to an MLS Listing. 
        Both bubbles should not be initialed.
        <br/><br/>
        <strong>MLS Bubble:</strong> If your clients initial in this bubble then 
        the property will be listed on the MLS System.<br/><br/>
        <strong>Exclusive:</strong> If your clients initial in this bubble then 
        the property will not be listed on the MLS System and will be an Exclusive
        Listing.
      
        </p>`,
      },
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p>Insert Brokerage information here: Name, Address, City...etc.</p>`,
      },
      {
        x: `1.5%`,
        y: `22.5%`,
        data: `<p>Insert the full legal names of all Landlord clients. Double check 
        your clients IDs to ensure accuracy. Additionally, you should check 
        GeoWarehouse to ensure that the names on title match the Landlord names. It 
        is imperative that all individuals listed on the title are included as 
        Landlord names to ensure appropriate authorization for leasing the property, 
        regardless of the information provided in the listing.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `26%`,
        data: `<p>Insert the Designated Representative(s). The names listed here
        will be the agents authorized to provide services to the clients named above. 
        If you are on a team it is important to note that Team Names are not 
        acceptable as designated representatives. Each agent that will be providing 
        services to the client must be listed. Alternatively, you can also have 1 designated 
        representative here and a "See Schedule A" statement which may include 
        additional designated representatives. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `32%`,
        data: `<p>Insert the address information of the property to be listed. Verify
        the details in GeoWarehouse as some properties are registered under a different
        address based on the land registration. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `38%`,
        data: `<p>This marks the start and end of representation with the client, 
        as well as the listing period for the property, set by these particular 
        times and dates. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `43%`,
        data: `<p>This is a mandatory bubble the client must initial to acknowledge
        that the client understands that the time length of representation is a 
        negotiable element of the agreement. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `48%`,
        data: `<p>This value represents the asking rental amount for the property. 
        It's important to confirm that the price is consistent in both numerical 
        and textual formats (example: $2,500 / Two Thousand Five Hundred). This 
        amount typically reflects the monthly amount. If the rental payment frequency differs
        from the typical monthly amount, please specify along with the price.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `51%`,
        data: `<p> Have your clients initial here to warrant that they are not party
        to another listing agreement.  It is important to have this discussion
        with your clients to ensure they are aware of the repercussions associated
        with already being under contract with another agent. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `54%`,
        data: `<p>As per TRESA, all agreements should be accompanied by a Schedule
        that outlines the services to be provided to the client.  Since Schedule A is
        included with this agreement, only add additional Schedule Letters here if 
        they exist in addition to the pre-printed A.</p>`,
      },
      {
        x: `1.5%`,
        y: `69%`,
        data: `<p><strong>Total Commission (Listing + Selling Sides):</strong>
        The first spot is where you insert the amount of TOTAL commission 
        that the Landlord will pay for the Leasing of their property. Whether or not a
        co-operating brokerage is involved, the full amount of the TOTAL commission
        is to be collected from the Listing Brokerage upon a successful agreement.
        This amount can be a percentage, a specific dollar value, or a combination
        of both. With residential leases, the amount is typically a specific dollar
        amount or statement as seen in the example. 
         You can also add additional statements here, if applicable.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `73%`,
        data: `<p><strong>Co-operating Brokerage Commission:</strong>
        This spot is where you insert the amount of commission 
        that the Landlord will pay to the co-operating brokerage for the Leasing of 
        their property, if a co-operating brokerage is involved in the transaction. This 
        amount comes out of the TOTAL commission and is not in addition to the 
        amount stated above. 
        This amount can be a percentage, a specific dollar value, or a combination
        of both. With residential leases, the amount is typically a specific dollar
        amount or statement as seen in the example.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `77%`,
        data: `<p>Insert the number of holdover days that apply to this agreement. Read
        the printed statement associated with this holdover period to see how it applies.
        In short, if this agreement expires and the Landlord obtains an
        offer from anyone introduced to the property during the listing period
         (example: 90 days), they 
        agree to pay the commission as per this agreement if that offer turns into a
        successful transaction. However, if they enter into 
        a new listing agreement with another Brokerage, the commission
        amount is reduced by the amount on their new agreement.</p>`,
      },
      {
        x: `18%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Landlords" 
        bubble.
         The agent can initial in the "Initials of Listing Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 2722,
    form: `272`,
    page: `2`,
    imageURL: `../../forms/272/form272-2.png`,
    hotspots: [
      {
        x: `18%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Landlords" 
        bubble.
         The agent can initial in the "Initials of Listing Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 2723,
    form: `272`,
    page: `3`,
    imageURL: `../../forms/272/form272-3.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `32%`,
        data: `<p>Have your clients initial one of the bubbles based on their choice:<br/>
        <strong>Does:</strong> By choosing this option, the Landlord authorizes 
        other members of the real estate board to reach out to them upon the 
        expiration of the listing.<br/>
        <strong>Does Not:</strong> By choosing this option, the Landlord DOES NOT 
        authorize other members of the real estate board to reach out to them upon 
        the expiration of the listing. (this is the most common option, but it is
          up to the Landlord which option they would like to select).
        </p>`,
      },
      {
        x: `1.5%`,
        y: `50%`,
        data: `<p>You sign here, add the date and insert your name.</p>`,
      },
      {
        x: `1.5%`,
        y: `61%`,
        data: `<p>Have your Landlord clients sign here and insert date. Adding their
        telephone is not mandatory but advised in case the Brokerage needs to 
        contact the Landlord.</p>`,
      },
      {
        x: `1.5%`,
        y: `69%`,
        data: `<p><strong>Spousal Consent:</strong> In the case where this property 
        is deemed the matrimonial home and the spouse, although not listed on the 
        title, is necessary to grant consent for the leasing of the property, please 
        have the spouse sign at this location.</p>`,
      },
      {
        x: `1.5%`,
        y: `76%`,
        data: `<p><strong>Declaration of Insurance:</strong> Insert your name along
        with signature below. In this section you are declaring that you are insured
        as an agent (TRESA requirement).</p>`,
      },
      {
        x: `1.5%`,
        y: `85%`,
        data: `<p><strong>Acknowledgement:</strong> Have your clients sign here to 
        acknowledge that you have provided them with a fully signed copy of this 
        agreement. As per our Code of Ethics, all clients must be provided with a 
        copy of the fully signed agreement.</p>`,
      },
    ],
  },
  {
    id: 2724,
    form: `272`,
    page: `4`,
    imageURL: `../../forms/272/form272-4.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `18%`,
        data: `<p><strong>Brokerage:</strong> Insert your Brokerage name.<br/><br/>
        <strong>Landlord:</strong> Insert your Landlord clients names<br/><br/>
        <strong>Property:</strong> Insert the address of the property being listed.</p>`,
      },

      {
        x: `1.5%`,
        y: `30.5%`,
        data: `<p>As per TRESA, all agreements should include a list of services
        to be provided to the client. Add those services here. Keep in mind, this
         will likely be an ever changing Schedule.  This is your opportunity to 
         showcase all of the unique services that you will provide to your clients. 
         Follow the "don't overpromise and under-deliver" principle so that you do 
         not create a situation in which you have breached the agreement.</p>`,
      },
      {
        x: `18%`,
        y: `88.5%`,
        data: `<p>Have ALL of your clients initial in the "Initials of Landlords" 
        bubble.
         The agent can initial in the "Initials of Listing Brokerage" bubble.</p>`,
      },
    ],
  },
  {
    id: 6411,
    form: `641`,
    page: `1`,
    imageURL: `../../forms/641/form641-1.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `13%`,
        data: `<p><strong>Referring Brokerage:</strong> Insert the Referring Brokerage 
        Name, Address, Phone Number, and Referring Agent Name. <br/>(This is the brokerage/agent
        sending out the client).
        </p>`,
      },
      {
        x: `1.5%`,
        y: `19%`,
        data: `<p><strong>Receiving Brokerage:</strong> Insert the Receiving Brokerage 
        Name, Address, Phone Number, and Receiving Agent Name. <br/>(This is the 
          brokerage/agent that is receiving the client).
        </p>`,
      },

      {
        x: `1.5%`,
        y: `29%`,
        data: `<p>Complete this section by entering the details of the clients who are 
        being referred in this agreement. Take full advantage of the available fields 
        in this section to incorporate as much pertinent information as possible.</p>`,
      },
      {
        x: `1.5%`,
        y: `37%`,
        data: `<p>Select the type of client that is being referred:
        <br/>
        <strong>Seller:</strong> If the client being referred is a Seller, check the 
        box and insert the address of the property to be listed.<br/><br/>
        <strong>Buyer:</strong> If the client being referred is a Buyer, check the box
        and insert any pertinent details regarding their property search. See example 
        statement for some possible details.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `49%`,
        data: `<p>In this section, insert the % of commission to be paid to the 
        referring brokerage.  This section deals with the client consenting and 
        acknowledging the details of the referral fee to be paid by the receiving 
        brokerage to the referring brokerage. 
        </p>`,
      },
      {
        x: `1.5%`,
        y: `54%`,
        data: `<p>As per disclosure requirements, have the Buyer/Seller sign here to
        acknowledge that a referral is taking place. Additionally, the referring 
        agent name and signature are located here.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `65%`,
        data: `<p> This section deals with the
        receiving brokerage acknowledging reciept of the referral and agrees to 
        offer the service(s) to the Buyer/Seller, as specified above. Insert the 
        % of commission to be paid by the receiving brokerage to the referring
        brokerage. Additionally, the receiving agent inserts their name and 
        signature in this section.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `82%`,
        data: `<p> This section is to be completed when there is a successful 
        purchase or sale by the receiving brokerage for the above noted client. As 
        the example shows, insert the address of the property sold/purchased, the 
        sale price, closing date and the referral fee payable. Additionally, the 
        receiving agent will insert their name and sign here.
        </p>`,
      },
    ],
  },
  {
    id: 2991,
    form: `299`,
    page: `1`,
    imageURL: `../../forms/299/form299-1.png`,
    hotspots: [
      {
        x: `1.5%`,
        y: `17%`,
        data: `<p>This section deals with all the information relating to the 
        property for which the disclosure is being made, when it is being made and
        all the parties involved in the transaction, including the brokerage(s), 
        seller and prospsective buyer.

        </p>`,
      },

      {
        x: `1.5%`,
        y: `39%`,
        data: `<p>Have the Seller initial all bubbles that apply depending on the 
        disclosure(s) being made. If any additional comments are to be supplied with 
        the disclosure add them at the bottom of this section.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `61%`,
        data: `<p>If additional schedules exist and form part of the disclosure, 
        add those here.  This could involve additional documents relating to the 
        disclosure being made. 
        </p>`,
      },

      {
        x: `1.5%`,
        y: `67%`,
        data: `<p> Have all appropriate parties sign here as indicated on the form. 
        This includes all Sellers, the listing brokerage, signature of listing agent 
        and the listing agents name.
        </p>`,
      },
      {
        x: `1.5%`,
        y: `82%`,
        data: `<p> Have all appropriate parties sign here as indicated on the form. 
        This includes all Buyers, the co-operating brokerage, signature of co-operating 
        agent and the co-operating agents name.
        </p>`,
      },
    ],
  },
];

export default FormPageData;
