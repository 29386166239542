import React from "react";

export default function AssignmentListingNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        Agreements of Purchase and Sale are assignable by nature, unless
        otherwise specified. As a result, it is important to obtain the original
        purchase agreement before listing to ensure the agreement is assignable.
      </li>
      <li className="pb-2">
        If it's a Pre-Con assignment, builders' consent is typically required,
        and so that clause should be included. In addition, it is always
        recommended to have lawyer review conditions in the agreement as these
        can be complex transactions.
      </li>
      <li className="pb-2">
        Have your clients obtain a lawyer that is both experienced and
        comfortable doing assignment agreements.
      </li>
      <li className="pb-2">
        It is important to check the original purchase contract or speak with
        the builder to find out if the property can be advertised (both MLS and
        public websites)
      </li>
    </ul>
  );
}
