import React, { useState } from "react";
import "./MBSig.css";
import "./CustomApp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function RECOGuide() {
  const [ShowContact, setShowContact] = useState(false);

  const handleInquire = (e) => {
    setShowContact(!ShowContact);
  };
  return (
    <div className="body pt-10">
      <div className="streamline px-3 pb-5">
        Simplify and Streamline... <br />
        Brokerage Ops with One Simple Link.
      </div>
      <div className="streamline-small ">
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Custom Password Protected Application.
        </div>
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Optimized for Mobile & Desktop.
        </div>

        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Custom Document Submission Procedures for your Brokerage.
        </div>
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Custom Transaction Checklists for your Brokerage.
        </div>
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Custom Transaction Notes for your Checklists.
        </div>
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Clear Procedures to Increase Efficiency for Agents & Admin.
        </div>
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Reduce Agent Requests for Required Documents.
        </div>

        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Customized Header/Footer with Brokerage Logo
        </div>
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Customized to suit Brokerage Marketing Colours
        </div>
        <div className="flex items-top pt-1 pr-2">
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color="#00ad43"
            className=" pr-3 pb-1 pt-1"
          />
          Preferred Professional Partners Section (upon request)
        </div>
      </div>
      <br />
      <div className="custom-app-text px-3 ">
        Increase Agent Confidence and Decrease Brokerage requests by providing
        clear Brokerage requirements for specific types of transactions and/or
        internal procedures.
      </div>
      <div className="pl-3">
        <button className=" custom-app-button " onClick={handleInquire}>
          Inquire Today
        </button>
      </div>
      {ShowContact === true ? (
        <>
          <div className="contact-box flex flex-col py-5 px-3">
            <div className=" contact-info">
              <strong>Pange Studios</strong>
              <br />
              <strong>Email:</strong>{" "}
              <a href="mailto:PangeStudiosRE@gmail.com">
                PangeStudiosRE@gmail.com
              </a>{" "}
            </div>
            <div className="contact-info">
              <strong>Call:</strong> 519.317.2215 (Messages returned after 5pm
              Weekdays, 9-5pm Saturday/Sunday)
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
