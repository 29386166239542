import React from "react";
import "./Listing.css";
import "../Cards/ChecklistCard.css";
import ChecklistCard from "../Cards/ChecklistCard";
import LOINotes from "../../notes/LOINotes";

export default function LetterOfOpinion() {
  const LOIData = [
    "RECO Information Guide Acknowledgement",
    "Listing Agreement with Schedule A (271)",
    "Copy of Letter of Opinion for services rendered (include CMA if applicable)",
    "Additional Required Documents (as per your Brokerage)",
  ];

  return (
    <div className="checklists flex flex-col items-center justify-center">
      <ChecklistCard
        Title1={""}
        CheckListData={LOIData}
        Header="Letter Of Opinion"
      />
      <div className="important-notes flex flex-col w-full justify-left pt-6">
        <strong>Important Notes:</strong>

        <LOINotes />
      </div>
    </div>
  );
}
