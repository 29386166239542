import React from "react";

export default function ReferralNotes() {
  return (
    <ul className="ul-a">
      <li className="pb-2">
        Referrals or other payments must be paid through the brokerage.
      </li>
      <li className="pb-2">
        Please complete the “Results of the Referral” section at the bottom of
        Form 641 to complete the document.
      </li>
      <li className="pb-2">
        You are NOT legally permitted to pay referrals to those who are not
        registered salespeople or brokers.
      </li>
      <li className="pb-2">
        Client rebates are permitted by RECO provided it is being paid to a
        party to the transaction.
      </li>
      <li className="pb-2">
        Clients need to sign forms to confirm disclosure requirements have been
        met as per our Code of Ethics. If clients refuse to sign, their consent
        may not be required but proof of written disclosure must occur. Check
        with your brokerages for any additional policies. Consent is required by
        RECO if it involves a change to a commission agreement or a conflict of
        interest.
      </li>
    </ul>
  );
}
