import React, { useState } from "react";
import "./Listing.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ResidentialListing from "../Checklists/ResidentialListing.js";
import LeaseListing from "../Checklists/LeaseListing.js";
import AssignmentListing from "../Checklists/AssignmentListing.js";

export default function Listing() {
  const [ListingType, setListingType] = useState("");

  let listingTemplate = "";
  if (ListingType === "Residential") {
    listingTemplate = <ResidentialListing />;
  } else if (ListingType === "Lease") {
    listingTemplate = <LeaseListing />;
  } else if (ListingType === "Assignment") {
    listingTemplate = <AssignmentListing />;
  } else {
    listingTemplate = "";
  }
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d5a80",
        dark: "#3d5a80",
      },
      text: {
        primary: "#31303F",
        secondary: "#3d5a80",
      },
    },
  });
  const setTemplate = (e) => {
    setListingType(e.target.value);
  };

  return (
    <div className="checklists flex flex-col items-center justify-center">
      <div className="flex flex-col w-full pl-4 ">
        <div className="flex flex-col items-left pb-4">
          <div>Listing Type?</div>
          <ThemeProvider theme={theme}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Residential"
                  control={<Radio size="small" onClick={setTemplate} />}
                  label="Residential"
                />
                <FormControlLabel
                  value="Lease"
                  control={<Radio size="small" onClick={setTemplate} />}
                  label="Lease"
                />
                <FormControlLabel
                  value="Assignment"
                  control={<Radio size="small" onClick={setTemplate} />}
                  label="Assignment"
                />
              </RadioGroup>
            </FormControl>
          </ThemeProvider>
        </div>
      </div>
      {listingTemplate}
    </div>
  );
}
