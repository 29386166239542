import React from "react";
import "./MBSig.css";
import CustomAppAd from "../Globals/CustomAppAd";

export default function RECOGuide() {
  return (
    <div className="body pt-10">
      <CustomAppAd />
      <div className="title-header">
        <h1>RECO Information Guide </h1>
      </div>
      <p>
        It is important to note that ALL consumers seeking your Judgements,
        Opinions, Services, Skills or Advice must be provided the RECO
        Information Guide, as a first step. This guide, as per RECO, cannot be
        altered or personalized.
      </p>
      <br />
      <p>
        <strong>Important:</strong> Under TRESA - no person can receive
        judgements, opinions, skills, services or advice without a
        representation agreement.
      </p>
      <br />
      <div>
        <h2>The RECO Information Guide should be:</h2>
        <ol>
          <li>
            <strong>Shared with the Consumer:</strong> Ensure you have proof it
            was sent to the consumer.
          </li>
          <li>
            <strong>Explained to the Consumer:</strong> Allows them to make an
            informed decision with regards to their options.
          </li>
          <li>
            <strong>Acknowledged:</strong> Obtain signed acknowledgement (last
            page) or RECO acknowledgement email. It is not mandatory to obtain
            an acknowledgement signature/email. However, if an issue arises,
            RECO may request for proof that the guide was explained to the
            consumer, to ensure you fulfilled your obligations under the Act.
          </li>
        </ol>
        <br />
        <p>
          Assistance can only be provided to consumers, if the assistance
          provided benefits YOUR client. Hence, you cannot provide assistance to
          anyone if you do not have a client involved in the trade. (example:
          showing a consumer your clients' listing is acceptable after guide
          obligations are fulfilled).
        </p>
      </div>
      <br />
      <div>
        <h2>Where do I find the RECO INFO GUIDE?</h2>

        <p>
          Visit:{" "}
          <a
            href="https://www.recoinfoguide.ca/"
            target="_blank"
            rel="noreferrer"
          >
            www.recoinfoguide.ca
          </a>{" "}
          to either download the RECO Info Guide or use their online tool to
          send to the consumer.
        </p>
        <br />
        <p>
          <strong>Please note:</strong> if you use the online tool, you will
          need 2 emails in order to fulfill your obligations.
        </p>
        <br />
        <p>
          <strong>Email 1:</strong> Guide Shared
        </p>
        <br />
        <p>
          <strong>Email 2: </strong>Guide Acknowledged. This is obtained after
          the consumer checks the box at the end of the guide and submits.
        </p>
      </div>
    </div>
  );
}
